// /* eslint-disable */
import {
  Button, Flex, Input, Modal, Popover, Radio, Select, Slider, Space, Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { SearchOutlined } from '@ant-design/icons';
import Mixpanel from '../../../../utils/mixpanel';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';
// import { generateIndicationsAction } from '../../pages/IEPInputPage/logic';
import SaveProjectModal from '../SaveProjectModal';
import InputDropdown from '../../components/InputDropdownV2';
import { getInputSynonymsAction } from '../../pages/IEPInputPage/logic';

function AdvanceSearchView({
  isOpen, setIsAdvanceSearch, serviceName, isAdvanceSearch,
}) {
  const [proteinList, setProteinList] = useState([]);
  const [selectedProtein, setSelectedProtein] = useState('');
  const [synonymsList, setSynonymsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpenAddSynonym, setIsOpenAddSynonym] = useState(false);
  const [newSynonym, setNewSynonym] = useState('');
  const [networkApproachScore, setNetworkApproachScore] = useState(60);
  const [literatureMiningScore, setLiteratureMiningScore] = useState(20);
  const [aiModelScore, setAiModelScore] = useState(10);
  const [clinicalTrialScore, setClinicalTrialScore] = useState(10);
  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false);
  const [totalWeightage, setTotalWeightage] = useState(100);
  const [proteinsInteractionWeightage, setProteinsInteractionWeightage] = useState(networkApproachScore / 4);
  const [literatureMiningWeightage, setLiteratureMiningWeightage] = useState(networkApproachScore / 4);
  const [diseaseSimilarityWeightage, setDiseaseSimilarityWeightage] = useState(networkApproachScore / 4);
  const [molecularFunctionSimilarityWeightage, setMolecularFunctionSimilarityWeightage] = useState(networkApproachScore / 4);
  const [tempProteinsInteractionWeightage, setTempProteinsInteractionWeightage] = useState(networkApproachScore / 4);
  const [tempLiteratureMiningWeightage, setTempLiteratureMiningWeightage] = useState(networkApproachScore / 4);
  const [tempDiseaseSimilarityWeightage, setTempDiseaseSimilarityWeightage] = useState(networkApproachScore / 4);
  const [tempMolecularFunctionSimilarityWeightage, setTempMolecularFunctionSimilarityWeightage] = useState(networkApproachScore / 4);
  const getSynonymsResponse = useAppSelector((state) => state.getSynonymsResponse);
  const dispatch = useDispatch();
  const indicationsOptions = [
    {
      label: 'All applicable indications',
      value: 'all_applicable_indications',
    },
    {
      label: 'Only rare indications',
      value: 'only_rare_indications',
    },
  ];
  const [indication, setIndication] = useState(indicationsOptions[0].value);
  const [requestBody, setRequestBody] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const marks = (key1, value1) => ({
    0: '0',
    [key1]: value1,
  });

  useEffect(() => {
    setProteinsInteractionWeightage(networkApproachScore / 4);
    setLiteratureMiningWeightage(networkApproachScore / 4);
    setDiseaseSimilarityWeightage(networkApproachScore / 4);
    setMolecularFunctionSimilarityWeightage(networkApproachScore / 4);
    setTempProteinsInteractionWeightage(networkApproachScore / 4);
    setTempLiteratureMiningWeightage(networkApproachScore / 4);
    setTempDiseaseSimilarityWeightage(networkApproachScore / 4);
    setTempMolecularFunctionSimilarityWeightage(networkApproachScore / 4);
  }, [networkApproachScore]);

  const networkApproachChild = (title, value, updateValue) => (
    <div className="network-approach-child">
      <div className="network-approach-child-card">
        <div className="title-row" style={{ display: 'flex' }}>
          <div className="title">{title}</div>
        </div>
        <div className="approach-slider">
          <Slider
            defaultValue={value}
            value={value}
            marks={marks(networkApproachScore, networkApproachScore)}
            tooltip={{ placement: 'bottom' }}
            onChange={(e) => {
              updateValue(e);
            }}
            min={0}
            max={networkApproachScore}
          />
        </div>
      </div>
    </div>
  );

  const handleClose = (removedTag) => {
    setSynonymsList((prev) => {
      for (let i = 0; i < prev.length; i += 1) {
        if (prev[i].protein_name === selectedProtein) {
          if (!prev[i].synonyms_removed) prev[i].synonyms_removed = [];
          prev[i].synonyms_removed = [removedTag, ...prev[i].synonyms_removed];
          prev[i].synonyms_net = prev[i].synonyms_net.filter((item) => item !== removedTag);
        }
      }
      return prev;
    });
    // setProteinList(proteinList.filter((item) => item !== removedTag));
  };
  const addSynonym = (item) => {
    setSynonymsList((prev) => {
      for (let i = 0; i < prev.length; i += 1) {
        if (prev[i].protein_name === selectedProtein) {
          if (!prev[i].synonyms_added) prev[i].synonyms_added = [];
          // if(!prev[i].synonyms_added.includes(item))
          prev[i].synonyms_added = [item, ...prev[i].synonyms_added];
          // if(!prev[i].synonyms_net.includes(item))
          prev[i].synonyms_net = [item, ...prev[i].synonyms_net];
        }
      }
      return prev;
    });
    setIsOpenAddSynonym(!isOpenAddSynonym);
    setNewSynonym('');
    Mixpanel.track('IEP Add Synonym IEP Advance Search', {
      action: 'IEP Synonym added', name: item, service: 'Prioritize Top Indications', module: 'IEP',
    });
  };

  const addSynonymDropdown = () => (
    <div className="add-protein-dropdown-menu">
      <div className="searchbar-dashboard-content-search">
        <Input
          onChange={(e) => {
            setNewSynonym(e.target.value);
          }}
          value={newSynonym}
          placeholder="Enter synonym here"
        />
      </div>
      <div className="add-synonym-dropdown-menu-footer add-protein-dropdown-menu-footer">
        <Button className="clear-btn" onClick={() => setNewSynonym('')} disabled={newSynonym === ''}>
          Clear
        </Button>
        <Button className="add-btn" onClick={() => addSynonym(newSynonym)} disabled={newSynonym === ''}>
          Add synonym
        </Button>
      </div>
    </div>
  );

  const onCloseModal = () => {
    setIsOpenAddSynonym(false);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setAiModelScore(10);
    setClinicalTrialScore(10);
    setIsAdvanceSearch(false);
    Mixpanel.track('IEP Advance Search Modal Closed', {
      action: 'IEP Advance Search Cancelled',
      service: 'Prioritize Top Indications',
      module: 'IEP',
    });
  };

  const onUpdate = () => {
    setProteinsInteractionWeightage(tempProteinsInteractionWeightage);
    setLiteratureMiningWeightage(tempLiteratureMiningWeightage);
    setDiseaseSimilarityWeightage(tempDiseaseSimilarityWeightage);
    setMolecularFunctionSimilarityWeightage(tempMolecularFunctionSimilarityWeightage);
    setIsOpenEditDetails(false);
    Mixpanel.track('IEP Network Approaches Updated', {
      action: 'IEP Network Approaches Updated',
      service: 'Prioritize Top Indications',
      module: 'IEP',
      ppi_appraoch: tempProteinsInteractionWeightage,
      lm_appraoch: tempLiteratureMiningWeightage,
      ds_appraoch: tempDiseaseSimilarityWeightage,
      mfs_appraoch: tempMolecularFunctionSimilarityWeightage,
    });
  };

  const onResetToDefault = () => {
    setTotalWeightage(100);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setClinicalTrialScore(10);
    setAiModelScore(10);
    setTempDiseaseSimilarityWeightage(15);
    setTempMolecularFunctionSimilarityWeightage(15);
    setTempProteinsInteractionWeightage(15);
    setTempLiteratureMiningWeightage(15);
    Mixpanel.track('IEP Advance Search Reset', {
      action: 'IEP Reset to default button clicked',
      service: 'Prioritize Top Indications',
      module: 'IEP',
    });
  };

  const generateIndications = () => {
    setIsAdvanceSearch(false);
    const proteins = [];
    for (let i = 0; i < synonymsList.length; i += 1) {
      const obj = {
        protein_name: synonymsList[i].protein_name,
        synonyms_added: synonymsList[i].synonyms_added,
        synonyms_removed: synonymsList[i].synonyms_removed,
        synonyms_net: synonymsList[i].synonyms_net,
      };
      proteins.push(obj);
    }

    const request = {
      params: {
        service: serviceName,
      },
      body: {
        proteins,
        approach_weightage: {
          literature_mining: literatureMiningScore,
          clinical_trial: clinicalTrialScore,
          ai_model: aiModelScore,
          network_disease_similarity: diseaseSimilarityWeightage,
          network_ppi: proteinsInteractionWeightage,
          network_molecular_function: molecularFunctionSimilarityWeightage,
          network_literature_mining: literatureMiningWeightage,
        },
        rare_indication_flag: proteinList.length === 1 && indication === 'only_rare_indications',
      },
    };
    setRequestBody(request);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setClinicalTrialScore(10);
    setAiModelScore(10);
    setIsSubmitted(true);
    Mixpanel.track('IEP Generate Indications clicked', {
      action: 'IEP Generate Indications button clicked',
      service: 'Prioritize Top Indications',
      module: 'IEP',
      request_body: request,
    });
  };

  useEffect(() => {
    if (proteinList.length === 0) {
      setSelectedProtein('');
    } else if (proteinList.length === 1) {
      setSelectedProtein(proteinList[0]);
    } else if (!proteinList.includes(selectedProtein)) {
      setSelectedProtein(proteinList.slice(-1));
    }
  }, [proteinList]);

  const getSynonymsList = () => {
    let list = [];
    for (let i = 0; i < synonymsList.length; i += 1) {
      if (synonymsList[i].protein_name === selectedProtein) {
        list = synonymsList[i].synonyms_net;
      }
    }
    if (searchTerm !== '') list = list.filter((item) => item.includes(searchTerm));

    return (
      <div className="tags-group">
        <Flex gap="4px 0" wrap="wrap">
          {list?.map((item) => (
            <Tag key={item} className="sample-tags" closable onClose={() => handleClose(item)}>
              {item}
            </Tag>
          ))}
        </Flex>
      </div>
    );
  };

  return (
    <>
      <Modal open={isOpen} footer={false} onCancel={() => onCloseModal()} closable className="advance-search-modal">
        <div className="advance-search-container">
          <div className="advance-search-title">Provide input for prioritizing top indications</div>
          <div className="advance-search-input">
            <div className="input-container ">
              <div className="input-fields">
                <div className="dropdown-container">
                  {/* <InputDropdown index={0} changeHandler={updateProtein} proteinName={proteinItem.gene_name} isDisabled={false} setProteinName={() => {}} setProteinItem={updateProteinItem} /> */}
                  <InputDropdown proteinList={proteinList} isDisabled={false} setProteinList={setProteinList} setSynonymsList={setSynonymsList} />

                  <div className="input-fields-value try-example-section">
                    <text className="try-example-section-title">Try Example: </text>
                    <text
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        Mixpanel.track('IEP try example clicked', {
                          action: 'IEP Try example from Advance Search clicked',
                          selected_protein: 'DLL3',
                          service: 'Prioritize Top Indications',
                        });
                        if (!proteinList.includes('DLL3')) {
                          setProteinList((prev) => [...prev, 'DLL3']);
                          const request = {
                            protein_name: 'DLL3',
                            uid: 'PROT_ID_9606_a39ee0dbc72871f2d3c39685fb730753b47045f1',
                          };
                          dispatch(getInputSynonymsAction(queryString.stringify(request)));
                        }
                      }}
                    >
                      DLL3
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="advance-search-synonyms">
            <div className="advance-search-synonyms-title-row">
              <div className="advance-search-synonyms-header">
                <div className="advance-search-synonyms-title">Synonyms</div>
                { proteinList.length > 0 && (
                <div className="card-content-dropdown-select">
                  <Select
                    value={selectedProtein}
                    style={{
                      width: 120,
                    }}
                    onChange={(value) => {
                      setSelectedProtein(value);
                    }}
                    options={proteinList.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
                )}
              </div>
              <div className="advance-search-synonyms-header-right">
                {synonymsList?.length > 0 && (
                <div className="inplace-search-bar">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search"
                    allowClear
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                )}
                <div className="add-btn-sec">
                  <Popover overlayClassName="add-dropdown-popover" open={isOpenAddSynonym && isAdvanceSearch} placement="bottomRight" content={addSynonymDropdown()}>
                    <div className="add-dropdown">
                      <div
                        className="add-btn"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          Mixpanel.track('IEP Add Synonym clicked', {
                            action: 'IEP add synonym dropdown click',
                          });
                          setIsOpenAddSynonym(!isOpenAddSynonym);
                        }}
                        aria-hidden
                      >
                        Add
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            <Loader loading={getSynonymsResponse.loading}>
              {synonymsList?.length > 0 ? (
                getSynonymsList()
              ) : (
                <div className="advance-search-synonyms-content">Synonyms of the input term will be generated here....</div>
              )}
            </Loader>
          </div>

          <div className="advance-search-approach-weightage">
            <div className="advance-search-approach-weightage-title-row" style={{ display: 'flex' }}>
              <div className="advance-search-approach-weightage-title">Approach Weightage</div>
              <div className={`advance-search-total-weightage${totalWeightage !== 100 ? ' warning' : ''}`} style={{ display: 'flex' }}>
                <div className="advance-search-total-weightage-key">Total Weightage:</div>
                <div className={`advance-search-total-weightage-value${totalWeightage !== 100 ? ' warning' : ''}`}>{`${totalWeightage}%`}</div>
              </div>
              {totalWeightage !== 100 && (
                <div className="weightage-warning">
                  <div className="warning-icon" />
                  <div className="warning-text">Total weightage should be 100%</div>
                </div>
              )}
            </div>
            <div className="advance-search-approaches" style={{ display: 'flex' }}>
              <div className={`individual-approach${isOpenEditDetails ? ' active' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">1. Network Approach</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${networkApproachScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={networkApproachScore}
                      value={networkApproachScore}
                      onChange={(e) => {
                        setNetworkApproachScore(e);
                        setTotalWeightage(e + literatureMiningScore + clinicalTrialScore + aiModelScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className="edit-details-btn" onClick={() => setIsOpenEditDetails(true)} aria-hidden>
                    Edit Details
                  </div>
                </div>
              </div>
              <div className={`individual-approach${isOpenEditDetails ? ' disabled' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">2. Literature Mining</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${literatureMiningScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={literatureMiningScore}
                      value={literatureMiningScore}
                      onChange={(e) => {
                        setLiteratureMiningScore(e);
                        setTotalWeightage(e + networkApproachScore + clinicalTrialScore + aiModelScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </div>
              <div className={`individual-approach${isOpenEditDetails ? ' disabled' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">3. Clinical Trial</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${clinicalTrialScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={clinicalTrialScore}
                      value={clinicalTrialScore}
                      onChange={(e) => {
                        setClinicalTrialScore(e);
                        setTotalWeightage(e + literatureMiningScore + networkApproachScore + aiModelScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </div>
              <div className={`individual-approach${isOpenEditDetails ? ' disabled' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">4. AI Model Score</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${aiModelScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={aiModelScore}
                      value={aiModelScore}
                      onChange={(e) => {
                        setAiModelScore(e);
                        setTotalWeightage(e + literatureMiningScore + networkApproachScore + clinicalTrialScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`individual-approach-contents${isOpenEditDetails ? ' open' : ''}`}>
              <div className="network-approach-content-row" style={{ display: 'flex' }}>
                {networkApproachChild('i. Protein-Protein Interactions', tempProteinsInteractionWeightage, setTempProteinsInteractionWeightage)}
                {networkApproachChild('ii. Literature Mining', tempLiteratureMiningWeightage, setTempLiteratureMiningWeightage)}
                {networkApproachChild('iii. Disease Similarity', tempDiseaseSimilarityWeightage, setTempDiseaseSimilarityWeightage)}
                {networkApproachChild('iv. Molecular Function Similarity', tempMolecularFunctionSimilarityWeightage, setTempMolecularFunctionSimilarityWeightage)}
              </div>
              <div className="update-btn-row">
                <div className="update-btn-row-content">
                  <div className="total-weightage-value">
                    {'Total sum of all four network approach: '}
                    <span className={`total-weightage-percent${tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore ? ' warning' : ''}`}>{`${tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage}%`}</span>
                  </div>
                  {tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore && (
                    <div className="weightage-warning">
                      <div className="warning-icon" />
                      <div className="warning-text">{`Total weightage should be ${networkApproachScore}%`}</div>
                    </div>
                  )}
                </div>
                <Button className="update-btn" onClick={() => onUpdate()} disabled={tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore}>
                  Update
                </Button>
              </div>
            </div>
          </div>
          <div className="advance-search-indications">
            <div className="advance-search-indications-title">Indications</div>
            <div className="advance-search-indications-selection">
              <Radio.Group
                value={indication}
                onChange={(e) => {
                  setIndication(e.target.value);
                  Mixpanel.track('IEP Indication Selection Updated', {
                    action: 'IEP Indication Selection Updated', updated_indication: e.target.value, service: 'Prioritize Top Indications', module: 'IEP',
                  });
                }}
              >
                <Space>
                  {indicationsOptions?.map((item, index) => {
                    if (proteinList?.length > 1 && index === 1) {
                      return (
                        <Radio key={item.value} value={item.value} disabled>
                          {item.label}
                        </Radio>
                      );
                    }
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </div>
          </div>
          <div className="advance-search-submit">
            <div className="reset-to-default-btn" onClick={() => onResetToDefault()} aria-hidden>
              Reset to Default
            </div>
            <Button disabled={totalWeightage !== 100 || proteinList.length === 0} onClick={() => generateIndications()}>
              Generate Indications
            </Button>
          </div>
        </div>
      </Modal>
      {isSubmitted && <SaveProjectModal name={proteinList.join(', ')} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} />}
    </>
  );
}

AdvanceSearchView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsAdvanceSearch: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  isAdvanceSearch: PropTypes.bool.isRequired,
};
export default AdvanceSearchView;
