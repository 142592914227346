/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import Graph from 'react-vis-network-graph';
import { getTargetFeatureNetworkVisualisationOutputAction } from './logic';
import useAppSelector from '../../../../../utils/redux-hook';
import Loader from '../../../../../common/components/Loader';
import _ from 'lodash';
// import  './target_feature_network' as dummyData


function TargetFeaturesNetworkGraph({ jobId }) {

  const dispatch = useDispatch();
  const getNetworkVisualisationOutputReponse = useAppSelector((state) => state.getTargetFeatureNetworkVisualisationOutputResponse);
  const [graphData, setGraphData] = useState();
  const [graphParsing, setGraphParsing] = useState(false);
  const networkRef = useRef(null); // Create a reference for the Graph component

  // const [graphKey, setGraphKey] = useState(null);

  // useEffect(() => {
  //   // setGraphKey(uuidv4);
  // });

  // const nodes = [
  //   {
  //     id: 0, label: 'PORCN', group: 0, value: 70, title: 'PORCN', color: '#CCDBF9',
  //   },
  //   {
  //     id: 1, label: 'JUP', group: 0, value: 70, title: 'JUP', color: '#CCDBF9',
  //   },
  //   {
  //     id: 2, label: 'WNT3A', group: 0, value: 70, title: 'WNT3A', color: '#CCDBF9',
  //   },
  //   {
  //     id: 3, label: 'AXIN2', group: 0, value: 70, title: 'AXIN2', color: '#CCDBF9',
  //   },
  //   {
  //     id: 4, label: 'RNF43', group: 0, value: 70, title: 'RNF43', color: '#CCDBF9',
  //   },
  //   {
  //     id: 5, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 6, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 7, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 8, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 9, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 10, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 11, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 12, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 13, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 14, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 15, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 16, label: 'APOC4', group: 0, value: 70, title: 'APOC4', color: '#CCDBF9',
  //   },
  //   {
  //     id: 17, label: 'APOC4', group: 1, value: 10, title: 'APOC4', color: '#9BB3E5',
  //   },
  //   {
  //     id: 18, label: 'APOC4', group: 1, value: 10, title: 'APOC4', color: '#3B76EF',
  //   },
  //   {
  //     id: 19, label: 'APOC4', group: 1, value: 10, title: 'APOC4', color: '#9BB3E5',
  //   },
  //   {
  //     id: 20, label: 'APOC4', group: 1, value: 10, title: 'APOC4', color: '#3B76EF',
  //   },
  // ].map((node, index, arr) => {
  //   if (index < 16) {
  //     const angle = 2 * Math.PI * (index / 16);
  //     node.x = 400 * Math.cos(angle);
  //     node.y = 400 * Math.sin(angle);
  //     // node.fixed.x = true;
  //     // node.fixed.y = true;
  //     node.fixed = true;
  //     // node.physics = false;
  //     // if (index % 2 === 0) {
  //     //   node.value = index + 1;
  //     // }
  //     node.size = 40;
  //   } else {
  //   //   node.x = Math.random() * 300 ;
  //   //   node.y = Math.random() * 300;
  //     node.size = 4;
  //   }
  //   return node;
  // });
  // const edges = [
  //   { from: 0, to: 1 },
  //   { from: 1, to: 2 },
  //   { from: 2, to: 3 },
  //   { from: 3, to: 4 },
  //   { from: 4, to: 5 },
  //   { from: 5, to: 6 },
  //   { from: 6, to: 7 },
  //   { from: 7, to: 8 },
  //   { from: 8, to: 9 },
  //   { from: 9, to: 10 },
  //   { from: 10, to: 11 },
  //   { from: 11, to: 12 },
  //   { from: 12, to: 13 },
  //   { from: 13, to: 14 },
  //   { from: 14, to: 15 },
  //   { from: 15, to: 16 },
  // ];




  // const graph = {
  //   nodes,
  //   edges,
  // };

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getTargetFeatureNetworkVisualisationOutputAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getNetworkVisualisationOutputReponse.flag) {
      let radius = 50;
      let offset = 0;
      setGraphParsing(true);
      const nodes = [];
      let netWorkData = null;
      netWorkData = getNetworkVisualisationOutputReponse?.data?.data
      if (Array.isArray(netWorkData)) {
        netWorkData = netWorkData[0];
      }
      const edges = _.cloneDeep(netWorkData?.edges)
      // const edges = _.cloneDeep(dummyData?.edges)
        for (const i in netWorkData.nodes) {
        // for(const i in dummyData?.nodes) {
          var node = _.cloneDeep(netWorkData.nodes[i]);
          // var node = _.cloneDeep(dummyData?.nodes[i]);
          if (i < 16) {
            const angle = 2 * Math.PI * (i / 16);
            node.x = 700 * Math.cos(angle);
            node.y = 700 * Math.sin(angle);
            // node.fixed.x = true;
            // node.fixed.y = true;
            node.font = { size: 25, color: "black", face: "arial" },
            node.fixed = true;
            // node.physics = false;
            // if (index % 2 === 0) {
            //   node.value = index + 1;
            // }
            node.label = node.label.split(" ").join("\n")
            node.label = "                   \n" + node.label + "\n                   ";
            node.size = 10;
          } else {
            // node.x = Math.random() * 2300 ;
            // node.y = Math.random() * 2300;

            node.label = '  ';
            const title = node.title;
            node.title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px; padding: 4px; border-radius: 8px; display: flex;flex-direction: row">'
            + '<div style="padding-right: 15px;"><p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Target </p>'
            + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${title}` + '</p></div>'
            + '<div><p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Rank </p>'
            + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${node.rank}` + '</p></div>'
            + '</div>';
            // console.log('length', dummyData?.nodes.length);
            // if(node.rank / dummyData?.nodes.length <= 0.1)
            //   node.color = '#3B76EF';
            // else if(node.rank / dummyData?.nodes.length <= 0.5)
            //   node.color = '#3B76EF99';
            // else
            //   node.color = '#3B76EF60'
            if(node.rank / netWorkData?.nodes.length <= 0.1)
              node.color = '#3B76EF';
            else if(node.rank / netWorkData?.nodes.length <= 0.5)
              node.color = '#3B76EF99';
            else
              node.color = '#3B76EF60'
            // node.font = { color: 'transparent'}
            // node.x = ((Math.random() * 2) - 1) * (i/16 * radius);
            // node.y = ((Math.random() * 2) - 1) * (i/16 * radius);
            if(i%16 === 0) {
              radius+=75;
              offset += 30;
            }
            const angle = 2 * Math.PI * (i / 16) + offset;
            node.x = radius * Math.cos(angle);
            node.y = radius * Math.sin(angle);
            node.size = 4;
          }
          nodes.push(node);
          // if(i>16) {
          //   for(let j = 1; j < 10; j++) {
          //     const temp = _.cloneDeep(node);
          //     temp.id = j*10+i;
          //     nodes.push(temp);
          //   }
          // }
          // console.log('nodes', i);
        }
        const tempEdges = [];

        // for(let i in nodes) {
        //   // tempEdges.push(edges[i]);
        //   console.log('nodes', nodes[i].id);
        //   for(let j =i+1; j< nodes.length; j++ ) {
        //     if(nodes[j])
        //       tempEdges.push({from: nodes[i]?.id, to: parseInt(nodes[j]?.id)});
        //   }
        // }
      setGraphData({nodes, edges: edges });
      setGraphParsing(false);
    }
  }, [JSON.stringify(getNetworkVisualisationOutputReponse)]);

  // useEffect(() => {
  //   console.log('graphData', graphData);
  // }, [graphData]);

  const options = {
    interaction: {
      zoomView: false,
      selectable: true,
      dragView: false,
    },
    physics: {
      enabled: false,
      solver: 'barnesHut', // 'forceAtlas2Based' or 'repulsion' are other options
      barnesHut: {
        gravitationalConstant: -2000, // Increase repulsion slightly
        // centralGravity: 0.1, // Reduce attraction to the center
        springLength: 200, // Desired distance between connected nodes
        springConstant: 0.0005, // Reduce rigidity to allow more movement
        // damping: 0.4, // Increase damping to reduce oscillations
        avoidOverlap: 1,
      },
      stabilization: {
        enabled: true,
        iterations: 300, // Further increase number of iterations to stabilize
        updateInterval: 50,
        onlyDynamicEdges: false,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    layout: {
      improvedLayout: true,
    },
    edges: {
      // color: 'red',
      arrows: {
        to: {
          enabled: false,
        },
        middle: {
          enabled: false,
        },
        from: {
          enabled: false,
        },
      },
    },
    nodes: {
      shape: 'circle',
      scaling: {
        // label: {
        //   enabled: true,
        //   min: 25,
        // },
        customScalingFunction(min, max, total, value) {
          return (value * 10 / total) * 1.5;
        },
        min: 5,
        max: 150,
      },
    },
    height: '800px',
  };

  // const events = {
  //   select(event) {
  //     const { nodes, edges } = event;
  //     console.log(nodes, edges);
  //   },
  // };

  useEffect(() => {
    // if (networkRef.current) {
    //   const network = networkRef.current.Network;
    //   network.on('selectNode', (params) => {
    //     const nodeId = params.nodes[0];
    //     const connectedEdges = network.getConnectedEdges(nodeId);

    //     connectedEdges.forEach((edgeId) => {
    //       network.updateEdge(edgeId, { width: 10 }); // Increase width of the edge
    //     });
    //   });
    //   console.log('network ---->', network);
    //   network.on('deselectNode', (params) => {
    //     const { edges } = network.body;

    //     Object.keys(edges).forEach((edgeId) => {
    //       network.updateEdge(edgeId, { width: 2 }); // Reset width of the edge
    //     });
    //   });
    //   // You can now access the network object and call its methods
    //   console.log('network', network); // For example, log the network object
    // }
    if (networkRef.current) {
      const network = networkRef.current.Network;
      const nodesDataset = network.body.data.nodes;
      let netWorkData = null;
      netWorkData = getNetworkVisualisationOutputReponse?.data?.data
      if (Array.isArray(netWorkData)) {
        netWorkData = netWorkData[0];
      }
      network.on('selectNode', (params) => {
        const nodeId = params.nodes[0];
        const connectedNodes = network.getConnectedNodes(nodeId);
        const allNodes = network.body.data.nodes.get();
        // Highlight the connected nodes
        // const up = connectedNodes.map((connectedNodeId) => ({
        //   id: connectedNodeId,
        //   color: { background: '#58D2A6' },
        // }));
        // const updates = []
        console.log(connectedNodes)
        const updates = allNodes.map((node) => {
          if(connectedNodes.includes(node.id)) {

              return {
                id: node.id,
                color: { background: '#58D2A6' }, // Reset color to default
              }
            }
            if(node.id >= 16) {
              var clr;
              if(node.rank / netWorkData?.nodes.length <= 0.1)
                clr = '#3B76EF';
              else if(node.rank / netWorkData?.nodes.length <= 0.5)
                clr = '#3B76EF99';
              else
              clr = '#3B76EF60'
            return {
              id: node.id,
              color: { background: clr }, // Reset color to default
            }
          }
        });
        nodesDataset.update(updates);
      });

      network.on('deselectNode', () => {
        const allNodes = network.body.data.nodes.get();
        const updates = allNodes.map((node) => {
          var clr;
          if(node.id >= 16) {

            if(node.rank / netWorkData?.nodes.length <= 0.1)
              clr = '#3B76EF';
            else if(node.rank / netWorkData?.nodes.length <= 0.5)
              clr = '#3B76EF99';
            else
            clr = '#3B76EF60'
          return {
            id: node.id,
            color: { background: clr }, // Reset color to default
          }
        }
        return {
          id: node.id,
          color: { background: '#CCDBF9' }, // Reset color to default
        }
        });
        nodesDataset.update(updates);
      });
    }
  }, [graphData]);

  return (<>
      <Loader loading={getNetworkVisualisationOutputReponse.loading || !getNetworkVisualisationOutputReponse.flag || graphParsing} />
      { graphData && graphData.nodes?.length ? <Graph key={Math.random()} ref={networkRef} graph={{nodes: graphData.nodes , edges: graphData.edges}} options={options} /> : null }
      <Loader />
      </>
  );
}

TargetFeaturesNetworkGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default TargetFeaturesNetworkGraph;
