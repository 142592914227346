/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getGraphDataAction } from '../../../pages/TopIndicationsPage/logic';
// import BubbleChart from '../../BubbleGraph';
import useAppSelector from '../../../../../utils/redux-hook';
import bubbleGraphData from './data';
import NormalBubbleChart from '../../NormalBubbleChart';
import BubbleChart from '../../BubbleGraph';

function CustomBubble({ item }) {
  return (
    <div style={{ backgroundColor: item.color, padding: '10px', borderRadius: '50%' }}>
      <span>{"Hii Sumit"}</span>
      {/* Add your widget/component here */}
      {/* <YourWidgetComponent /> */}
    </div>
  );
}

function IndicationsLandscapeView({ jobId }) {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState([]);
  const iepGraphDataResponse = useAppSelector((state) => state.iepGraphDataResponse);
  const newData = [
    {
      key: 'hERG Block',
      value: 20,
    },
    {
      key: 'Clinical Toxicity',
      value: 30,
    },
    {
      key: 'Mutagenicity',
      value: 40,
    },
    {
      key: 'DILI',
      value: 20,
    },
    {
      key: 'Carcinogenicity',
      value: 10,
    },
    {
      key: 'Acute Toxicity LD50',
      value: 25,
    },
    {
      key: 'Skin Reaction',
      value: 35,
    },
    {
      key: 'NR-AR',
      value: bubbleGraphData['NR-AR'],
    },
    {
      key: 'NR-AR-AhR',
      value: bubbleGraphData['NR-AR-AhR'],
    },
    {
      key: 'NR-AR-LBD',
      value: bubbleGraphData['NR-AR-LBD'],
    },
    {
      key: 'NR-Aromatase',
      value: bubbleGraphData['NR-Aromatase'],
    },
    {
      key: 'NR-ER',
      value: bubbleGraphData['NR-ER'],
    },
    {
      key: 'NR-ER-LBD',
      value: bubbleGraphData['NR-ER-LBD'],
    },
    {
      key: 'NR-PPAR-gamma',
      value: bubbleGraphData['NR-PPAR-gamma'],
    },
    {
      key: 'SR-ARE',
      value: 44,
    },
    {
      key: 'SR-ATAD5',
      value: 33,
    },
    {
      key: 'SR-HSE',
      value: 45,
    },
    {
      key: 'SR-MMP',
      value: 33,
    },
    {
      key: 'SR-p53',
      value: 34,
    },
  ];
  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getGraphDataAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (iepGraphDataResponse.flag) {
      setGraphData(iepGraphDataResponse.data.data);
    }
  }, [iepGraphDataResponse.data.data]);
  return (
    <div className="indications-landscape-main">
      <div className="indications-landscape-title-row">
        <div className="indications-landscape-title card-content-ctp-title">Indications Landscape</div>
      </div>
      <div className="indications-landscape-graph">
        <BubbleChart data={graphData} />
        {/* {bubbleGraphData !== undefined
        && (
        <NormalBubbleChart dData={newData?.map((item) => ({
          ...item,
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderBubble: () => <CustomBubble {...item} />, // Custom rendering
        }))}
        />
        )} */}
      </div>
    </div>
  );
}

IndicationsLandscapeView.propTypes = {
  jobId: PropTypes.string.isRequired,
};
export default IndicationsLandscapeView;
