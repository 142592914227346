import generatePayloadsReducer from '../../modules/adc/components/InputDropdown/logic';
import generateADCOutputResponseReducer from '../../modules/adc/pages/ADCOutputPage/logic';
import generateLinkersReducer from '../../modules/dc/components/InputDropdown/logic';
import generateLinkersOutputReducer from '../../modules/dc/pages/DCOutputPage/logic';
import { checkStatusReducer } from '../../modules/meta-ctp/pages/CTPInputPage/logic';
import requestDemoReducer from './ModalRequestDemo/logic';
import { getStudyGroupsReducer } from '../../modules/biomarker/container/SelectStudy/logic';
import { getCTPOutputReducer, isReadCTPOutputReducer } from '../../modules/meta-ctp/pages/CTPOutputPage/logic';
import { getTerminalsReducer, usersMeReducer } from './Selection/logic';
import { getInputSuggestReducer } from '../../modules/iep/components/InputDropdown/logic';
import {
  generateIndicationsReducer, getInputSynonymsReducer, iepCheckStatusReducer, uploadCCADocumentReducer,
} from '../../modules/iep/pages/IEPInputPage/logic';
import { isReadIEPOutputReducer } from '../../modules/iep/pages/IEPOutputPage/logic';
import { checkBiomarkerStatusReducer, getJobIdReducer } from '../../modules/biomarker/pages/BiomarkerInputPage/logic';
import { getBiomarkerOutputReducer, isReadBiomarkerOutputReducer, volcanoGraphBiomarkerOutputReducer } from '../../modules/biomarker/pages/BiomarkerOutputPage/logic';
import { generateTIInputsReducer } from '../../modules/target-id/components/InputDropdown/logic';
import { getInputSuggestDRReducer } from '../../modules/dr/components/InputDropdown/logic';
import { drCheckStatusReducer, generateIndicationsDRReducer } from '../../modules/dr/pages/DRInputPage/logic';
import { getIndicationOutputDRReducer, isReadDROutputReducer } from '../../modules/dr/pages/DROutputPage/logic';
import { checkTIStatusReducer, generateTITargetReducer } from '../../modules/target-id/pages/TargetIdInputPage/logic';
import { isReadTIOutputReducer } from '../../modules/target-id/pages/TargetIdOutputPage/logic';
import { checkSARStatusReducer } from '../../modules/sar/pages/SARInputPage/logic';
import { isReadSAROutputReducer } from '../../modules/sar/pages/SAROutputPage/logic';
import { getInputSuggestProtacReducer } from '../../modules/protac/components/InputDropdown/logic';
import { getProtacOutputReducer, isReadProtacOutputReducer } from '../../modules/protac/pages/ProtacOutputPage/logic';
import { protacCheckStatusReducer, generateDesignProtacReducer } from '../../modules/protac/pages/ProtacInputPage/logic';
import { getAutoSuggestReducer } from '../../modules/biomarker/container/BiomarkerInputComponents/SearchDatasets/logic';
import { getFiltersReducer, getSampleFiltersReducer } from '../../modules/biomarker/pages/NewBiomarkerInputPage/logic';
import { getStudiesReducer } from '../../modules/biomarker/container/BiomarkerInputComponents/SelectPreferredStudies/logic';
import { getSamplesReducer } from '../../modules/biomarker/container/BiomarkerInputComponents/ViewSamples/logic';
import { ctpPhaseDistributionReducer } from '../../modules/meta-ctp/container/Graph/BarGraph/logic';
import { ctpKolReducer } from '../../modules/meta-ctp/container/Graph/SlopeGraph/logic';
import { ctpCountryDistributionReducer } from '../../modules/meta-ctp/container/Graph/BubbleGraphContainer/logic';
import { generateCTPInputsReducer } from '../../modules/meta-ctp/components/InputDropdown/logic';
import { ctpWordCloudReducer } from '../../modules/meta-ctp/container/Graph/WordCloud/logic';
import { getSAROutputReducer } from '../../modules/sar/pages/LeadDetailsPage/logic';
import { checkAdmetStatusReducer, getAdmetCompareOutputReducer } from '../../modules/sar/components/AdmetProperties/CompareMoleculeView/logic';
import { getAdmetOutputReducer } from '../../modules/sar/pages/AdmetPropertiesPage/logic';
import { getGraphDataReducer, getIndicationOutputReducer } from '../../modules/iep/pages/TopIndicationsPage/logic';
import { getPathwayEnrichmentReducer } from '../../modules/iep/components/TopIndicationGraphs/PathwayEnrichment/logic';
import { getAssociatedProteinsDistributionReducer } from '../../modules/iep/components/TopIndicationGraphs/AssociatedProteinDistributionGraph/logic';
import { getPPINetworkGraphReducer } from '../../modules/iep/components/TopIndicationGraphs/PPINetworkGraph/logic';
import { getLiteratureMiningReducer } from '../../modules/iep/components/TopIndicationGraphs/LiteratureMiningGraph/logic';
import { getTIOutputReducer } from '../../modules/target-id/pages/DiseaseBasedPage/logic';
import { getClinicalTrialEnrichmentReducer } from '../../modules/iep/components/TopIndicationGraphs/EnrichmentGraph/logic';
import { iepEditCheckStatusReducer } from '../../modules/iep/container/EditAssociatedProteinView/logic';
import { getTargetVisibilityOutputReducer } from '../../modules/target-id/container/TargetVisibilityView/logic';
import { getSafetyProfileOutputReducer } from '../../modules/target-id/container/SafetyProfileView/logic';
import { getDrugDevelopmentOutputReducer } from '../../modules/target-id/container/DrugDevelopmentFeasilibilityView/logic';
import { getTargetFeatureNetworkVisualisationOutputReducer } from '../../modules/target-id/components/DiseaseBasedGraphs/TargetFeaturesNetworkGraph/logic';
import { getTopOrganAffectedOutputReducer } from '../../modules/target-id/container/TopOrganAffectedView/logic';
import { getSynonymsReducer, iepAdvanceSearchCheckStatusReducer } from '../../modules/iep/container/AdvanceSearchView/logic';
import {
  clinicalAnalysisJobsIEPReducer, iepUpdateWorkspaceStatusReducer, iepWorkspaceStatusReducer, topIndicationsJobsIEPReducer,
} from '../../modules/iep/pages/MyWorkspace/logic';
import { addProteinsFileInputReducer, addProteinsTextualInputReducer } from '../../modules/iep/container/AddProteinsModal/logic';
import { ctpUpdateWorkspaceStatusReducer, hctpWorkspaceStatusReducer, posWorkspaceStatusReducer } from '../../modules/meta-ctp/pages/MyWorkspace/logic';
import { hctpDrugIndicationsReducer } from '../../modules/meta-ctp/pages/HCTPOutputPage/logic';
import { generateHCTPCombinationsReducer, generateInsightsReducer, hctpPairsFileInputReducer } from '../../modules/meta-ctp/container/SaveProjectModal/logic';
import { sarAdmetPropertiesWorkspaceStatusReducer, sarLeadGenerationWorkspaceStatusReducer, sarUpdateWorkspaceStatusReducer } from '../../modules/sar/pages/MyWorkspace/logic';
import { generateSARLeadReducer, uploadSmileDocumentReducer } from '../../modules/sar/container/SaveProjectModal/logic';
import { aeVerifySequencesReducer } from '../../modules/antibody-engineering/pages/AntibodyEngineeringInputPage/logic';
import { aeGetSequencesReducer } from '../../modules/antibody-engineering/components/LightChainOutputComponents/RawDataAndDockingComponent/logic';
import { getPhaseDistributionOutputReducer } from '../../modules/target-id/container/PhaseDistributionView/logic';
import { diseaseBasedJobsTIReducer, tiUpdateWorkspaceStatusReducer } from '../../modules/target-id/pages/MyWorkspace/logic';
import { getExpressionDataReducer } from '../../modules/iep/components/TopIndicationGraphs/ExpressionGraph/logic';
import {
  getOncoTaggingOutputReducer, getUtilitiesPathwayEnrichmentReducer, getUtilitiesPPINetworkReducer, pathwayEnrichmentUploadFileReducer, ppiNetworkUploadFileReducer,
} from '../../modules/utilities/pages/OncoTaggingInputPage/logic';
import {
  geoCtDetailsReducer, geoCtReducer, geoCtStatesReducer, geoKolDetailsReducer, geoKolReducer, geoKolStatesReducer,
} from '../../modules/meta-ctp/container/Graph/GlobalMap/logic';

const rootAppReducer = {
  dcResult: generateLinkersReducer,
  statusGenerateInsights: generateInsightsReducer,
  statusRequestDemo: requestDemoReducer,
  terminals: getTerminalsReducer,
  ctpOutput: getCTPOutputReducer,
  dcOutputResponse: generateLinkersOutputReducer,
  adcPayloadListResponse: generatePayloadsReducer,
  adcOutputResponse: generateADCOutputResponseReducer,
  checkStatusResponse: checkStatusReducer,
  isReadCTPOutputResponse: isReadCTPOutputReducer,
  usersMeResponse: usersMeReducer,
  getStudyGroupResponse: getStudyGroupsReducer,
  getInputSuggestResponse: getInputSuggestReducer,
  generateIndicationsResponse: generateIndicationsReducer,
  iepCheckStatusResponse: iepCheckStatusReducer,
  getIndicationOutputResponse: getIndicationOutputReducer,
  isReadIEPOutputResponse: isReadIEPOutputReducer,
  getBiomarkerJobId: getJobIdReducer,
  getBiomarkerJobIdStatus: checkBiomarkerStatusReducer,
  isReadBiomarkerOutputResponse: isReadBiomarkerOutputReducer,
  getBiomarkerOutputResponse: getBiomarkerOutputReducer,
  volcanoGraphBiomarkerOutputResponse: volcanoGraphBiomarkerOutputReducer,
  generateTIInputsSuggestionsResponse: generateTIInputsReducer,
  getInputSuggestDRResponse: getInputSuggestDRReducer,
  drCheckStatusResponse: drCheckStatusReducer,
  generateIndicationsDRResponse: generateIndicationsDRReducer,
  getIndicationOutputDRResponse: getIndicationOutputDRReducer,
  isReadDROutputResponse: isReadDROutputReducer,
  generateTITargetResponse: generateTITargetReducer,
  checkTIStatus: checkTIStatusReducer,
  getTIOutputResponse: getTIOutputReducer,
  getInputSuggestProtacResponse: getInputSuggestProtacReducer,
  protacCheckStatusResponse: protacCheckStatusReducer,
  generateDesignProtacResponse: generateDesignProtacReducer,
  getProtacOutputResponse: getProtacOutputReducer,
  isReadTIOutputResponse: isReadTIOutputReducer,
  generateSARLeadResponse: generateSARLeadReducer,
  checkSARStatusResponse: checkSARStatusReducer,
  checkAdmetStatusResponse: checkAdmetStatusReducer,
  getSAROutputResponse: getSAROutputReducer,
  getAdmetOutputResponse: getAdmetOutputReducer,
  getAdmetCompareOutputResponse: getAdmetCompareOutputReducer,
  isReadSAROutputResponse: isReadSAROutputReducer,
  isReadProtacOutputResponse: isReadProtacOutputReducer,
  iepGraphDataResponse: getGraphDataReducer,
  uploadSmileDocumentResponse: uploadSmileDocumentReducer,
  getAutoSuggestResponse: getAutoSuggestReducer,
  getFiltersResponse: getFiltersReducer,
  getSampleFiltersResponse: getSampleFiltersReducer,
  getStudiesResponse: getStudiesReducer,
  getSamplesResponse: getSamplesReducer,
  ctpPhaseDistributionResponse: ctpPhaseDistributionReducer,
  ctpKolResponse: ctpKolReducer,
  ctpCountryDistributionResponse: ctpCountryDistributionReducer,
  ctpWordCloudResponse: ctpWordCloudReducer,
  generateCTPInputsResponse: generateCTPInputsReducer,
  geoCtResponse: geoCtReducer,
  geoKolResponse: geoKolReducer,
  getPathwayEnrichmentResponse: getPathwayEnrichmentReducer,
  getAssociatedProteinDistributionResponse: getAssociatedProteinsDistributionReducer,
  getPPINetworkGraphResponse: getPPINetworkGraphReducer,
  getLiteratureMiningResponse: getLiteratureMiningReducer,
  getClinicalTrialEnrichmentResponse: getClinicalTrialEnrichmentReducer,
  iepEditCheckStatusResponse: iepEditCheckStatusReducer,
  getTargetVisibilityResponse: getTargetVisibilityOutputReducer,
  getSafetyProfileResponse: getSafetyProfileOutputReducer,
  getDrugDevelopmentResponse: getDrugDevelopmentOutputReducer,
  getTargetFeatureNetworkVisualisationOutputResponse: getTargetFeatureNetworkVisualisationOutputReducer,
  getTopOrganAffectedResponse: getTopOrganAffectedOutputReducer,
  getSynonymsResponse: getSynonymsReducer,
  iepAdvanceSearchCheckStatusResponse: iepAdvanceSearchCheckStatusReducer,
  getInputSynonymsResponse: getInputSynonymsReducer,
  iepWorkspaceStatusResponse: iepWorkspaceStatusReducer,
  addProteinsTextualInputResponse: addProteinsTextualInputReducer,
  addProteinsFileInputResponse: addProteinsFileInputReducer,
  uploadCCADocumentResponse: uploadCCADocumentReducer,
  hctpWorkspaceStatusResponse: hctpWorkspaceStatusReducer,
  posWorkspaceStatusResponse: posWorkspaceStatusReducer,
  topIndicationsIEPResponse: topIndicationsJobsIEPReducer,
  clinicalAnalysisIEPResponse: clinicalAnalysisJobsIEPReducer,
  hctpDrugIndicationsResponse: hctpDrugIndicationsReducer,
  generateHCTPCombinationsResponse: generateHCTPCombinationsReducer,
  ctpUpdateWorkspaceStatusResponse: ctpUpdateWorkspaceStatusReducer,
  hctpPairsFileInputResponse: hctpPairsFileInputReducer,
  sarUpdateWorkspaceResponse: sarUpdateWorkspaceStatusReducer,
  sarLeadGenerationWorkspaceResponse: sarLeadGenerationWorkspaceStatusReducer,
  sarAdmetPropertiesWorkspaceResponse: sarAdmetPropertiesWorkspaceStatusReducer,
  getOncoTaggingOutputResponse: getOncoTaggingOutputReducer,
  aeVerifySequencesResponse: aeVerifySequencesReducer,
  aeGetSequencesResponse: aeGetSequencesReducer,
  getPhaseDistributionDataResponse: getPhaseDistributionOutputReducer,
  diseaseBasedJobsResponse: diseaseBasedJobsTIReducer,
  tiUpdateWorkspaceStatusResponse: tiUpdateWorkspaceStatusReducer,
  iepUpdateWorkspaceStatusResponse: iepUpdateWorkspaceStatusReducer,
  geoCtStatesResponse: geoCtStatesReducer,
  geoCtDetailsResponse: geoCtDetailsReducer,
  geoKolStatesResponse: geoKolStatesReducer,
  geoKolDetailsResponse: geoKolDetailsReducer,
  getExpressionDataResponse: getExpressionDataReducer,
  getUtilitiesPathwayEnrichmentResponse: getUtilitiesPathwayEnrichmentReducer,
  getUtilitiesPPINetworkResponse: getUtilitiesPPINetworkReducer,
  ppiNetworkUploadFileResponse: ppiNetworkUploadFileReducer,
  pathwayEnrichmentUploadFileResponse: pathwayEnrichmentUploadFileReducer,
};

export default rootAppReducer;
