/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Graph from 'react-vis-network-graph';
// import { getPPINetworkGraphAction } from './logic';
import { XFilled } from '@ant-design/icons';
// import { nodes as allNodes, edges as allEdges } from './data';
import _ from 'lodash';
import useAppSelector from '../../../../utils/redux-hook';
import { getPPINetworkGraphAction } from '../../../iep/components/TopIndicationGraphs/PPINetworkGraph/logic';
import Loader from '../../../../common/components/Loader';
import { getUtilitiesPPINetworkAction } from '../../pages/OncoTaggingInputPage/logic';
import { useNavigate } from 'react-router-dom';
import { terminalsPath } from '../../../../app/components/Card/data';

function PPINetwotkGraph({ isFileInput }) {
  const dispatch = useDispatch();
  const getUtilitiesPPINetworkResponse = useAppSelector((state) => state.getUtilitiesPPINetworkResponse);
  const ppiNetworkUploadFileResponse = useAppSelector((state) => state.ppiNetworkUploadFileResponse);
  const [graphData, setGraphData] = useState({});
  const [allNodes, setAllNodes] = useState([]);
  const [allEdges, setAllEdges] = useState([]);
  const [graphParsing, setGraphParsing] = useState(false);
  const [highlightActive, setHighlightActive] = useState(false);
  const navigate = useNavigate();
  // let highlightActive = false;
  const networkRef = useRef(null); // Create a reference for the Graph component

  useEffect(() => {
    if (ppiNetworkUploadFileResponse.flag) {
      setGraphParsing(true);
      let zero;
      const nodes = [];
      const cnt = 0;
      for (const i of (ppiNetworkUploadFileResponse?.data?.nodes)) {
        const title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 28px; padding: 4px; border-radius: 8px">' + '<p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Associated Protein: </p>' + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${i.title}` + '</p>' + '</div>';
        // const title = '';
        const font = {
          size: 35,
          bold: true,
        };
        let obj = { ...i, title, font };
        if (i.id === 0) {
          zero = obj;
          obj = { ...obj, value: 80 };
          // continue;
        }
        nodes.push(obj);
      }
      const edges = [];
      for (const i of ppiNetworkUploadFileResponse?.data?.edges) {
        const obj = { ...i, color: undefined };
        edges.push(obj);
      }
      setGraphData((prev) => ({ nodes: _.cloneDeep(nodes), edges: _.cloneDeep(edges) }));
      setAllNodes(nodes);
      setAllEdges(edges);
      setGraphParsing(false);
    }
  }, [JSON.stringify(ppiNetworkUploadFileResponse)]);

  useEffect(() => {
    if (getUtilitiesPPINetworkResponse.flag) {
      setGraphParsing(true);
      let zero;
      const nodes = [];
      const cnt = 0;
      for (const i of (getUtilitiesPPINetworkResponse?.data?.nodes)) {
        const title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 28px; padding: 4px; border-radius: 8px">' + '<p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Associated Protein: </p>' + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${i.title}` + '</p>' + '</div>';
        // const title = '';
        const font = {
          size: 35,
          bold: true,
        };
        let obj = { ...i, title, font };
        if (i.id === 0) {
          zero = obj;
          obj = { ...obj, value: 80 };
          // continue;
        }
        nodes.push(obj);
      }
      const edges = [];
      for (const i of getUtilitiesPPINetworkResponse?.data?.edges) {
        const obj = { ...i, color: undefined };
        edges.push(obj);
      }
      setGraphData((prev) => ({ nodes: _.cloneDeep(nodes), edges: _.cloneDeep(edges) }));
      setAllNodes(nodes);
      setAllEdges(edges);
      setGraphParsing(false);
    }
  }, [JSON.stringify(getUtilitiesPPINetworkResponse)]);

  const options = {
    physics: {
      enabled: true,
      solver: 'barnesHut',
      barnesHut: {
        gravitationalConstant: -2000, // More negative for more repulsion
        centralGravity: 0.05, // Lower values mean less attraction to the center
        springLength: 750, // Desired distance between connected nodes
        springConstant: 0.005, // Rigidity of the edges
        damping: 0.09, // Reduces the velocity of moving nodes
        avoidOverlap: 1, // Prevents overlap
      },
      stabilization: {
        enabled: true,
        iterations: 150, // Number of iterations to stabilize
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    nodes: {
      shape: 'dot',
      scaling: {
        label: {
          enabled: false,
          min: 35,
          // drawThreshold: 12,
          // maxVisible: 20,
        },
        customScalingFunction(min, max, total, value) {
          return ((value * 10) / total) * 0.25;
        },
        min: 20,
        max: 150,
      },
    },
    edges: {
      width: 0.15,
      color: { inherit: 'from' },
      arrows: {
        to: {
          enabled: false,
        },
        middle: {
          enabled: false,
        },
        from: {
          enabled: false,
        },
      },
      smooth: {
        type: 'continuous',
      },
    },
    layout: {
      improvedLayout: false,
    },
    interaction: {
      tooltipDelay: 200,
      // hideEdgesOnDrag: true,
      // hideEdgesOnZoom: true,
    },
  };

  function resetNodeColorsAndLabels() {
    for (const nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        allNodes[nodeId].color = undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
    }
  }

  function neighbourhoodHighlight(params) {
    const network = networkRef.current.Network;

    if (params.nodes.length > 0) {
      // highlightActive = true;
      let i; let
        j;
      const selectedNode = params.nodes[0];
      // console.log('selectedNode', selectedNode);

      const degrees = 1;

      // mark all nodes as hard to read.
      for (var nodeId in allNodes) {
        if (allNodes[nodeId].hiddenColor === undefined) {
          allNodes[nodeId].hiddenColor = allNodes[nodeId].color;
        }
        allNodes[nodeId].color = 'rgba(200,200,200,0.1)';
        if (allNodes[nodeId].hiddenLabel === undefined) {
          allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
          allNodes[nodeId].label = undefined;
        }
      }
      const connectedNodes = network.getConnectedNodes(selectedNode);

      const allConnectedNodes = [];

      // get the second degree nodes
      // for (i = 1; i < degrees; i++) {
      //   for (j = 0; j < connectedNodes.length; j++) {
      //     allConnectedNodes = allConnectedNodes.concat(
      //       network.getConnectedNodes(connectedNodes[j])
      //     );
      //   }
      // }

      // all second degree nodes get a different color and their label back
      // for (i = 0; i < allConnectedNodes.length; i++) {
      //   allNodes[allConnectedNodes[i]].color = "rgba(150,150,150,0.75)";
      //   if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
      //     allNodes[allConnectedNodes[i]].label =
      //       allNodes[allConnectedNodes[i]].hiddenLabel;
      //     allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
      //   }
      // }

      // all first degree nodes get their own color and their label back
      for (i = 0; i < connectedNodes.length; i++) {
        if (allNodes[connectedNodes[i]]) {
          allNodes[connectedNodes[i]].color = allNodes[connectedNodes[i]].hiddenColor ?? undefined;
          if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
            allNodes[connectedNodes[i]].label = allNodes[connectedNodes[i]].hiddenLabel;
            allNodes[connectedNodes[i]].hiddenLabel = undefined;
          }
        }
      }

      // the main node gets its own color and its label back.
      allNodes[selectedNode].color = allNodes[selectedNode].hiddenColor ?? undefined;
      if (allNodes[selectedNode].hiddenLabel !== undefined) {
        allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
        allNodes[selectedNode].hiddenLabel = undefined;
      }
      setHighlightActive(true);
    } else if (highlightActive === true) {
      // reset all nodes

      for (var nodeId in allNodes) {
        allNodes[nodeId].color = allNodes[nodeId].hiddenColor ?? undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
      setHighlightActive(false);
      // highlightActive = false;
    }

    // transform the object into an array
    const updateArray = [];
    for (nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        updateArray.push(allNodes[nodeId]);
      }
    }
    // nodesDataset.update(updateArray);
    // network.redraw();
    setGraphData((prev) => ({
      ...prev,
      nodes: _.cloneDeep(updateArray),
      // nodes: [],
    }));
  }

  useEffect(() => {
    if (networkRef.current) {
      const network = networkRef.current.Network;

      // network.on('selectNode', (params) => {
      //   const nodeId = params.nodes[0];
      //   const connectedEdges = network.getConnectedEdges(nodeId);

      //   connectedEdges.forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 15 }); // Increase width of the edge
      //   });
      // });
      // network.on('selectEdge', neighbourhoodHighlight);
      // network.on('click', neighbourhoodHighlight);
      // network.on('deselectNode', (params) => {
      //   const { edges } = network.body;

      //   Object.keys(edges).forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 2 }); // Reset width of the edge
      //   });
      // });
      // You can now access the network object and call its methods
    }
  }, [graphData]);

  return (
    <Loader loading={ppiNetworkUploadFileResponse.loading || getUtilitiesPPINetworkResponse.loading || Object.keys(graphData).length === 0 || graphParsing}>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph" style={{ width: '100%', height: '800px' }}>
        <div className="card-content-ctp-title">PPI Network Graph and 1st Degree Associations</div>
        {graphData ? (
          <>
            {/* <div className="pathway-enrichment-graph-header">
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#CBA5E9', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Molecular functions Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#EDC962', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Disease Similarity Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#5FD6E7', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                PPIs Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#85D38D', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Literature Mining Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#D79B97', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px' }}>
                Common Protein
              </div>
            </div> */}
            <Graph ref={networkRef} graph={graphData} options={options} events={{ select: neighbourhoodHighlight }} />
          </>
        ) : (
          <Loader loading={graphParsing} />
        )}
      </div>
    </Loader>
  );
}

PPINetwotkGraph.propTypes = {
  isFileInput: PropTypes.string.isRequired,
};

export default PPINetwotkGraph;
