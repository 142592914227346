/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { Tooltip } from "antd";

function HeatMapView({ graphData, phase }) {
  const [series, setSeries] = useState([]);
  // const targets = ['Target1', 'Target2', 'Target3', 'Target4', 'Target5', 'Target1', 'Target2', 'Target3', 'Target4', 'Target5', 'Target1', 'Target2', 'Target3', 'Target4', 'Target5', 'Target1', 'Target2', 'Target3', 'Target4', 'Target5'];
  const [targets, setTargets] = useState([]);
  useEffect(() => {
    if (graphData) {
      setTargets(graphData?.targets || []);
    }
  },[]);
  const options = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 3700,
              color: "#3B76EF", // Color for other values
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: graphData?.drugs_list?.map((el) => el.drug_name) || [], // Safely access drugs_list
      labels: {
        formatter(value) {
          // Truncate label if it's longer than 10 characters
          return value.length > 10 ? `${value.substr(0, 10)}...` : value;
        },
      },
      tooltip: {
        enabled: true, // Enable tooltip on x-axis labels
        formatter(value) {
          return value; // Show the full label in the tooltip
        }
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide Y-axis labels
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `Target Value: ${val}`; // Display the y value as the target value
        },
      },
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const drugName = w.globals.labels[dataPointIndex]; // Get drug name
        const targetName = targets[seriesIndex]?.targetNames[dataPointIndex]; // Get target name
        const targetRank = series[seriesIndex][dataPointIndex]; // Get target value (y)
        const shouldDisableTooltip = targetName === 'None' || targetRank === 0; // Disable tooltip if target name is 'None' or target value is 0
        if (shouldDisableTooltip) return '';
        return `<div class="apexcharts-tooltip-y-group" style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 14px;margin-bottom: 3px; display: flex;flex-direction: column; padding: 5px;">
        <div style="display: flex;"><span class="apexcharts-tooltip-text-y-label">Drug Name: </span>
        <span class="apexcharts-tooltip-text-y-value">${drugName}</span></div>
        <div style="display: flex;"><span class="apexcharts-tooltip-text-y-label">Target Name: </span>
        <span class="apexcharts-tooltip-text-y-value">${targetName}</span></div>
        <div style="display: flex;"><span class="apexcharts-tooltip-text-y-label">Target Rank: </span>
        <span class="apexcharts-tooltip-text-y-value">${targetRank}</span></div>
        </div>`;
      },
    },
    legend: {
      show: false,
    },
  };

  const arrayToString = (array) => array.join(", ");

  const fetchData = () => {
    try {
      const data = graphData?.targets || []; // Safely access targets

      // Assuming the API returns an array of objects with drug names and values
      const formattedSeries = data.map((metric) => ({
        name: metric.name,
        data: metric.values.map((value, index) => ({
          x: graphData?.drugs_list?.[index]?.drug_name || "", // Safely access drug names
          y: value,
        })),
      }));

      setSeries(formattedSeries);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [graphData]); // Add graphData as a dependency

  return (
    <div id="chart">
      <div className="heat-map-main">
        <div className="phase-number">
          <div className="phase-number-value">{phase}</div>
        </div>
        <div className="heat-map-main-content">
          <div
            className="drug-circle"
            style={{
              display: "flex",
              width: `${60 * graphData?.drugs_list?.length + 130}px`,
              paddingRight: "30px",
              justifyContent: "space-between"
            }}
          >
            <div className="labels-column-content">
              <div className="moa">MOA</div>
            </div>
            {graphData?.drugs_list?.map((item) => (
              <div
                className="circle"
                key={item.drug_name}
                style={{
                  color: "#000",
                  fontSize: "11px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "60px",
                }}
              >
                <Tooltip
                  placement="bottomLeft"
                  title={arrayToString(item.moas?.map((moa) => moa.moa_name))}
                >
                  {arrayToString(item.moas?.map((moa) => moa.moa_name))}
                </Tooltip>
              </div>
            ))}
          </div>
          <div className="heat-map">
            <div className="labels-column-content">
              <div className="targets">Targets</div>
            </div>
            <Chart
              options={options}
              series={series}
              type="heatmap"
              height={(35 * graphData?.targets?.length) + 140}
              width={60 * graphData?.drugs_list?.length}
            />
          </div>
          <div className="labels-column-content">
            <div className="drugs">Drugs</div>
          </div>
        </div>
      </div>
    </div>
  );
}

HeatMapView.propTypes = {
  graphData: PropTypes.shape({
    drugs_list: PropTypes.arrayOf(
      PropTypes.shape({
        drug_name: PropTypes.string.isRequired,
        moas: PropTypes.arrayOf(
          PropTypes.shape({
            moa_name: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    targets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.number).isRequired,
      })
    ).isRequired,
  }).isRequired,
  phase: PropTypes.string.isRequired,
};

export default HeatMapView;
