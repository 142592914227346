import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'antd';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { terminalsPath } from '../../../../app/components/Card/data';
import Mixpanel from '../../../../utils/mixpanel';
import { uploadSmileDocumentAction, uploadSmileDocumentResetAction } from '../InputBox/logic';
import { generateSARLeadAction, generateSARLeadResetAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';

const { TextArea } = Input;

function SaveProjectModal({
  open, setOpen, requestBody, formData, service,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState('My Project');
  const [description, setDescription] = useState('');
  const [navigating, setNavigating] = useState(false);

  const uploadSmileDocumentResponse = useAppSelector((state) => state.uploadSmileDocumentResponse);
  const generateSARLeadResponse = useAppSelector((state) => state.generateSARLeadResponse);

  const onSaveAndGenerate = () => {
    const newRequestBody = requestBody.params;
    newRequestBody.job_name = projectName;
    setNavigating(true);
    if (description) newRequestBody.description = description;
    if (formData) {
      dispatch(uploadSmileDocumentAction({ params: queryString.stringify(newRequestBody), body: formData }));
    } else {
      dispatch(generateSARLeadAction({ ...requestBody, params: queryString.stringify(newRequestBody) }));
    }
    // navigate(`${terminalsPath.metad3_sar_denovo_workspace}?service=${service}`);
    toast(`Project “${projectName}” is added successfully in My workspace`, {
      position: 'top-right',
      autoClose: 2000,
      pauseOnHover: false,
      draggable: true,
      newestOnTop: true,
      type: 'default',
      hideProgressBar: false,
      closeButton: true,
    });
    // setOpen(false);
    Mixpanel.track('SAR Project saved', {
      action: 'SAR Save & project button clicked', service, project_name: projectName, description, request_body: { ...requestBody, params: queryString.stringify(newRequestBody) },
    });
  };

  useEffect(() => {
    if (uploadSmileDocumentResponse.flag && !uploadSmileDocumentResponse.loading) {
      dispatch(uploadSmileDocumentResetAction());
      setOpen(false);
      setNavigating(false);
      navigate(`${terminalsPath.metad3_sar_denovo_workspace}?service=${service}`);
    }
  }, [uploadSmileDocumentResponse]);

  useEffect(() => {
    if (generateSARLeadResponse.flag && !generateSARLeadResponse.loading) {
      dispatch(generateSARLeadResetAction());
      setOpen(false);
      setNavigating(false);
      navigate(`${terminalsPath.metad3_sar_denovo_workspace}?service=${service}`);
    }
  }, [generateSARLeadResponse]);

  return (
    <Modal
      footer={null}
      closeIcon={null}
      closable
      onCancel={() => {
        setOpen(false); Mixpanel.track('SAR Save Project Modal Closed', {
          action: 'SAR Close Button Clicked',
          service: 'Prioritize Top Indications',
          module: 'IEP',
        });
      }}
      open={open}
      className="save-project-modal"
    >
      <div className="save-project-title">Save Project</div>
      <div className="save-project-content">
        <div className="save-project-name">
          <div className="save-project-name-title">Save new project as per new input changes</div>
          <div className="save-project-name-input">
            <Input placeholder="Enter name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
          </div>
        </div>
        <div className="save-project-description">
          <div className="save-project-description-title">
            Description
            <span className="description-optional">(Optional)</span>
          </div>
          <div className="save-project-description-input">
            <TextArea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
        <div className="btns-row">
          <div className="cancel-btn" onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} aria-hidden>Cancel</div>
          <Button disabled={!projectName || navigating} onClick={() => onSaveAndGenerate()} className="save-generate-btn">
            { navigating ? 'Navigating' : 'Save & Generate output'}
          </Button>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
}

SaveProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  requestBody: PropTypes.objectOf.isRequired,
  formData: PropTypes.objectOf.isRequired,
  service: PropTypes.string.isRequired,
};

export default SaveProjectModal;
