/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { terminalsPath } from '../../../../app/components/Card/data';
import { iepWorkspaceStatusResetAction } from '../../pages/MyWorkspace/logic';
import { generateIndicationsResetAction } from '../../pages/IEPInputPage/logic';
import { getCookie } from '../../../../utils';
import Mixpanel from '../../../../utils/mixpanel';

function TopIndicationsWorkspaceDetails({ job }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmailId = getCookie('usersEmailId');

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };

  const onViewOutput = () => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_iep_output}?jobId=${job.job_id}`);
      Mixpanel.track('IEP View Output Clicked', {
        action: 'IEP View Output Clicked from IEP workspace',
        job_name: job.job_name,
        job_id: job.job_id,
        service: 'Top Indications',
        module: 'IEP',
      });
    } else {
      navigate(terminalsPath.metad3_iep);
      Mixpanel.track('IEP Resubmit Output Clicked', {
        action: 'IEP Resubmit Output Clicked from IEP workspace',
        job_name: job.job_name,
        job_id: job.job_id,
        service: 'Top Indications',
        module: 'IEP',
      });
    }
  };

  const eachApproachWeightage = (title, weightage) => (
    <div className="each-approach-weightage">
      <div className="weightage-title">{title}</div>
      <div className="weightage-value">{`${weightage}%`}</div>
    </div>
  );

  const tagsGroup = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1.map((item) => (
          <Tag key={item} className="sample-tags" closable={false}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );

  const tagsGroupProteins = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1.map((item) => (
          <Tag key={item.preferred_name} className="sample-tags" closable={false}>
            {item.preferred_name}
          </Tag>
        ))}
      </div>
    </div>
  );

  const getHeadingName = (proteins) => {
    let str = "";
    for (let i = 0; i < proteins.length; i++) {
      if (i === proteins.length - 1) {
        str = str + proteins[i].protein_name;
      } else {
        str = str + proteins[i].protein_name + ", ";
      }
    }
    return str;
  }

  return (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{getHeadingName(job?.user_input.proteins)}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                dispatch(iepWorkspaceStatusResetAction({}));
                dispatch(generateIndicationsResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Description</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-list">{job?.description ? job?.description : 'No description'}</div>
              </div>
            </div>
          </div>
        ) : null}
        {job?.user_input?.proteins?.length || job?.user_input?.synonyms_removed?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Synonyms Changes</div>
            <div className="synonyms-changes-content">
              <div className="added-synonyms"  style={{ display: 'flex' }}>
                <div className="added-synonyms-title">Added Synonyms</div>
                <div className="removed-synonyms-title">Removed Synonyms</div>
              </div>
              {job?.user_input?.proteins?.map((item) => {
                return <div className="synonyms-list-content">
                  <div className="protein-name">{item?.protein_name}</div>
                  <div className='synonyms-list'>
                    <div className="added-synonyms-list">{item.synonyms_added ? tagsGroup(item.synonyms_added) : 'No added synonyms'}</div>
                    <div className="removed-synonyms-list">{item.synonyms_removed ? tagsGroup(item.synonyms_removed) : 'No removed synonyms'}</div>
                  </div>
                </div>
              })}
              {/* <div className="added-synonyms">
                <div className="added-synonyms-title">Added Synonyms</div>
                <div className="added-synonyms-list">{job?.user_input.synonyms_added ? tagsGroup(job?.user_input.synonyms_added) : 'No added synonyms'}</div>
              </div>
              <div className="removed-synonyms">
                <div className="removed-synonyms-title">Removed Synonyms</div>
                <div className="removed-synonyms-list">{job?.user_input.synonyms_removed ? tagsGroup(job?.user_input.synonyms_removed) : 'No removed synonyms'}</div>
              </div> */}
            </div>
          </div>
        ) : null}
        {job?.user_input?.drugs_added?.length || job?.user_input?.drugs_removed?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Drugs Changes</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-title">Added Drugs</div>
                <div className="added-synonyms-list">{job?.user_input.drugs_added ? tagsGroup(job?.user_input.drugs_added) : 'No added drugs'}</div>
              </div>
              <div className="removed-synonyms">
                <div className="removed-synonyms-title">Removed Drugs</div>
                <div className="removed-synonyms-list">{job?.user_input.drugs_removed ? tagsGroup(job?.user_input.drugs_removed) : 'No removed drugs'}</div>
              </div>
            </div>
          </div>
        ) : null}
        {job?.user_input.approach_weightage !== undefined && Object.keys(job?.user_input?.approach_weightage).length ? (
          <div className="approach-weightage">
            <div className="approach-weightage-title">Approach Weightages</div>
            <div className="approach-weightage-row" style={{ display: 'flex' }}>
              {eachApproachWeightage('Network Approach', job.user_input.approach_weightage.network_disease_similarity + job.user_input.approach_weightage.network_molecular_function + job.user_input.approach_weightage.network_ppi + job.user_input.approach_weightage.network_literature_mining)}
              {eachApproachWeightage('Literature Mining', job?.user_input.approach_weightage?.literature_mining)}
              {eachApproachWeightage('Clinical Trial', job?.user_input.approach_weightage?.clinical_trial)}
            </div>
            <div className="approach-weightage-row" style={{ display: 'flex' }}>
              {eachApproachWeightage('Protein-Protein Interactions', job?.user_input.approach_weightage?.network_ppi)}
              {eachApproachWeightage('Network Literature Mining', job?.user_input.approach_weightage?.network_literature_mining)}
              {eachApproachWeightage('Disease Similarity', job?.user_input.approach_weightage?.network_disease_similarity)}
              {eachApproachWeightage('Molecular Function Similarity', job?.user_input.approach_weightage?.network_molecular_function)}
            </div>
          </div>
        ) : null}
        {job.user_input.proteins[0].proteins_added?.length || job.user_input.proteins[0].proteins_removed?.length ? (
          <div className="synonyms-changes">
          <div className="synonyms-changes-title">Associated Protein Changes</div>
          <div className="synonyms-changes-content">
            <div className="added-synonyms"  style={{ display: 'flex' }}>
              <div className="added-synonyms-title">Added Proteins</div>
              <div className="removed-synonyms-title">Removed Proteins</div>
                {/* <div className="added-proteins-list">{tagsGroup(job.user_input.proteins_removed?.map((p) => p.preferred_name) || [])}</div> */}
              </div>
              {job?.user_input?.proteins?.map((item) => {
                return <div className="synonyms-list-content">
                  <div className="protein-name">{item?.protein_name}</div>
                  <div className='synonyms-list'>
                    <div className="added-synonyms-list">{item.proteins_added ? tagsGroupProteins(item.proteins_added) : 'No added proteins'}</div>
                    <div className="removed-synonyms-list">{item.proteins_removed ? tagsGroupProteins(item.proteins_removed) : 'No removed proteins'}</div>
                  </div>
                </div>
              })}
            </div>
          </div>
        ) : null}
        {job.user_input.proteins_added?.length || job.user_input.proteins_removed?.length ? <div className="input-details-footer">* 1- Disease Similarity Score, 2-Protein protein score, 3-Molecular function score, 4-Literature mining score</div> : null}
      </div>
    </div>
  );
}

TopIndicationsWorkspaceDetails.propTypes = {
  job: PropTypes.objectOf.isRequired,
};

export default TopIndicationsWorkspaceDetails;
