import worlddata from './CountriesJson/worlddata.json';
import argentina from './CountriesJson/argentina.json';
import australia from './CountriesJson/australia.json';
import austria from './CountriesJson/austria.json';
import bosniaandherzegovina from './CountriesJson/bosniaandherzegovina.json';
import brazil from './CountriesJson/brazil.json';
import bulgaria from './CountriesJson/bulgaria.json';
import canada from './CountriesJson/canada.json';
import chile from './CountriesJson/chile.json';
import china from './CountriesJson/china.json';
import colombia from './CountriesJson/colombia.json';
import croatia from './CountriesJson/croatia.json';
import czechrepublic from './CountriesJson/czechrepublic.json';
import denmark from './CountriesJson/denmark.json';
import estonia from './CountriesJson/estonia.json';
import finland from './CountriesJson/finland.json';
import france from './CountriesJson/france.json';
import georgia from './CountriesJson/georgia.json';
import germany from './CountriesJson/germany.json';
import greece from './CountriesJson/greece.json';
import hungary from './CountriesJson/hungary.json';
import india from './CountriesJson/india.json';
import ireland from './CountriesJson/ireland.json';
import israel from './CountriesJson/israel.json';
import italy from './CountriesJson/italy.json';
import japan from './CountriesJson/japan.json';
import korearepublicof from './CountriesJson/korearepublicof.json';
import latvia from './CountriesJson/latvia.json';
import lithuania from './CountriesJson/lithuania.json';
import malaysia from './CountriesJson/malaysia.json';
import mexico from './CountriesJson/mexico.json';
import unitedstates from './CountriesJson/unitedstate.json';
import moldovarepublicof from './CountriesJson/moldovarepublicof.json';
import netherlands from './CountriesJson/netherlands.json';
import newzealand from './CountriesJson/newzealand.json';
import norway from './CountriesJson/norway.json';
import peru from './CountriesJson/peru.json';
import philippines from './CountriesJson/philippines.json';
import poland from './CountriesJson/poland.json';
import portugal from './CountriesJson/portugal.json';
import puertorico from './CountriesJson/puertorico.json';
import romania from './CountriesJson/romania.json';
import serbia from './CountriesJson/serbia.json';
import singapore from './CountriesJson/singapore.json';
import slovakia from './CountriesJson/slovakia.json';
import slovenia from './CountriesJson/slovenia.json';
import southafrica from './CountriesJson/southafrica.json';
import spain from './CountriesJson/spain.json';
import sweden from './CountriesJson/sweden.json';
import switzerland from './CountriesJson/switzerland.json';
import taiwan from './CountriesJson/taiwan.json';
import thailand from './CountriesJson/thailand.json';
import turkey from './CountriesJson/turkey.json';
import ukraine from './CountriesJson/ukraine.json';
import unitedkingdom from './CountriesJson/unitedkingdom.json';
import vietnam from './CountriesJson/vietnam.json';

const countries = {
  worlddata,
  argentina,
  australia,
  austria,
  bosniaandherzegovina,
  brazil,
  bulgaria,
  canada,
  chile,
  china,
  colombia,
  croatia,
  czechrepublic,
  denmark,
  estonia,
  finland,
  france,
  georgia,
  germany,
  greece,
  hungary,
  india,
  ireland,
  israel,
  italy,
  japan,
  korearepublicof,
  latvia,
  lithuania,
  malaysia,
  mexico,
  moldovarepublicof,
  netherlands,
  newzealand,
  norway,
  peru,
  philippines,
  poland,
  puertorico,
  portugal,
  romania,
  serbia,
  singapore,
  slovakia,
  slovenia,
  southafrica,
  spain,
  sweden,
  switzerland,
  taiwan,
  thailand,
  turkey,
  ukraine,
  unitedkingdom,
  unitedstates,
  vietnam,
};

export default function getCountryData(countryName) {
  if (countries[countryName]) {
    return countries[countryName];
  }
  return null;
}

// function getCountryData(countryName) {
//   setJsonLoading(true)
//   import(`../GetJsonData/CountriesJson/${countryName}.json`)
//     .then((data) => {
//       console.log('data--->', data)
//       setJsonLoading(false)
//       return data
//     })
//     .catch((error) => {
//       console.log('country--->', error)
//     });
// }
