import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import CustomPieChart from '../../container/Graph/PieChart/PieChart';
import ConfidenceMeterGaugeChart from '../../container/Graph/ConfidenceMeterGaugeChart';

function PosComponent({ item, handleClick, isDetails }) {
  return (
    <div className="pos-component" onClick={() => handleClick(item)} style={{ cursor: isDetails ? 'default' : 'pointer' }} aria-hidden>
      {item && (
      <div className="ctp-content-upper" style={{ display: 'flex' }}>
        <div className="card-content-left-part">
          <div className="ctp-content-upper-heading">Probability of Success</div>
          <div className="ctp-content-upper-card-content-ctp-value">
            <div className="ctp-content-upper-card-content-ctp-value-percentage">
              <CustomPieChart score={parseFloat(item?.pos?.probability).toFixed(2)} status={item?.pos?.prediction} />
              <div className={`gauge-content ${String(item?.pos?.prediction).toLowerCase()}`}>{item?.pos?.prediction}</div>
            </div>
          </div>
        </div>
        <div className="card-content-ctp-main">
          <div className="ctp-content-upper-card-content-ctp-content">
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-id">
                <a
                  aria-label="innoplexus-logo"
                  label="innoplexus"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://terminal.ontosight.ai/clinicalTrials/analyticsResults?query=%5B%7B%22field_name%22%3A%22indication%22%2C%22label%22%3A%22Free%20Text%22%2C%22value%22%3A%22free_text%22%2C%22type%22%3A%22free_text%22%2C%22name%22%3A%22free_text%22%2C%22currentFilter%22%3A%5B%22${item?.pos?.trial}%22%5D%2C%22currentKeys%22%3A%5B%22${item?.pos?.trial}%22%5D%2C%22display_name%22%3A%22Free%20Text%22%7D%5D&sort=null`}
                  className="clickable-nct-id"
                >
                  {item?.pos?.trial}
                </a>
              </div>
              <div className="ctp-content-upper-blocks-values-phase">
                {`${item?.pos?.phase}`}
                {item?.pos?.trial_status ? ` - ${item?.pos?.trial_status}` : ''}
              </div>
            </div>

            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-title">{item?.pos?.title}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Condition:</div>
              <div className="ctp-content-upper-blocks-values">{item?.pos?.condition}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Start Date:</div>
              <div className="ctp-content-upper-blocks-values">{`${item?.pos?.start_date} | ${item?.pos?.anticipated_enrollment} Anticipated Enrollment`}</div>
            </div>
            <div className="ctp-content-upper-blocks ctp-content-upper-blocks-dual">
              <div className="ctp-content-upper-blocks-keys">(Category) Primary Outcome:</div>
              <div className="ctp-content-upper-blocks-values">
                {/* {console.log('item?.pos?.primary_outcome', item?.pos?.primary_outcome)} */}
                { (item?.pos?.primary_outcome?.map((itm) => (
                  <div className="ctp-content-upper-blocks-values-box">
                    <div className="ctp-content-upper-blocks-values-right">
                      <Tooltip placement="topLeft" title={`(${itm?.predicted_labels})`}>{`(${itm?.predicted_labels})`}</Tooltip>
                    </div>
                    <div className="ctp-content-upper-blocks-values-left">
                      <div className="ctp-content-upper-blocks-values-left-text">
                        <Tooltip placement="topLeft" title={itm?.measure}>{itm?.measure}</Tooltip>
                      </div>
                    </div>
                  </div>
                ))) }
              </div>
            </div>
          </div>
        </div>
        <div className="card-content-right-part">
          <div className="ctp-content-upper-heading">Confidence Meter</div>
          <div className="ctp-content-upper-card-content-ctp-value">
            <div className="ctp-content-upper-card-content-ctp-value-percentage">
              {/* <CustomPieChart score={parseFloat(item?.pos?.probability).toFixed(2)} status={item?.pos?.prediction} /> */}
              <ConfidenceMeterGaugeChart confidenceScore={parseFloat(item?.pos?.confidence_score)} />
              {/* <div className={`gauge-content ${String(item?.pos?.prediction).toLowerCase()}`}>{item?.pos?.prediction}</div> */}
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

PosComponent.propTypes = {
  item: PropTypes.objectOf.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDetails: PropTypes.bool.isRequired,
};

export default PosComponent;
