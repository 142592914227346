import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_EXPRESSION_DATA = 'GET_EXPRESSION_DATA';
const GET_EXPRESSION_DATA_SUCCESS = 'GET_EXPRESSION_DATA_SUCCESS';
const GET_EXPRESSION_DATA_FAILURE = 'GET_EXPRESSION_DATA_FAILURE';

export const getExpressionDataAction = createAction(GET_EXPRESSION_DATA);
const getExpressionDataSuccess = createAction(GET_EXPRESSION_DATA_SUCCESS);
const getExpressionDataFailure = createAction(GET_EXPRESSION_DATA_FAILURE);

export const getExpressionDataEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPRESSION_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/expressionGraphData?${action.payload}`, 'get')).pipe(
    map((res) => getExpressionDataSuccess(res)),
    catchError((err) => of(getExpressionDataFailure(err))),
  )),
);

export const getExpressionDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExpressionDataAction, (state) => {
      state.loading = true;
    })
      .addCase(getExpressionDataSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getExpressionDataFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
