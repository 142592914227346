/* eslint-disable */
import { index } from "d3";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import HeatMapView from "../../components/HeatMapView";
import { getPhaseDistributionOutputAction } from "./logic";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import useAppSelector from "../../../../utils/redux-hook";
import Loader from "../../../../common/components/Loader";

function PhaseDistributionView({ jobId, diseaseName }) {
  const dispatch = useDispatch();
  const getPhaseDistributionDataResponse = useAppSelector(
    (state) => state.getPhaseDistributionDataResponse
  );
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const request = {
      job_id: jobId,
      disease_name: diseaseName,
    };
    dispatch(getPhaseDistributionOutputAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (getPhaseDistributionDataResponse) {
      setGraphData(getPhaseDistributionDataResponse?.data?.data?.phase_1);
    }
  }, [getPhaseDistributionDataResponse]);

  return (
    <div className="heat-map-container">
      <div className="heat-map-title-row">
        <div className="heat-map-title">
          {`Phase-wise Distribution of Targets for ${diseaseName}`}
        </div>
        <div className="heat-map-indicator">
          <div className="indicator-bar"></div>
          <div className="indicator-values">
            <div className="min">0</div>
            <div className="title">Target Rank</div>
            <div className="max">100</div>
          </div>
        </div>
      </div>
      <Loader loading={getPhaseDistributionDataResponse.loading}>
        <div className="heat-map-phase">
          <HeatMapView
            graphData={getPhaseDistributionDataResponse?.data?.data?.phase_1}
            phase="Phase 1"
          />
        </div>
        <div className="heat-map-phase">
          <HeatMapView
            graphData={getPhaseDistributionDataResponse?.data?.data?.phase_2}
            phase="Phase 2"
          />
        </div>
        <div className="heat-map-phase">
          <HeatMapView
            graphData={getPhaseDistributionDataResponse?.data?.data?.phase_3}
            phase="Phase 3"
          />
        </div>
        <div className="heat-map-phase">
          <HeatMapView
            graphData={getPhaseDistributionDataResponse?.data?.data?.phase_4}
            phase="Phase 4"
          />
        </div>
      </Loader>
    </div>
  );
}

PhaseDistributionView.propTypes = {
  jobId: PropTypes.string.isRequired,
  diseaseName: PropTypes.string.isRequired,
};

export default PhaseDistributionView;
