import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip as RechartTooltip, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import Loader from '../../../../../common/components/Loader';
import { getExpressionDataAction } from './logic';
import useAppSelector from '../../../../../utils/redux-hook';

function ExpressionGraph({ jobId }) {
  const [proteinData, setProteinData] = useState([]);
  const [geneData, setGeneData] = useState([]);
  const dispatch = useDispatch();
  const getExpressionDataResponse = useAppSelector((state) => state.getExpressionDataResponse);
  const dropdownValues = [
    {
      value: 'Protein Expression',
      label: 'Protein Expression',
    },
    {
      value: 'Gene Expression',
      label: 'Gene Expression',
    },
  ];
  const [currentValue, setCurrentValue] = useState(dropdownValues[0].value);

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getExpressionDataAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getExpressionDataResponse) {
      setProteinData(getExpressionDataResponse?.data?.protein);
      setGeneData(getExpressionDataResponse?.data?.gene);
      console.log(getExpressionDataResponse, geneData, jobId);
    }
  }, [getExpressionDataResponse]);

  const expressionCustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#000' }}>
          <div className="custom-tooltip-content" style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="label">{`${label}`}</p>
            <p className="pass" style={{ color: payload[0].value < 0 ? '#6DBAE5' : '#CBA5E9' }}>
              {`${payload[0].value}`}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="expression-graph-main">
      <div className="expression-graph-title-row card-content-ctp-title">
        <div className="expression-graph-title card-content-ctp-header">Expression Graph</div>
        <div className="expression-graph-dropdown-select card-content-dropdown-select">
          <Select
            value={currentValue}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              setCurrentValue(value);
            }}
            options={dropdownValues}
          />
        </div>
      </div>
      <div className="expression-graph-container">
        <Loader loading={false}>
          <ResponsiveContainer width="100%" height={1200}>
            <BarChart
              layout="vertical"
              width={450}
              height={600}
              data={currentValue === 'Protein Expression' ? proteinData : geneData || proteinData}
              barGap={10}
              margin={{
                top: 5,
                right: 30,
                left: 50,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                label={{
                  value: '-log10(FC-value)  ---> ',
                  position: 'insideBottom',
                  offset: -5,
                  fill: '#121417',
                  fontSize: '18',
                  fontWeight: '600',
                }}
                tick={{
                  fill: '#121417',
                  fontSize: '16',
                  fontWeight: '400',
                }}
              />
              <YAxis
                width={300}
                dataKey="name"
                type="category"
                tick={{
                  fill: '#121417',
                  fontSize: '16',
                  fontWeight: '400',
                }}
              />
              <RechartTooltip
                content={expressionCustomToolTip} // Display only the value in the tooltip
                cursor={false}
              />
              <ReferenceLine x={0} stroke="transparent" />
              {' '}
              <Bar dataKey="pv" fill="#CBA5E9" barSize={1000} />
              <Bar dataKey="uv" fill="#6DBAE5" barSize={1000} />
              <Legend
                verticalAlign="top"
                align="center"
                wrapperStyle={{ margin: '-10px' }}
                formatter={(value, entry) => {
                  if (entry.dataKey === 'pv') {
                    return '+log10(FC-value)'; // Customize this sentence for 'pv'
                  }
                  if (entry.dataKey === 'uv') {
                    return '-log10(FC-value)'; // Customize this sentence for 'uv'
                  }
                  return null;
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </Loader>
      </div>
    </div>
  );
}

ExpressionGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default ExpressionGraph;
