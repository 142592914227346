// /* eslint-disable */
// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import * as d3 from 'd3';

// function BubbleChart({ data }) {
//   const svgRef = useRef(null);
//   const tooltip = d3.select('body').append('div').style('position', 'absolute').style('padding', '10px').style('background', '#fff').style('border', '1px solid #000').style('border-radius', '5px').style('opacity', 0).style('pointer-events', 'none');

//   const showTooltip = function (d) {
//     tooltip.transition().duration(200).style('opacity', 0.9);
//     tooltip
//       .html(
//         '<span style="color:grey">Indication: </span><span style="color:black">' +
//           `${d?.data?.key
//             ?.split(' ')
//             .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ')}<br>
//       <span style="color:grey">Therapeutic Area: </span><span style="color:black">` +
//           `${d?.data?.ta
//             ?.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
//             .split(' ')
//             .join(' ')}<br>
//       <span style="color:grey">Overall Score: </span><span style="color:black">` +
//           `${d.data.score} | <span style="color:${d.data.score > 0.6 ? '#0A7201' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#A28309' : '#EA3913'}; background-color: ${d.data.score > 0.6 ? '#C3F2BC' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#FEEEB1' : '#FEE5DF'}; padding: 5px; border-radius: 5px;">${d.data.score > 0.6 ? 'High' : d.data.score > 0.4 && d.data.score <= 0.6 ? 'Medium' : 'Low'}</span>
//       `,
//       )
//       .style('left', `${d3.event.pageX}px`)
//       .style('top', `${d3.event.pageY - 28}px`);
//   };

//   const hideTooltip = function () {
//     tooltip.transition().duration(200).style('opacity', 0);
//   };

//   useEffect(() => {
//     if (!data) {
//       return;
//     }

//     const width = 1000;
//     const height = 800;

//     const bubble = d3.pack().size([width, height]).padding(5);

//     const svg = d3.select(svgRef.current).attr('width', width).attr('height', height).style('stroke-width', 0);
//     const root = d3.hierarchy({ children: data }).sum((d) => d.score);
//     const nodes = bubble(root).descendants();
//     svg
//       .selectAll('circle')
//       .data(nodes)
//       .enter()
//       .append('circle')
//       /* eslint no-underscore-dangle: 0 */
//       .attr('id', (d) => d.data?.key)
//       .attr('cx', (d) => d.x)
//       .attr('cy', (d) => d.y)
//       .attr('r', (d) => d.r)
//       .attr('label', (d) => d.data?.score)
//       .style('fill', (d) => d?.data?.color)
//       .style('stroke', 'none')
//       .style('stroke-width', 0)
//       .style('opacity', 0.9)
//       .on('mouseover', (d) => {
//         if (d?.data?.key != null && d?.data?.ta != null && d?.data?.score != null) {
//           showTooltip(d);
//         }
//       })
//       .on('mouseout', hideTooltip);
//   }, [data]);

//   return <svg ref={svgRef} />;
// }

// BubbleChart.propTypes = {
//   data: PropTypes.arrayOf.isRequired,
// };

// export default BubbleChart;

/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

function BubbleChart({ data }) {
  const svgRef = useRef(null);
  const tooltip = d3.select('body').append('div').style('position', 'absolute').style('padding', '10px').style('background', '#fff').style('border', '1px solid #000').style('border-radius', '5px').style('opacity', 0).style('pointer-events', 'none');

  const showTooltip = function (d) {
    tooltip.transition().duration(200).style('opacity', 0.9);
    tooltip
      .html(
        '<span style="color:grey">Indication: </span><span style="color:black">' +
          `${d?.data?.key
            ?.split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}<br>
      <span style="color:grey">Therapeutic Area: </span><span style="color:black">` +
          `${d?.data?.ta
            ?.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .split(' ')
            .join(' ')}<br>
      <span style="color:grey">Overall Score: </span><span style="color:black">` +
          `${d.data.score} | <span style="color:${d.data.score > 0.6 ? '#0A7201' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#A28309' : '#EA3913'}; background-color: ${d.data.score > 0.6 ? '#C3F2BC' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#FEEEB1' : '#FEE5DF'}; padding: 5px; border-radius: 5px;">${d.data.score > 0.6 ? 'High' : d.data.score > 0.4 && d.data.score <= 0.6 ? 'Medium' : 'Low'}</span>
      `,
      )
      .style('left', `${d3.event.pageX}px`)
      .style('top', `${d3.event.pageY - 28}px`);
  };

  const hideTooltip = function () {
    tooltip.transition().duration(200).style('opacity', 0);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const width = 1400;
    const height = 1400;

    const bubble = d3.pack().size([width, height]).padding(5);

    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height).style('stroke-width', 0);
    const root = d3.hierarchy({ children: data }).sum((d) => d.score);
    const nodes = bubble(root).descendants();
    
    // Draw a rectangle for the outer boundary
    svg.append('rect')
    .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none') // Set fill to none or a color if needed
      .style('stroke', '#000') // Optional: add a stroke for visibility
      .style('stroke-width', 0);
      
      // Draw circles for each node
      svg
      .selectAll('rect')
      .data(nodes)
      .enter()
      .append('circle')
      /* eslint no-underscore-dangle: 0 */
      .attr('id', (d) => d.data?.key)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => d.data.children === undefined ? d.r * 0.85 : d.r * 0.97)
      .attr('label', (d) => d.data?.score)
      .style('fill', (d) => d?.data?.color && d.data.children !== undefined ? "transparent" : "#759CEA") // Default color if not specified
      .style('stroke', '#759CEA')
      .style('stroke-width', (d) => d.data.children === undefined ? 0 : 1)
      .style('opacity', 0.5)
      .on('mouseover', (d) => {
        if (d?.data?.key != null && d?.data?.ta != null && d?.data?.score != null) {
          showTooltip(d);
        }
      })
      .on('mouseout', hideTooltip);
      
      // Add text labels inside the bubbles
      svg.selectAll("text")
        .data(nodes)
        .enter()
        .append("text")
        .text(d => {
          // Display only the first three characters followed by ellipsis
          return d.data.key && d.data.children === undefined ? (d.data.key.length > 6 ? d.data.key.substring(0, 5) + ".." : d.data.key) : "";
        }) // Display the key or any other relevant text
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        // Calculate font size based on radius
        .attr("font-size", (d) => Math.max(d.r / 2.7, 5)) // Adjust divisor for desired scaling
        .attr("fill", "#000") // Set text color to black
        .attr("x", (d) => d.x) // Position text at the center of the circle
        .attr("y", (d) => d.y);// Position text at the center of the circle
      
  }, [data]);

  return <svg ref={svgRef} />;
}

BubbleChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    ta: PropTypes.string,
    score: PropTypes.number.isRequired,
    color: PropTypes.string,
  })).isRequired,
};

export default BubbleChart;