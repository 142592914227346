import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall, { apiCallwithoutCT } from '../../../../utils';

// get Output details
const GET_ONCO_TAGGING_OUTPUT = 'GET_ONCO_TAGGING_OUTPUT';
const GET_ONCO_TAGGING_OUTPUT_SUCCESS = 'GET_ONCO_TAGGING_OUTPUT_SUCCESS';
const GET_ONCO_TAGGING_OUTPUT_FAILURE = 'GET_ONCO_TAGGING_OUTPUT_FAILURE';
const GET_MORE_ONCO_TAGGING_OUTPUT = 'GET_MORE_ONCO_TAGGING_OUTPUT';
const GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS = 'GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS';

export const getOncoTaggingOutputAction = createAction(GET_ONCO_TAGGING_OUTPUT);
const getOncoTaggingOutputSuccess = createAction(GET_ONCO_TAGGING_OUTPUT_SUCCESS);
const getOncoTaggingOutputFaliure = createAction(GET_ONCO_TAGGING_OUTPUT_FAILURE);
export const getMoreOncoTaggingOutputAction = createAction(GET_MORE_ONCO_TAGGING_OUTPUT);
const getMoreOncoTaggingOutputSuccess = createAction(GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS);

export const getOncoTaggingOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_ONCO_TAGGING_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_utilities}/getOntologyTags?${action.payload}`, 'post', {}, true, { Accept: 'application/json' })).pipe(
    map((res) => getOncoTaggingOutputSuccess(res)),
    catchError((err) => of(getOncoTaggingOutputFaliure(err))),
  )),
);
export const getMoreOncoTaggingOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_ONCO_TAGGING_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_utilities}/getOntologyTags?${action.payload}`, 'post', {}, true, { Accept: 'application/json' })).pipe(
    map((res) => getMoreOncoTaggingOutputSuccess(res)),
    catchError((err) => of(getOncoTaggingOutputFaliure(err))),
  )),
);
export const getOncoTaggingOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getOncoTaggingOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getOncoTaggingOutputSuccess, (state, action) => {
        // console.log('sate', state);
        state.data = {
          total_count: action.payload.response.total_count,
          data: [
            ...action.payload.response.data,
          ],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreOncoTaggingOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreOncoTaggingOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.total_count,
          data: [
            ...state.data.data,
            ...action.payload.response.data,
            // data: [...state.data.data.data, ...action.payload.response.data],
          ],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getOncoTaggingOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// upload file pathway enrichment
const PATHWAY_ENRICHMENT_UPLOAD_FILE = 'PATHWAY_ENRICHMENT_UPLOAD_FILE';
const PATHWAY_ENRICHMENT_UPLOAD_FILE_SUCCESS = 'PATHWAY_ENRICHMENT_UPLOAD_FILE_SUCCESS';
const PATHWAY_ENRICHMENT_UPLOAD_FILE_FAILURE = 'PATHWAY_ENRICHMENT_UPLOAD_FILE_FAILURE';
const PATHWAY_ENRICHMENT_UPLOAD_FILE_RESET = 'PATHWAY_ENRICHMENT_UPLOAD_FILE_RESET';

export const pathwayEnrichmentUploadFileAction = createAction(PATHWAY_ENRICHMENT_UPLOAD_FILE);
export const pathwayEnrichmentUploadFileResetAction = createAction(PATHWAY_ENRICHMENT_UPLOAD_FILE_RESET);

const pathwayEnrichmentUploadFileSuccess = createAction(PATHWAY_ENRICHMENT_UPLOAD_FILE_SUCCESS);
const pathwayEnrichmentUploadFileFailure = createAction(PATHWAY_ENRICHMENT_UPLOAD_FILE_FAILURE);

export const pathwayEnrichmentUploadFileEpic = (actions$) => actions$.pipe(
  ofType(PATHWAY_ENRICHMENT_UPLOAD_FILE),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_utilities}/getPathwayEnrichmentData?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => pathwayEnrichmentUploadFileSuccess(res)),
    catchError((err) => of(pathwayEnrichmentUploadFileFailure(err))),
  )),
);

export const pathwayEnrichmentUploadFileReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(pathwayEnrichmentUploadFileAction, (state) => {
        state.loading = true;
      })
      .addCase(pathwayEnrichmentUploadFileSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(pathwayEnrichmentUploadFileFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(pathwayEnrichmentUploadFileResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// get utilities pathway enrichment
const GET_UTILITIES_PATHWAY_ENRICHMENT = 'GET_UTILITIES_PATHWAY_ENRICHMENT';
const GET_UTILITIES_PATHWAY_ENRICHMENT_SUCCESS = 'GET_UTILITIES_PATHWAY_ENRICHMENT_SUCCESS';
const GET_UTILITIES_PATHWAY_ENRICHMENT_FAILURE = 'GET_UTILITIES_PATHWAY_ENRICHMENT_FAILURE';

export const getUtilitiesPathwayEnrichmentAction = createAction(GET_UTILITIES_PATHWAY_ENRICHMENT);
const getUtilitiesPathwayEnrichmentSuccess = createAction(GET_UTILITIES_PATHWAY_ENRICHMENT_SUCCESS);
const getUtilitiesPathwayEnrichmentFailure = createAction(GET_UTILITIES_PATHWAY_ENRICHMENT_FAILURE);

export const getUtilitiesPathwayEnrichmentEpic = (actions$) => actions$.pipe(
  ofType(GET_UTILITIES_PATHWAY_ENRICHMENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_utilities}/getPathwayEnrichmentData?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getUtilitiesPathwayEnrichmentSuccess(res)),
    catchError((err) => of(getUtilitiesPathwayEnrichmentFailure(err))),
  )),
);

export const getUtilitiesPathwayEnrichmentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getUtilitiesPathwayEnrichmentAction, (state) => {
      state.loading = true;
    })
      .addCase(getUtilitiesPathwayEnrichmentSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getUtilitiesPathwayEnrichmentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// get utilities ppi network
const GET_UTILITIES_PPI_NETWORK = 'GET_UTILITIES_PPI_NETWORK';
const GET_UTILITIES_PPI_NETWORK_SUCCESS = 'GET_UTILITIES_PPI_NETWORK_SUCCESS';
const GET_UTILITIES_PPI_NETWORK_FAILURE = 'GET_UTILITIES_PPI_NETWORK_FAILURE';

export const getUtilitiesPPINetworkAction = createAction(GET_UTILITIES_PPI_NETWORK);
const getUtilitiesPPINetworkSuccess = createAction(GET_UTILITIES_PPI_NETWORK_SUCCESS);
const getUtilitiesPPINetworkFailure = createAction(GET_UTILITIES_PPI_NETWORK_FAILURE);

export const getUtilitiesPPINetworkEpic = (actions$) => actions$.pipe(
  ofType(GET_UTILITIES_PPI_NETWORK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_utilities}/getppiNetwork?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getUtilitiesPPINetworkSuccess(res)),
    catchError((err) => of(getUtilitiesPPINetworkFailure(err))),
  )),
);

export const getUtilitiesPPINetworkReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getUtilitiesPPINetworkAction, (state) => {
      state.loading = true;
    })
      .addCase(getUtilitiesPPINetworkSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getUtilitiesPPINetworkFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
// upload a document PPI network
const PPI_NETWORK_UPLOAD_FILE = 'PPI_NETWORK_UPLOAD_FILE';
const PPI_NETWORK_UPLOAD_FILE_SUCCESS = 'PPI_NETWORK_UPLOAD_FILE_SUCCESS';
const PPI_NETWORK_UPLOAD_FILE_FAILURE = 'PPI_NETWORK_UPLOAD_FILE_FAILURE';
const PPI_NETWORK_UPLOAD_FILE_RESET = 'PPI_NETWORK_UPLOAD_FILE_RESET';

export const ppiNetworkUploadFileAction = createAction(PPI_NETWORK_UPLOAD_FILE);
export const ppiNetworkUploadFileResetAction = createAction(PPI_NETWORK_UPLOAD_FILE_RESET);

const ppiNetworkUploadFileSuccess = createAction(PPI_NETWORK_UPLOAD_FILE_SUCCESS);
const ppiNetworkUploadFileFailure = createAction(PPI_NETWORK_UPLOAD_FILE_FAILURE);

export const ppiNetworkUploadFileEpic = (actions$) => actions$.pipe(
  ofType(PPI_NETWORK_UPLOAD_FILE),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_utilities}/uploadDocument${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => ppiNetworkUploadFileSuccess(res)),
    catchError((err) => of(ppiNetworkUploadFileFailure(err))),
  )),
);

export const ppiNetworkUploadFileReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(ppiNetworkUploadFileAction, (state) => {
        state.loading = true;
      })
      .addCase(ppiNetworkUploadFileSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(ppiNetworkUploadFileFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ppiNetworkUploadFileResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
