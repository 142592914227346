import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Flex, Input, Modal, Popover, Tag, Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { terminalsPath } from '../../../../app/components/Card/data';
import Mixpanel from '../../../../utils/mixpanel';
import useAppSelector from '../../../../utils/redux-hook';
import {
  generateIndicationsAction,
  generateIndicationsResetAction,
} from '../../pages/IEPInputPage/logic';

const { TextArea } = Input;

function EditCTModal({
  name, service, open, setOpen, drugsList, prevJobId,
}) {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    setProjectName(name);
  }, [name]);
  const [navigating, setNavigating] = useState(false);
  const dispatch = useDispatch();
  const [isOpenAddDrug, setIsOpenAddDrug] = useState(false);
  const [newDrug, setNewDrug] = useState('');
  const [drugsAdded, setDrugsAdded] = useState([]);
  const [drugsRemoved, setDrugsRemoved] = useState([]);
  const [drugsNet, setDrugsNet] = useState([]);
  const getIndicationOutputResponse = useAppSelector(
    (state) => state.getIndicationOutputResponse,
  );
  const generateIndicationsResponse = useAppSelector(
    (state) => state.generateIndicationsResponse,
  );

  const onSaveAndGenerate = () => {
    const newRequestBody = getIndicationOutputResponse.data.data?.jobData?.user_input;
    const requestBodyToUpdate = {
      ...newRequestBody,
      proteins: newRequestBody.proteins.map((item) => ({
        ...item,
        drugs_added: drugsAdded,
        drugs_removed: drugsRemoved,
        drugs_net: drugsNet,
      })),
    };
    const params = {
      job_name: projectName,
      description: description ?? undefined,
      service:
        getIndicationOutputResponse.data.data?.jobData?.user_input?.service,
      previous_job_id:
        getIndicationOutputResponse.data.data?.jobData?.cached_job_id
        ?? prevJobId,
    };
    const body = requestBodyToUpdate;

    setNavigating(true);
    // if(formData) {
    //   dispatch(uploadCCADocumentAction({ params: queryString.stringify(newRequestBody), body: formData, }));
    // } else {

    dispatch(
      generateIndicationsAction({ body, params: queryString.stringify(params) }),
    );
    // }
    navigate(`${terminalsPath.metad3_iep_workspace}?service=${service}`);
    toast(`Project “${projectName}” is added successfully in My workspace`, {
      position: 'top-right',
      autoClose: 2000,
      pauseOnHover: false,
      draggable: true,
      newestOnTop: true,
      type: 'default',
      hideProgressBar: false,
      closeButton: true,
    });
    // Mixpanel.track('Project saved', {
    //   action: 'Save & project button clicked', project_name: projectName, description, request_body: { ...requestBody, params: queryString.stringify(newRequestBody) },
    // });
  };

  useEffect(() => {
    setDrugsNet(drugsList);
  }, []);

  const addDrug = (item) => {
    if (!drugsAdded.includes(item)) {
      setDrugsAdded([item, ...drugsAdded]);
      setDrugsNet([item, ...drugsNet]);
    }
    setIsOpenAddDrug(!isOpenAddDrug);
    setNewDrug('');
    // Mixpanel.track('IEP Add Synonym IEP Advance Search', {
    //   action: 'IEP Synonym added', name: item, service: 'Prioritize Top Indications', module: 'IEP',
    // });
  };

  useEffect(() => {
    if (
      generateIndicationsResponse.flag
      && !generateIndicationsResponse.loading
      && navigating
    ) {
      dispatch(generateIndicationsResetAction());
      setOpen(false);
      setNavigating(false);
      navigate(`${terminalsPath.metad3_iep_workspace}?service=${service}`);
    }
  }, [generateIndicationsResponse]);

  const addDrugDropdown = () => (
    <div className="add-protein-dropdown-menu">
      <div className="searchbar-dashboard-content-search">
        <Input
          onChange={(e) => {
            setNewDrug(e.target.value);
          }}
          value={newDrug}
          placeholder="Enter Drug here"
        />
      </div>
      <div className="add-synonym-dropdown-menu-footer add-protein-dropdown-menu-footer">
        <Button
          className="clear-btn"
          onClick={() => setNewDrug('')}
          disabled={newDrug === ''}
        >
          Clear
        </Button>
        <Button
          className="add-btn"
          onClick={() => addDrug(newDrug)}
          disabled={newDrug === ''}
        >
          Add Drug
        </Button>
      </div>
    </div>
  );

  const handleResetToDefault = () => {
    setDrugsAdded([]);
    setDrugsRemoved([]);
    setDrugsNet(drugsList);
  };

  return (
    <Modal
      footer={null}
      closeIcon={null}
      closable
      onCancel={() => {
        setOpen(false);
        Mixpanel.track('Edit CT Modal Closed', {
          action: 'Close Button Clicked',
          service: 'Prioritize Top Indications',
          module: 'IEP',
        });
      }}
      open={open}
      className="save-project-modal"
    >
      <div className="save-project-title">Clinical Trials Enrichment Graph</div>
      <div className="save-project-content">
        <div className="advance-search-container">
          <div className="advance-search-synonyms">
            <div className="advance-search-synonyms-title-row">
              <div className="advance-search-synonyms-title">
                Associated Drugs
                <Tooltip title="Associated Drugs">
                  <div className="info-add-icon" />
                </Tooltip>
              </div>
              <div className="btns-container">
                <div
                  className="reset-to-default-btn"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleResetToDefault()}
                  aria-hidden
                >
                  Reset List to Default
                </div>
                <div className="add-btn-sec">
                  <Popover
                    overlayClassName="add-dropdown-popover"
                    open={isOpenAddDrug}
                    placement="bottomRight"
                    content={addDrugDropdown()}
                  >
                    <div className="add-dropdown">
                      <div
                        className="add-btn"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // Mixpanel.track('IEP Add Synonym clicked', {
                          //   action: 'add synonym dropdown click',
                          // });
                          setIsOpenAddDrug(!isOpenAddDrug);
                        }}
                        aria-hidden
                      >
                        Add Drug
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            {/* <Loader loading={getSynonymsResponse.loading}> */}
            {drugsNet?.length > 0 ? (
              <div className="tags-group">
                <Flex gap="4px 0" wrap="wrap">
                  {drugsNet
                    //   .filter((item) => !removedSynonyms.includes(item))
                    ?.map((item) => (
                      <Tag
                        key={item}
                        className="sample-tags"
                        closable
                        onClose={() => {
                          setDrugsRemoved([item, ...drugsRemoved]);
                          setDrugsNet(drugsNet.filter((x) => x !== item));
                        }}
                      >
                        {item}
                      </Tag>
                    ))}
                </Flex>
              </div>
            ) : (
              <div className="advance-search-synonyms-content">
                Synonyms of the input term will be generated here....
              </div>
            )}
            {/* </Loader> */}
          </div>
          <div className="save-project-name">
            <div className="save-project-name-title">
              Save new project as per new input changes
            </div>
            <div className="save-project-name-input">
              <Input
                placeholder="Enter new project name here"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
          </div>
          <div className="save-project-description">
            <div className="save-project-description-title">
              Description
              <span className="description-optional">(Optional)</span>
            </div>
            <div className="save-project-description-input">
              <TextArea
                placeholder="Enter short project description here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="btns-row">
            <div
              role="presentation"
              className="cancel-btn"
              onClick={() => setOpen(false)}
              style={{ cursor: 'pointer' }}
            >
              Cancel
            </div>
            <Button
              disabled={!projectName || navigating}
              onClick={() => onSaveAndGenerate()}
              className="save-generate-btn"
            >
              {navigating ? 'Navigating' : 'Save & Generate output'}
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
}

EditCTModal.propTypes = {
  name: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  drugsList: PropTypes.arrayOf.isRequired,
  prevJobId: PropTypes.string.isRequired,
};

export default EditCTModal;
