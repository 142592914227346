/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Graph from 'react-vis-network-graph';
import useAppSelector from '../../../../../utils/redux-hook';
// import { getPPINetworkGraphAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { XFilled } from '@ant-design/icons';
// import { nodes as allNodes, edges as allEdges } from './data';
import { getPPINetworkGraphAction } from '../PPINetworkGraph/logic';
import _ from 'lodash';



const dummyData = {
  "nodes": [
    {
      "id": 101,
      "label": "HSPA1B",
      "group": 1,
      "value": 20,
      "title": "HSPA1B",
      "color": "#5FD6E7",
      "x": 1380.0,
      "y": 0.0
    },
    {
      "id": 102,
      "label": "HSPA1A",
      "group": 1,
      "value": 20,
      "title": "HSPA1A",
      "color": "#5FD6E7",
      "x": 1300.0,
      "y": 80.0
    },
    {
      "id": 103,
      "label": "PDP1",
      "group": 1,
      "value": 20,
      "title": "PDP1",
      "color": "#5FD6E7",
      "x": 1220.0,
      "y": 9.797174393178826e-15
    },
    {
      "id": 104,
      "label": "MYADM",
      "group": 1,
      "value": 20,
      "title": "MYADM",
      "color": "#5FD6E7",
      "x": 1300.0,
      "y": -80.0
    },
    {
      "id": 105,
      "label": "XYLT2",
      "group": 1,
      "value": 20,
      "title": "XYLT2",
      "color": "#5FD6E7",
      "x": 1144.115427318801,
      "y": -90.00000000000001
    },
    {
      "id": 106,
      "label": "CGRRF1",
      "group": 1,
      "value": 20,
      "title": "CGRRF1",
      "color": "#5FD6E7",
      "x": 1253.4125718815462,
      "y": -173.8666487320323
    },
    {
      "id": 107,
      "label": "TUBB8",
      "group": 1,
      "value": 20,
      "title": "TUBB8",
      "color": "#5FD6E7",
      "x": 1390.0,
      "y": -155.88457268119896
    },
    {
      "id": 115,
      "label": "EFCAB8",
      "group": 1,
      "value": 20,
      "title": "EFCAB8",
      "color": "#5FD6E7",
      "x": 1473.8666487320322,
      "y": -46.58742811845372
    },
    {
      "id": 116,
      "label": "PCDHAC2",
      "group": 1,
      "value": 20,
      "title": "PCDHAC2",
      "color": "#5FD6E7",
      "x": 1455.884572681199,
      "y": 90.0
    },
    {
      "id": 117,
      "label": "PCDHGA6",
      "group": 1,
      "value": 20,
      "title": "PCDHGA6",
      "color": "#5FD6E7",
      "x": 1346.5874281184538,
      "y": 173.8666487320323
    },
    {
      "id": 118,
      "label": "CDHR4",
      "group": 1,
      "value": 20,
      "title": "CDHR4",
      "color": "#5FD6E7",
      "x": 1210.0000000000002,
      "y": 155.88457268119905
    },
    {
      "id": 119,
      "label": "CAPSL",
      "group": 1,
      "value": 20,
      "title": "CAPSL",
      "color": "#5FD6E7",
      "x": 1126.1333512679678,
      "y": 46.587428118453744
    },
    {
      "id": 120,
      "label": "PRRG4",
      "group": 1,
      "value": 20,
      "title": "PRRG4",
      "color": "#5FD6E7",
      "x": 1440.0,
      "y": 242.48711305964287
    },
    {
      "id": 121,
      "label": "EFCAB14",
      "group": 1,
      "value": 20,
      "title": "EFCAB14",
      "color": "#5FD6E7",
      "x": 1300.0,
      "y": 280.0
    },
    {
      "id": 122,
      "label": "CRACR2B",
      "group": 1,
      "value": 20,
      "title": "CRACR2B",
      "color": "#5FD6E7",
      "x": 1160.0000000000002,
      "y": 242.48711305964295
    },
    {
      "id": 123,
      "label": "CRACR2A",
      "group": 1,
      "value": 20,
      "title": "CRACR2A",
      "color": "#5FD6E7",
      "x": 1057.512886940357,
      "y": 139.99999999999994
    },
    {
      "id": 124,
      "label": "CLXN",
      "group": 1,
      "value": 20,
      "title": "CLXN",
      "color": "#5FD6E7",
      "x": 1020.0,
      "y": 1.0287033112837766e-13
    },
    {
      "id": 125,
      "label": "HEG1",
      "group": 1,
      "value": 20,
      "title": "HEG1",
      "color": "#5FD6E7",
      "x": 1057.512886940357,
      "y": -139.99999999999977
    },
    {
      "id": 126,
      "label": "PCDHB4",
      "group": 1,
      "value": 20,
      "title": "PCDHB4",
      "color": "#5FD6E7",
      "x": 1160.0,
      "y": -242.48711305964284
    },
    {
      "id": 127,
      "label": "PCDHA10",
      "group": 1,
      "value": 20,
      "title": "PCDHA10",
      "color": "#5FD6E7",
      "x": 1299.9999999999998,
      "y": -280.0
    },
    {
      "id": 128,
      "label": "DSC1",
      "group": 1,
      "value": 20,
      "title": "DSC1",
      "color": "#5FD6E7",
      "x": 1439.9999999999998,
      "y": -242.48711305964295
    },
    {
      "id": 129,
      "label": "EFCAB10",
      "group": 1,
      "value": 20,
      "title": "EFCAB10",
      "color": "#5FD6E7",
      "x": 1542.4871130596428,
      "y": -139.99999999999997
    },
    {
      "id": 130,
      "label": "EFHC2",
      "group": 1,
      "value": 20,
      "title": "EFHC2",
      "color": "#5FD6E7",
      "x": 1580.0,
      "y": -1.3716044150450357e-13
    },
    {
      "id": 131,
      "label": "HMCN2",
      "group": 1,
      "value": 20,
      "title": "HMCN2",
      "color": "#5FD6E7",
      "x": 1542.487113059643,
      "y": 139.99999999999974
    },
    {
      "id": 132,
      "label": "PRRG2",
      "group": 1,
      "value": 20,
      "title": "PRRG2",
      "color": "#5FD6E7",
      "x": 1299.9999999999998,
      "y": -380.0
    },
    {
      "id": 136,
      "label": "PCDHGB3",
      "group": 1,
      "value": 20,
      "title": "PCDHGB3",
      "color": "#5FD6E7",
      "x": 1445.4197042987341,
      "y": -351.07422235428896
    },
    {
      "id": 137,
      "label": "SPATA21",
      "group": 1,
      "value": 20,
      "title": "SPATA21",
      "color": "#5FD6E7",
      "x": 1568.7005768508882,
      "y": -268.70057685088796
    },
    {
      "id": 138,
      "label": "PCDHA12",
      "group": 1,
      "value": 20,
      "title": "PCDHA12",
      "color": "#5FD6E7",
      "x": 1651.0742223542888,
      "y": -145.41970429873444
    },
    {
      "id": 139,
      "label": "EFCAB12",
      "group": 1,
      "value": 20,
      "title": "EFCAB12",
      "color": "#5FD6E7",
      "x": 1680.0,
      "y": -1.8614631347039768e-13
    },
    {
      "id": 142,
      "label": "B2M",
      "group": 1,
      "value": 20,
      "title": "B2M",
      "color": "#5FD6E7",
      "x": 1651.0742223542888,
      "y": 145.4197042987341
    },
    {
      "id": 143,
      "label": "KITLG",
      "group": 1,
      "value": 20,
      "title": "KITLG",
      "color": "#5FD6E7",
      "x": 1568.700576850888,
      "y": 268.7005768508882
    },
    {
      "id": 144,
      "label": "CXCL12",
      "group": 1,
      "value": 20,
      "title": "CXCL12",
      "color": "#5FD6E7",
      "x": 1445.4197042987344,
      "y": 351.07422235428885
    },
    {
      "id": 145,
      "label": "NANOS2",
      "group": 1,
      "value": 20,
      "title": "NANOS2",
      "color": "#5FD6E7",
      "x": 1300.0000000000002,
      "y": 380.0
    },
    {
      "id": 4,
      "label": "FGFR3",
      "group": 1,
      "value": 20,
      "title": "FGFR3",
      "color": "#EDC962",
      "x": 481.7220926874317,
      "y": 1236.3734711836996
    },
    {
      "id": 8,
      "label": "CDH17",
      "group": 1,
      "value": 20,
      "title": "CDH17",
      "color": "#EDC962",
      "x": 401.7220926874317,
      "y": 1316.3734711836996
    },
    {
      "id": 13,
      "label": "TNF",
      "group": 1,
      "value": 20,
      "title": "TNF",
      "color": "#EDC962",
      "x": 321.7220926874317,
      "y": 1236.3734711836996
    },
    {
      "id": 14,
      "label": "S100A4",
      "group": 1,
      "value": 20,
      "title": "S100A4",
      "color": "#EDC962",
      "x": 401.7220926874317,
      "y": 1156.3734711836996
    },
    {
      "id": 15,
      "label": "MET",
      "group": 1,
      "value": 20,
      "title": "MET",
      "color": "#EDC962",
      "x": 245.83752000623275,
      "y": 1146.3734711836996
    },
    {
      "id": 20,
      "label": "MTOR",
      "group": 1,
      "value": 20,
      "title": "MTOR",
      "color": "#EDC962",
      "x": 355.134664568978,
      "y": 1062.5068224516674
    },
    {
      "id": 22,
      "label": "MMP9",
      "group": 1,
      "value": 20,
      "title": "MMP9",
      "color": "#EDC962",
      "x": 491.7220926874317,
      "y": 1080.4888985025007
    },
    {
      "id": 23,
      "label": "KDR",
      "group": 1,
      "value": 20,
      "title": "KDR",
      "color": "#EDC962",
      "x": 575.588741419464,
      "y": 1189.7860430652458
    },
    {
      "id": 24,
      "label": "BMP2",
      "group": 1,
      "value": 20,
      "title": "BMP2",
      "color": "#EDC962",
      "x": 557.6066653686307,
      "y": 1326.3734711836996
    },
    {
      "id": 28,
      "label": "NTRK1",
      "group": 1,
      "value": 20,
      "title": "NTRK1",
      "color": "#EDC962",
      "x": 448.3095208058854,
      "y": 1410.2401199157318
    },
    {
      "id": 29,
      "label": "MYC",
      "group": 1,
      "value": 20,
      "title": "MYC",
      "color": "#EDC962",
      "x": 311.7220926874319,
      "y": 1392.2580438648986
    },
    {
      "id": 30,
      "label": "ANXA5",
      "group": 1,
      "value": 20,
      "title": "ANXA5",
      "color": "#EDC962",
      "x": 227.8554439553994,
      "y": 1282.9608993021534
    },
    {
      "id": 31,
      "label": "CDH1",
      "group": 1,
      "value": 20,
      "title": "CDH1",
      "color": "#EDC962",
      "x": 541.7220926874317,
      "y": 1478.8605842433426
    },
    {
      "id": 33,
      "label": "CCND2",
      "group": 1,
      "value": 20,
      "title": "CCND2",
      "color": "#EDC962",
      "x": 401.7220926874318,
      "y": 1516.3734711836996
    },
    {
      "id": 34,
      "label": "PDGFRB",
      "group": 1,
      "value": 20,
      "title": "PDGFRB",
      "color": "#EDC962",
      "x": 261.72209268743194,
      "y": 1478.8605842433426
    },
    {
      "id": 35,
      "label": "TGFB2",
      "group": 1,
      "value": 20,
      "title": "TGFB2",
      "color": "#EDC962",
      "x": 159.23497962778885,
      "y": 1376.3734711836996
    },
    {
      "id": 36,
      "label": "SCD",
      "group": 1,
      "value": 20,
      "title": "SCD",
      "color": "#EDC962",
      "x": 121.72209268743171,
      "y": 1236.3734711836996
    },
    {
      "id": 37,
      "label": "TIMP1",
      "group": 1,
      "value": 20,
      "title": "TIMP1",
      "color": "#EDC962",
      "x": 159.23497962778876,
      "y": 1096.3734711836998
    },
    {
      "id": 38,
      "label": "PCNA",
      "group": 1,
      "value": 20,
      "title": "PCNA",
      "color": "#EDC962",
      "x": 261.72209268743177,
      "y": 993.8863581240568
    },
    {
      "id": 39,
      "label": "KRAS",
      "group": 1,
      "value": 20,
      "title": "KRAS",
      "color": "#EDC962",
      "x": 401.7220926874316,
      "y": 956.3734711836996
    },
    {
      "id": 40,
      "label": "HRAS",
      "group": 1,
      "value": 20,
      "title": "HRAS",
      "color": "#EDC962",
      "x": 541.7220926874314,
      "y": 993.8863581240566
    },
    {
      "id": 55,
      "label": "SLC2A1",
      "group": 1,
      "value": 20,
      "title": "SLC2A1",
      "color": "#EDC962",
      "x": 644.2092057470745,
      "y": 1096.3734711836996
    },
    {
      "id": 58,
      "label": "FN1",
      "group": 1,
      "value": 20,
      "title": "FN1",
      "color": "#EDC962",
      "x": 681.7220926874318,
      "y": 1236.3734711836994
    },
    {
      "id": 60,
      "label": "CASP3",
      "group": 1,
      "value": 20,
      "title": "CASP3",
      "color": "#EDC962",
      "x": 644.2092057470746,
      "y": 1376.3734711836994
    },
    {
      "id": 61,
      "label": "CCN2",
      "group": 1,
      "value": 20,
      "title": "CCN2",
      "color": "#EDC962",
      "x": 401.72209268743154,
      "y": 856.3734711836996
    },
    {
      "id": 62,
      "label": "FLNB",
      "group": 1,
      "value": 20,
      "title": "FLNB",
      "color": "#EDC962",
      "x": 547.1417969861658,
      "y": 885.2992488294107
    },
    {
      "id": 64,
      "label": "TERT",
      "group": 1,
      "value": 20,
      "title": "TERT",
      "color": "#EDC962",
      "x": 670.42266953832,
      "y": 967.6728943328117
    },
    {
      "id": 65,
      "label": "F3",
      "group": 1,
      "value": 20,
      "title": "F3",
      "color": "#EDC962",
      "x": 752.7963150417206,
      "y": 1090.9537668849653
    },
    {
      "id": 66,
      "label": "MAPK3",
      "group": 1,
      "value": 20,
      "title": "MAPK3",
      "color": "#EDC962",
      "x": 781.7220926874318,
      "y": 1236.3734711836994
    },
    {
      "id": 67,
      "label": "SPARC",
      "group": 1,
      "value": 20,
      "title": "SPARC",
      "color": "#EDC962",
      "x": 752.7963150417206,
      "y": 1381.7931754824338
    },
    {
      "id": 72,
      "label": "CALM1",
      "group": 1,
      "value": 20,
      "title": "CALM1",
      "color": "#EDC962",
      "x": 670.4226695383197,
      "y": 1505.0740480345878
    },
    {
      "id": 87,
      "label": "TLR2",
      "group": 1,
      "value": 20,
      "title": "TLR2",
      "color": "#EDC962",
      "x": 547.1417969861661,
      "y": 1587.4476935379885
    },
    {
      "id": 91,
      "label": "SDC1",
      "group": 1,
      "value": 20,
      "title": "SDC1",
      "color": "#EDC962",
      "x": 401.72209268743194,
      "y": 1616.3734711836996
    },
    {
      "id": 92,
      "label": "ESR1",
      "group": 1,
      "value": 20,
      "title": "ESR1",
      "color": "#EDC962",
      "x": 256.30238838869764,
      "y": 1587.4476935379887
    },
    {
      "id": 93,
      "label": "IGF1R",
      "group": 1,
      "value": 20,
      "title": "IGF1R",
      "color": "#EDC962",
      "x": 133.02151583654353,
      "y": 1505.0740480345876
    },
    {
      "id": 98,
      "label": "CDK4",
      "group": 1,
      "value": 20,
      "title": "CDK4",
      "color": "#EDC962",
      "x": 50.64787033314286,
      "y": 1381.793175482434
    },
    {
      "id": 148,
      "label": "PAMR1",
      "group": 1,
      "value": 20,
      "title": "PAMR1",
      "color": "#CBA5E9",
      "x": -971.7220926874315,
      "y": 764.1208279802153
    },
    {
      "id": 149,
      "label": "CHP2",
      "group": 1,
      "value": 20,
      "title": "CHP2",
      "color": "#CBA5E9",
      "x": -1051.7220926874315,
      "y": 844.1208279802153
    },
    {
      "id": 150,
      "label": "PCDHB15",
      "group": 1,
      "value": 20,
      "title": "PCDHB15",
      "color": "#CBA5E9",
      "x": -1131.7220926874315,
      "y": 764.1208279802153
    },
    {
      "id": 151,
      "label": "DSG4",
      "group": 1,
      "value": 20,
      "title": "DSG4",
      "color": "#CBA5E9",
      "x": -1051.7220926874315,
      "y": 684.1208279802153
    },
    {
      "id": 152,
      "label": "OCM2",
      "group": 1,
      "value": 20,
      "title": "OCM2",
      "color": "#CBA5E9",
      "x": -1207.6066653686305,
      "y": 674.1208279802153
    },
    {
      "id": 153,
      "label": "PCDHB10",
      "group": 1,
      "value": 20,
      "title": "PCDHB10",
      "color": "#CBA5E9",
      "x": -1098.3095208058853,
      "y": 590.2541792481829
    },
    {
      "id": 154,
      "label": "EFCAB2",
      "group": 1,
      "value": 20,
      "title": "EFCAB2",
      "color": "#CBA5E9",
      "x": -961.7220926874315,
      "y": 608.2362552990163
    },
    {
      "id": 155,
      "label": "NECAB3",
      "group": 1,
      "value": 20,
      "title": "NECAB3",
      "color": "#CBA5E9",
      "x": -877.8554439553992,
      "y": 717.5333998617615
    },
    {
      "id": 156,
      "label": "ANKEF1",
      "group": 1,
      "value": 20,
      "title": "ANKEF1",
      "color": "#CBA5E9",
      "x": -895.8375200062326,
      "y": 854.1208279802153
    },
    {
      "id": 157,
      "label": "PCDHGB4",
      "group": 1,
      "value": 20,
      "title": "PCDHGB4",
      "color": "#CBA5E9",
      "x": -1005.1346645689778,
      "y": 937.9874767122476
    },
    {
      "id": 158,
      "label": "DNER",
      "group": 1,
      "value": 20,
      "title": "DNER",
      "color": "#CBA5E9",
      "x": -1141.7220926874313,
      "y": 920.0054006614143
    },
    {
      "id": 159,
      "label": "PCDHA1",
      "group": 1,
      "value": 20,
      "title": "PCDHA1",
      "color": "#CBA5E9",
      "x": -1225.5887414194638,
      "y": 810.708256098669
    },
    {
      "id": 160,
      "label": "MYL7",
      "group": 1,
      "value": 20,
      "title": "MYL7",
      "color": "#CBA5E9",
      "x": -911.7220926874317,
      "y": 1006.6079410398581
    },
    {
      "id": 161,
      "label": "EFCAB5",
      "group": 1,
      "value": 20,
      "title": "EFCAB5",
      "color": "#CBA5E9",
      "x": -1051.7220926874315,
      "y": 1044.1208279802154
    },
    {
      "id": 162,
      "label": "CABCOCO1",
      "group": 1,
      "value": 20,
      "title": "CABCOCO1",
      "color": "#CBA5E9",
      "x": -1191.7220926874313,
      "y": 1006.6079410398582
    },
    {
      "id": 163,
      "label": "PCDHGA2",
      "group": 1,
      "value": 20,
      "title": "PCDHGA2",
      "color": "#CBA5E9",
      "x": -1294.2092057470745,
      "y": 904.1208279802152
    },
    {
      "id": 164,
      "label": "OIT3",
      "group": 1,
      "value": 20,
      "title": "OIT3",
      "color": "#CBA5E9",
      "x": -1331.7220926874315,
      "y": 764.1208279802154
    },
    {
      "id": 165,
      "label": "PCDHB3",
      "group": 1,
      "value": 20,
      "title": "PCDHB3",
      "color": "#CBA5E9",
      "x": -1294.2092057470745,
      "y": 624.1208279802155
    },
    {
      "id": 166,
      "label": "PRRG3",
      "group": 1,
      "value": 20,
      "title": "PRRG3",
      "color": "#CBA5E9",
      "x": -1191.7220926874315,
      "y": 521.6337149205724
    },
    {
      "id": 167,
      "label": "PCDHB11",
      "group": 1,
      "value": 20,
      "title": "PCDHB11",
      "color": "#CBA5E9",
      "x": -1051.7220926874318,
      "y": 484.12082798021527
    },
    {
      "id": 168,
      "label": "CLSTN3",
      "group": 1,
      "value": 20,
      "title": "CLSTN3",
      "color": "#CBA5E9",
      "x": -911.7220926874318,
      "y": 521.6337149205723
    },
    {
      "id": 169,
      "label": "EFCAB6",
      "group": 1,
      "value": 20,
      "title": "EFCAB6",
      "color": "#CBA5E9",
      "x": -809.2349796277887,
      "y": 624.1208279802153
    },
    {
      "id": 170,
      "label": "FAT2",
      "group": 1,
      "value": 20,
      "title": "FAT2",
      "color": "#CBA5E9",
      "x": -771.7220926874315,
      "y": 764.1208279802152
    },
    {
      "id": 171,
      "label": "CALU",
      "group": 1,
      "value": 20,
      "title": "CALU",
      "color": "#CBA5E9",
      "x": -809.2349796277886,
      "y": 904.120827980215
    },
    {
      "id": 172,
      "label": "PCDHB2",
      "group": 1,
      "value": 20,
      "title": "PCDHB2",
      "color": "#CBA5E9",
      "x": -1051.7220926874318,
      "y": 384.12082798021527
    },
    {
      "id": 173,
      "label": "SUSD1",
      "group": 1,
      "value": 20,
      "title": "SUSD1",
      "color": "#CBA5E9",
      "x": -906.3023883886974,
      "y": 413.0466056259263
    },
    {
      "id": 174,
      "label": "HPCAL1",
      "group": 1,
      "value": 20,
      "title": "HPCAL1",
      "color": "#CBA5E9",
      "x": -783.0215158365434,
      "y": 495.4202511293273
    },
    {
      "id": 175,
      "label": "RCN2",
      "group": 1,
      "value": 20,
      "title": "RCN2",
      "color": "#CBA5E9",
      "x": -700.6478703331427,
      "y": 618.7011236814808
    },
    {
      "id": 176,
      "label": "MYL12A",
      "group": 1,
      "value": 20,
      "title": "MYL12A",
      "color": "#CBA5E9",
      "x": -671.7220926874315,
      "y": 764.120827980215
    },
    {
      "id": 177,
      "label": "PRRG1",
      "group": 1,
      "value": 20,
      "title": "PRRG1",
      "color": "#CBA5E9",
      "x": -700.6478703331426,
      "y": 909.5405322789494
    },
    {
      "id": 178,
      "label": "EYS",
      "group": 1,
      "value": 20,
      "title": "EYS",
      "color": "#CBA5E9",
      "x": -783.0215158365436,
      "y": 1032.8214048311033
    },
    {
      "id": 179,
      "label": "PCDHGA3",
      "group": 1,
      "value": 20,
      "title": "PCDHGA3",
      "color": "#CBA5E9",
      "x": -906.3023883886971,
      "y": 1115.1950503345042
    },
    {
      "id": 180,
      "label": "VWCE",
      "group": 1,
      "value": 20,
      "title": "VWCE",
      "color": "#CBA5E9",
      "x": -1051.7220926874313,
      "y": 1144.1208279802154
    },
    {
      "id": 181,
      "label": "MEGF8",
      "group": 1,
      "value": 20,
      "title": "MEGF8",
      "color": "#CBA5E9",
      "x": -1197.1417969861657,
      "y": 1115.1950503345042
    },
    {
      "id": 182,
      "label": "PCDHGA5",
      "group": 1,
      "value": 20,
      "title": "PCDHGA5",
      "color": "#CBA5E9",
      "x": -1320.4226695383197,
      "y": 1032.8214048311033
    },
    {
      "id": 183,
      "label": "CGREF1",
      "group": 1,
      "value": 20,
      "title": "CGREF1",
      "color": "#CBA5E9",
      "x": -1402.7963150417204,
      "y": 909.5405322789497
    },
    {
      "id": 184,
      "label": "CAPS2",
      "group": 1,
      "value": 20,
      "title": "CAPS2",
      "color": "#CBA5E9",
      "x": -1431.7220926874315,
      "y": 764.1208279802155
    },
    {
      "id": 185,
      "label": "DCHS2",
      "group": 1,
      "value": 20,
      "title": "DCHS2",
      "color": "#CBA5E9",
      "x": -1402.7963150417208,
      "y": 618.7011236814818
    },
    {
      "id": 186,
      "label": "CDHR1",
      "group": 1,
      "value": 20,
      "title": "CDHR1",
      "color": "#CBA5E9",
      "x": -1320.4226695383195,
      "y": 495.42025112932714
    },
    {
      "id": 187,
      "label": "PCDH19",
      "group": 1,
      "value": 20,
      "title": "PCDH19",
      "color": "#CBA5E9",
      "x": -1197.1417969861661,
      "y": 413.0466056259265
    },
    {
      "id": 188,
      "label": "PCDHGC3",
      "group": 1,
      "value": 20,
      "title": "PCDHGC3",
      "color": "#CBA5E9",
      "x": -1291.7220926874318,
      "y": 1179.8130217967457
    },
    {
      "id": 189,
      "label": "MCFD2",
      "group": 1,
      "value": 20,
      "title": "MCFD2",
      "color": "#CBA5E9",
      "x": -1408.431608916581,
      "y": 1085.303519032467
    },
    {
      "id": 190,
      "label": "PCDHGC5",
      "group": 1,
      "value": 20,
      "title": "PCDHGC5",
      "color": "#CBA5E9",
      "x": -1490.2239123558802,
      "y": 959.3544166565991
    },
    {
      "id": 191,
      "label": "RCN3",
      "group": 1,
      "value": 20,
      "title": "RCN3",
      "color": "#CBA5E9",
      "x": -1529.0926024642029,
      "y": 814.2944903486887
    },
    {
      "id": 192,
      "label": "PCDHGA4",
      "group": 1,
      "value": 20,
      "title": "PCDHGA4",
      "color": "#CBA5E9",
      "x": -1521.2329410396583,
      "y": 664.3232163876905
    },
    {
      "id": 193,
      "label": "PCDHB9",
      "group": 1,
      "value": 20,
      "title": "PCDHB9",
      "color": "#CBA5E9",
      "x": -1467.4142865039623,
      "y": 524.1208279802158
    },
    {
      "id": 194,
      "label": "PCDHGA12",
      "group": 1,
      "value": 20,
      "title": "PCDHGA12",
      "color": "#CBA5E9",
      "x": -1372.9047837396834,
      "y": 407.41131175106585
    },
    {
      "id": 195,
      "label": "EFCAB7",
      "group": 1,
      "value": 20,
      "title": "EFCAB7",
      "color": "#CBA5E9",
      "x": -1246.9556813638162,
      "y": 325.61900831176706
    },
    {
      "id": 196,
      "label": "PCDHGA1",
      "group": 1,
      "value": 20,
      "title": "PCDHGA1",
      "color": "#CBA5E9",
      "x": -1101.895755055905,
      "y": 286.750318203444
    },
    {
      "id": 197,
      "label": "PCDHB1",
      "group": 1,
      "value": 20,
      "title": "PCDHB1",
      "color": "#CBA5E9",
      "x": -951.9244810949076,
      "y": 294.6099796279884
    },
    {
      "id": 198,
      "label": "TCIM",
      "group": 1,
      "value": 20,
      "title": "TCIM",
      "color": "#CBA5E9",
      "x": -811.7220926874313,
      "y": 348.4286341636848
    },
    {
      "id": 199,
      "label": "PCDHB7",
      "group": 1,
      "value": 20,
      "title": "PCDHB7",
      "color": "#CBA5E9",
      "x": -695.0125764582828,
      "y": 442.9381369279629
    },
    {
      "id": 200,
      "label": "PCDHGA11",
      "group": 1,
      "value": 20,
      "title": "PCDHGA11",
      "color": "#CBA5E9",
      "x": -613.220273018983,
      "y": 568.8872393038314
    },
    {
      "id": 201,
      "label": "CABP5",
      "group": 1,
      "value": 20,
      "title": "CABP5",
      "color": "#CBA5E9",
      "x": -574.3515829106605,
      "y": 713.947165611741
    },
    {
      "id": 202,
      "label": "PCDH12",
      "group": 1,
      "value": 20,
      "title": "PCDH12",
      "color": "#CBA5E9",
      "x": -582.2112443352048,
      "y": 863.91843957274
    },
    {
      "id": 203,
      "label": "PCDHB13",
      "group": 1,
      "value": 20,
      "title": "PCDHB13",
      "color": "#CBA5E9",
      "x": -636.0298988709006,
      "y": 1004.1208279802147
    },
    {
      "id": 204,
      "label": "PCDH11Y",
      "group": 1,
      "value": 20,
      "title": "PCDH11Y",
      "color": "#CBA5E9",
      "x": -730.5394016351797,
      "y": 1120.8303442093647
    },
    {
      "id": 205,
      "label": "PCDHA2",
      "group": 1,
      "value": 20,
      "title": "PCDHA2",
      "color": "#CBA5E9",
      "x": -856.4885040110469,
      "y": 1202.6226476486634
    },
    {
      "id": 206,
      "label": "PCDHAC1",
      "group": 1,
      "value": 20,
      "title": "PCDHAC1",
      "color": "#CBA5E9",
      "x": -1001.548430318958,
      "y": 1241.4913377569865
    },
    {
      "id": 207,
      "label": "PCDHGB6",
      "group": 1,
      "value": 20,
      "title": "PCDHGB6",
      "color": "#CBA5E9",
      "x": -1151.5197042799555,
      "y": 1233.631676332442
    },
    {
      "id": 208,
      "label": "PCDHGB2",
      "group": 1,
      "value": 20,
      "title": "PCDHGB2",
      "color": "#CBA5E9",
      "x": -549.4273584924572,
      "y": 474.1208279802152
    },
    {
      "id": 209,
      "label": "PCDHB5",
      "group": 1,
      "value": 20,
      "title": "PCDHB5",
      "color": "#CBA5E9",
      "x": -491.4851134397717,
      "y": 614.005781820754
    },
    {
      "id": 210,
      "label": "PCDHGA8",
      "group": 1,
      "value": 20,
      "title": "PCDHGA8",
      "color": "#CBA5E9",
      "x": -471.72209268743154,
      "y": 764.1208279802148
    },
    {
      "id": 211,
      "label": "CABP7",
      "group": 1,
      "value": 20,
      "title": "CABP7",
      "color": "#CBA5E9",
      "x": -491.485113439772,
      "y": 914.2358741396777
    },
    {
      "id": 212,
      "label": "PCDHGB7",
      "group": 1,
      "value": 20,
      "title": "PCDHGB7",
      "color": "#CBA5E9",
      "x": -549.4273584924567,
      "y": 1054.1208279802145
    },
    {
      "id": 213,
      "label": "CDH16",
      "group": 1,
      "value": 20,
      "title": "CDH16",
      "color": "#CBA5E9",
      "x": -641.6001595992341,
      "y": 1174.2427610684128
    },
    {
      "id": 214,
      "label": "PCDHB14",
      "group": 1,
      "value": 20,
      "title": "PCDHB14",
      "color": "#CBA5E9",
      "x": -761.7220926874306,
      "y": 1266.4155621751893
    },
    {
      "id": 215,
      "label": "CDH22",
      "group": 1,
      "value": 20,
      "title": "CDH22",
      "color": "#CBA5E9",
      "x": -901.6070465279693,
      "y": 1324.3578072278747
    },
    {
      "id": 216,
      "label": "MICU3",
      "group": 1,
      "value": 20,
      "title": "MICU3",
      "color": "#CBA5E9",
      "x": -1051.7220926874322,
      "y": 1344.1208279802154
    },
    {
      "id": 217,
      "label": "MATN4",
      "group": 1,
      "value": 20,
      "title": "MATN4",
      "color": "#CBA5E9",
      "x": -1201.837138846893,
      "y": 1324.3578072278751
    },
    {
      "id": 218,
      "label": "PCDH1",
      "group": 1,
      "value": 20,
      "title": "PCDH1",
      "color": "#CBA5E9",
      "x": -1341.7220926874318,
      "y": 1266.4155621751895
    },
    {
      "id": 219,
      "label": "MYL10",
      "group": 1,
      "value": 20,
      "title": "MYL10",
      "color": "#CBA5E9",
      "x": -1461.8440257756283,
      "y": 1174.2427610684135
    },
    {
      "id": 220,
      "label": "PCDHA3",
      "group": 1,
      "value": 20,
      "title": "PCDHA3",
      "color": "#CBA5E9",
      "x": -1554.016826882406,
      "y": 1054.1208279802154
    },
    {
      "id": 221,
      "label": "PPP3R2",
      "group": 1,
      "value": 20,
      "title": "PPP3R2",
      "color": "#CBA5E9",
      "x": -1611.9590719350913,
      "y": 914.2358741396766
    },
    {
      "id": 222,
      "label": "MEGF6",
      "group": 1,
      "value": 20,
      "title": "MEGF6",
      "color": "#CBA5E9",
      "x": -1631.7220926874315,
      "y": 764.1208279802157
    },
    {
      "id": 223,
      "label": "PCDHA13",
      "group": 1,
      "value": 20,
      "title": "PCDHA13",
      "color": "#CBA5E9",
      "x": -1611.959071935091,
      "y": 614.0057818207529
    },
    {
      "id": 224,
      "label": "PCDHGA10",
      "group": 1,
      "value": 20,
      "title": "PCDHGA10",
      "color": "#CBA5E9",
      "x": -1554.0168268824063,
      "y": 474.120827980216
    },
    {
      "id": 225,
      "label": "PCDHA8",
      "group": 1,
      "value": 20,
      "title": "PCDHA8",
      "color": "#CBA5E9",
      "x": -1461.8440257756292,
      "y": 353.9988948920177
    },
    {
      "id": 226,
      "label": "PCDHGC4",
      "group": 1,
      "value": 20,
      "title": "PCDHGC4",
      "color": "#CBA5E9",
      "x": -1341.7220926874325,
      "y": 261.8260937852415
    },
    {
      "id": 227,
      "label": "PCDHA9",
      "group": 1,
      "value": 20,
      "title": "PCDHA9",
      "color": "#CBA5E9",
      "x": -1201.837138846894,
      "y": 203.88384873255575
    },
    {
      "id": 228,
      "label": "CAPS",
      "group": 1,
      "value": 20,
      "title": "CAPS",
      "color": "#CBA5E9",
      "x": -1051.722092687431,
      "y": 184.12082798021527
    },
    {
      "id": 229,
      "label": "MCTP2",
      "group": 1,
      "value": 20,
      "title": "MCTP2",
      "color": "#CBA5E9",
      "x": -901.6070465279702,
      "y": 203.8838487325554
    },
    {
      "id": 230,
      "label": "CALN1",
      "group": 1,
      "value": 20,
      "title": "CALN1",
      "color": "#CBA5E9",
      "x": -761.7220926874314,
      "y": 261.82609378524097
    },
    {
      "id": 231,
      "label": "CDH20",
      "group": 1,
      "value": 20,
      "title": "CDH20",
      "color": "#CBA5E9",
      "x": -641.6001595992348,
      "y": 353.99889489201695
    },
    {
      "id": 232,
      "label": "PCDH7",
      "group": 1,
      "value": 20,
      "title": "PCDH7",
      "color": "#CBA5E9",
      "x": -1731.7220926874315,
      "y": 764.1208279802158
    },
    {
      "id": 233,
      "label": "PCDH9",
      "group": 1,
      "value": 20,
      "title": "PCDH9",
      "color": "#CBA5E9",
      "x": -1714.6730729710714,
      "y": 612.8065928899209
    },
    {
      "id": 234,
      "label": "PCDH18",
      "group": 1,
      "value": 20,
      "title": "PCDH18",
      "color": "#CBA5E9",
      "x": -1664.3809228610767,
      "y": 469.07988538027627
    },
    {
      "id": 235,
      "label": "CRB1",
      "group": 1,
      "value": 20,
      "title": "CRB1",
      "color": "#CBA5E9",
      "x": -1583.3675007656914,
      "y": 340.14776271627596
    },
    {
      "id": 236,
      "label": "PCDH17",
      "group": 1,
      "value": 20,
      "title": "PCDH17",
      "color": "#CBA5E9",
      "x": -1475.6951579513689,
      "y": 232.47541990195384
    },
    {
      "id": 237,
      "label": "YY1",
      "group": 1,
      "value": 20,
      "title": "YY1",
      "color": "#85D38D",
      "x": -971.7220926874318,
      "y": -764.1208279802149
    },
    {
      "id": 238,
      "label": "NOTCH2",
      "group": 1,
      "value": 20,
      "title": "NOTCH2",
      "color": "#85D38D",
      "x": -1051.7220926874318,
      "y": -684.1208279802149
    },
    {
      "id": 239,
      "label": "LFNG",
      "group": 1,
      "value": 20,
      "title": "LFNG",
      "color": "#85D38D",
      "x": -1131.7220926874318,
      "y": -764.1208279802149
    },
    {
      "id": 240,
      "label": "ASCL1",
      "group": 1,
      "value": 20,
      "title": "ASCL1",
      "color": "#85D38D",
      "x": -1051.7220926874318,
      "y": -844.1208279802149
    },
    {
      "id": 241,
      "label": "NOTCH3",
      "group": 1,
      "value": 20,
      "title": "NOTCH3",
      "color": "#85D38D",
      "x": -1207.6066653686307,
      "y": -854.1208279802149
    },
    {
      "id": 242,
      "label": "MESP2",
      "group": 1,
      "value": 20,
      "title": "MESP2",
      "color": "#85D38D",
      "x": -1098.3095208058855,
      "y": -937.9874767122473
    },
    {
      "id": 243,
      "label": "HES1",
      "group": 1,
      "value": 20,
      "title": "HES1",
      "color": "#85D38D",
      "x": -961.7220926874318,
      "y": -920.0054006614139
    },
    {
      "id": 244,
      "label": "GATD3",
      "group": 1,
      "value": 20,
      "title": "GATD3",
      "color": "#85D38D",
      "x": -877.8554439553994,
      "y": -810.7082560986687
    },
    {
      "id": 245,
      "label": "GATD3B",
      "group": 1,
      "value": 20,
      "title": "GATD3B",
      "color": "#85D38D",
      "x": -895.8375200062328,
      "y": -674.1208279802149
    },
    {
      "id": 246,
      "label": "HES7",
      "group": 1,
      "value": 20,
      "title": "HES7",
      "color": "#85D38D",
      "x": -1005.134664568978,
      "y": -590.2541792481826
    },
    {
      "id": 247,
      "label": "NEUROD1",
      "group": 1,
      "value": 20,
      "title": "NEUROD1",
      "color": "#85D38D",
      "x": -1141.7220926874315,
      "y": -608.2362552990159
    },
    {
      "id": 248,
      "label": "TACSTD2",
      "group": 1,
      "value": 20,
      "title": "TACSTD2",
      "color": "#85D38D",
      "x": -1225.588741419464,
      "y": -717.5333998617612
    },
    {
      "id": 249,
      "label": "NCAM1",
      "group": 1,
      "value": 20,
      "title": "NCAM1",
      "color": "#85D38D",
      "x": -911.7220926874319,
      "y": -521.6337149205721
    },
    {
      "id": 250,
      "label": "HES5",
      "group": 1,
      "value": 20,
      "title": "HES5",
      "color": "#85D38D",
      "x": -1051.7220926874318,
      "y": -484.1208279802149
    },
    {
      "id": 251,
      "label": "TBX6",
      "group": 1,
      "value": 20,
      "title": "TBX6",
      "color": "#85D38D",
      "x": -1191.7220926874315,
      "y": -521.633714920572
    },
    {
      "id": 252,
      "label": "YAP1",
      "group": 1,
      "value": 20,
      "title": "YAP1",
      "color": "#85D38D",
      "x": -1294.2092057470745,
      "y": -624.1208279802149
    },
    {
      "id": 253,
      "label": "POU2F3",
      "group": 1,
      "value": 20,
      "title": "POU2F3",
      "color": "#85D38D",
      "x": -1331.7220926874318,
      "y": -764.1208279802148
    },
    {
      "id": 254,
      "label": "FOLH1",
      "group": 1,
      "value": 20,
      "title": "FOLH1",
      "color": "#85D38D",
      "x": -1294.2092057470747,
      "y": -904.1208279802147
    },
    {
      "id": 255,
      "label": "NKX2-1",
      "group": 1,
      "value": 20,
      "title": "NKX2-1",
      "color": "#85D38D",
      "x": -1191.7220926874318,
      "y": -1006.6079410398578
    },
    {
      "id": 256,
      "label": "CEACAM5",
      "group": 1,
      "value": 20,
      "title": "CEACAM5",
      "color": "#85D38D",
      "x": -1051.722092687432,
      "y": -1044.120827980215
    },
    {
      "id": 257,
      "label": "HEY2",
      "group": 1,
      "value": 20,
      "title": "HEY2",
      "color": "#85D38D",
      "x": -911.722092687432,
      "y": -1006.6079410398579
    },
    {
      "id": 258,
      "label": "RIPPLY2",
      "group": 1,
      "value": 20,
      "title": "RIPPLY2",
      "color": "#85D38D",
      "x": -809.2349796277889,
      "y": -904.1208279802149
    },
    {
      "id": 259,
      "label": "HEY1",
      "group": 1,
      "value": 20,
      "title": "HEY1",
      "color": "#85D38D",
      "x": -771.7220926874318,
      "y": -764.120827980215
    },
    {
      "id": 260,
      "label": "TTF1",
      "group": 1,
      "value": 20,
      "title": "TTF1",
      "color": "#85D38D",
      "x": -809.2349796277888,
      "y": -624.1208279802152
    },
    {
      "id": 261,
      "label": "TP53",
      "group": 1,
      "value": 20,
      "title": "TP53",
      "color": "#85D38D",
      "x": -1051.722092687432,
      "y": -1144.120827980215
    },
    {
      "id": 262,
      "label": "SUZ12",
      "group": 1,
      "value": 20,
      "title": "SUZ12",
      "color": "#85D38D",
      "x": -906.3023883886976,
      "y": -1115.1950503345038
    },
    {
      "id": 263,
      "label": "EZH2",
      "group": 1,
      "value": 20,
      "title": "EZH2",
      "color": "#85D38D",
      "x": -783.0215158365436,
      "y": -1032.8214048311029
    },
    {
      "id": 264,
      "label": "STEAP1",
      "group": 1,
      "value": 20,
      "title": "STEAP1",
      "color": "#85D38D",
      "x": -700.6478703331429,
      "y": -909.5405322789494
    },
    {
      "id": 265,
      "label": "SRRT",
      "group": 1,
      "value": 20,
      "title": "SRRT",
      "color": "#85D38D",
      "x": -671.7220926874318,
      "y": -764.1208279802152
    },
    {
      "id": 266,
      "label": "CD276",
      "group": 1,
      "value": 20,
      "title": "CD276",
      "color": "#85D38D",
      "x": -700.6478703331428,
      "y": -618.7011236814808
    },
    {
      "id": 267,
      "label": "INSM1",
      "group": 1,
      "value": 20,
      "title": "INSM1",
      "color": "#85D38D",
      "x": -783.0215158365438,
      "y": -495.42025112932674
    },
    {
      "id": 268,
      "label": "CHGA",
      "group": 1,
      "value": 20,
      "title": "CHGA",
      "color": "#85D38D",
      "x": -906.3023883886973,
      "y": -413.0466056259261
    },
    {
      "id": 269,
      "label": "OLIG2",
      "group": 1,
      "value": 20,
      "title": "OLIG2",
      "color": "#85D38D",
      "x": -1051.7220926874315,
      "y": -384.1208279802149
    },
    {
      "id": 270,
      "label": "APH1A",
      "group": 1,
      "value": 20,
      "title": "APH1A",
      "color": "#85D38D",
      "x": -1197.141796986166,
      "y": -413.0466056259259
    },
    {
      "id": 271,
      "label": "APH1B",
      "group": 1,
      "value": 20,
      "title": "APH1B",
      "color": "#85D38D",
      "x": -1320.42266953832,
      "y": -495.4202511293269
    },
    {
      "id": 272,
      "label": "BCL2",
      "group": 1,
      "value": 20,
      "title": "BCL2",
      "color": "#85D38D",
      "x": -1402.7963150417206,
      "y": -618.7011236814805
    },
    {
      "id": 273,
      "label": "SLFN11",
      "group": 1,
      "value": 20,
      "title": "SLFN11",
      "color": "#85D38D",
      "x": -1431.7220926874318,
      "y": -764.1208279802147
    },
    {
      "id": 274,
      "label": "RBPJ",
      "group": 1,
      "value": 20,
      "title": "RBPJ",
      "color": "#85D38D",
      "x": -1402.796315041721,
      "y": -909.5405322789484
    },
    {
      "id": 275,
      "label": "AURKA",
      "group": 1,
      "value": 20,
      "title": "AURKA",
      "color": "#85D38D",
      "x": -1320.4226695383197,
      "y": -1032.821404831103
    },
    {
      "id": 276,
      "label": "GNLY",
      "group": 1,
      "value": 20,
      "title": "GNLY",
      "color": "#85D38D",
      "x": -1197.1417969861664,
      "y": -1115.1950503345038
    },
    {
      "id": 277,
      "label": "DECR1",
      "group": 1,
      "value": 20,
      "title": "DECR1",
      "color": "#85D38D",
      "x": -1291.722092687432,
      "y": -348.4286341636845
    },
    {
      "id": 278,
      "label": "FOLR1",
      "group": 1,
      "value": 20,
      "title": "FOLR1",
      "color": "#85D38D",
      "x": -1408.4316089165814,
      "y": -442.93813692796317
    },
    {
      "id": 279,
      "label": "VIM",
      "group": 1,
      "value": 20,
      "title": "VIM",
      "color": "#85D38D",
      "x": -1490.2239123558802,
      "y": -568.887239303831
    },
    {
      "id": 280,
      "label": "CHI3L1",
      "group": 1,
      "value": 20,
      "title": "CHI3L1",
      "color": "#85D38D",
      "x": -1529.092602464203,
      "y": -713.9471656117415
    },
    {
      "id": 281,
      "label": "MYCL",
      "group": 1,
      "value": 20,
      "title": "MYCL",
      "color": "#85D38D",
      "x": -1521.2329410396583,
      "y": -863.9184395727397
    },
    {
      "id": 282,
      "label": "HSPG2",
      "group": 1,
      "value": 20,
      "title": "HSPG2",
      "color": "#85D38D",
      "x": -1467.4142865039626,
      "y": -1004.1208279802145
    },
    {
      "id": 283,
      "label": "TET1",
      "group": 1,
      "value": 20,
      "title": "TET1",
      "color": "#85D38D",
      "x": -1372.9047837396836,
      "y": -1120.8303442093643
    },
    {
      "id": 284,
      "label": "PROM1",
      "group": 1,
      "value": 20,
      "title": "PROM1",
      "color": "#85D38D",
      "x": -1246.9556813638164,
      "y": -1202.6226476486631
    },
    {
      "id": 285,
      "label": "TIGIT",
      "group": 1,
      "value": 20,
      "title": "TIGIT",
      "color": "#85D38D",
      "x": -1101.8957550559053,
      "y": -1241.4913377569862
    },
    {
      "id": 286,
      "label": "BDNF",
      "group": 1,
      "value": 20,
      "title": "BDNF",
      "color": "#85D38D",
      "x": -951.9244810949078,
      "y": -1233.6316763324417
    },
    {
      "id": 287,
      "label": "PDGFRA",
      "group": 1,
      "value": 20,
      "title": "PDGFRA",
      "color": "#85D38D",
      "x": -811.7220926874315,
      "y": -1179.8130217967455
    },
    {
      "id": 288,
      "label": "LAG3",
      "group": 1,
      "value": 20,
      "title": "LAG3",
      "color": "#85D38D",
      "x": -695.012576458283,
      "y": -1085.3035190324672
    },
    {
      "id": 289,
      "label": "STEAP2",
      "group": 1,
      "value": 20,
      "title": "STEAP2",
      "color": "#85D38D",
      "x": -613.2202730189832,
      "y": -959.3544166565988
    },
    {
      "id": 290,
      "label": "PIK3R3",
      "group": 1,
      "value": 20,
      "title": "PIK3R3",
      "color": "#85D38D",
      "x": -574.3515829106607,
      "y": -814.2944903486892
    },
    {
      "id": 291,
      "label": "TUBB3",
      "group": 1,
      "value": 20,
      "title": "TUBB3",
      "color": "#85D38D",
      "x": -582.211244335205,
      "y": -664.3232163876902
    },
    {
      "id": 292,
      "label": "SLC2A5",
      "group": 1,
      "value": 20,
      "title": "SLC2A5",
      "color": "#85D38D",
      "x": -636.0298988709009,
      "y": -524.1208279802155
    },
    {
      "id": 293,
      "label": "PSEN1",
      "group": 1,
      "value": 20,
      "title": "PSEN1",
      "color": "#85D38D",
      "x": -730.53940163518,
      "y": -407.41131175106557
    },
    {
      "id": 294,
      "label": "BCL9",
      "group": 1,
      "value": 20,
      "title": "BCL9",
      "color": "#85D38D",
      "x": -856.4885040110471,
      "y": -325.6190083117668
    },
    {
      "id": 295,
      "label": "TCF4",
      "group": 1,
      "value": 20,
      "title": "TCF4",
      "color": "#85D38D",
      "x": -1001.5484303189583,
      "y": -286.75031820344367
    },
    {
      "id": 296,
      "label": "TCF7L2",
      "group": 1,
      "value": 20,
      "title": "TCF7L2",
      "color": "#85D38D",
      "x": -1151.5197042799557,
      "y": -294.6099796279881
    },
    {
      "id": 297,
      "label": "SYP",
      "group": 1,
      "value": 20,
      "title": "SYP",
      "color": "#85D38D",
      "x": -549.4273584924574,
      "y": -1054.120827980215
    },
    {
      "id": 298,
      "label": "TUBA4A",
      "group": 1,
      "value": 20,
      "title": "TUBA4A",
      "color": "#85D38D",
      "x": -491.4851134397719,
      "y": -914.2358741396762
    },
    {
      "id": 299,
      "label": "ADAM17",
      "group": 1,
      "value": 20,
      "title": "ADAM17",
      "color": "#85D38D",
      "x": -471.72209268743177,
      "y": -764.1208279802154
    },
    {
      "id": 300,
      "label": "LVRN",
      "group": 1,
      "value": 20,
      "title": "LVRN",
      "color": "#85D38D",
      "x": -491.48511343977225,
      "y": -614.0057818207524
    },
    {
      "id": 301,
      "label": "CD46",
      "group": 1,
      "value": 20,
      "title": "CD46",
      "color": "#85D38D",
      "x": -549.427358492457,
      "y": -474.1208279802156
    },
    {
      "id": 302,
      "label": "COL4A1",
      "group": 1,
      "value": 20,
      "title": "COL4A1",
      "color": "#85D38D",
      "x": -641.6001595992343,
      "y": -353.99889489201735
    },
    {
      "id": 303,
      "label": "METAP1D",
      "group": 1,
      "value": 20,
      "title": "METAP1D",
      "color": "#85D38D",
      "x": -761.7220926874309,
      "y": -261.826093785241
    },
    {
      "id": 304,
      "label": "MGMT",
      "group": 1,
      "value": 20,
      "title": "MGMT",
      "color": "#85D38D",
      "x": -901.6070465279695,
      "y": -203.8838487325554
    },
    {
      "id": 305,
      "label": "FLT4",
      "group": 1,
      "value": 20,
      "title": "FLT4",
      "color": "#85D38D",
      "x": -1051.7220926874325,
      "y": -184.12082798021493
    },
    {
      "id": 306,
      "label": "HNF1A",
      "group": 1,
      "value": 20,
      "title": "HNF1A",
      "color": "#85D38D",
      "x": -1201.8371388468931,
      "y": -203.88384873255518
    },
    {
      "id": 307,
      "label": "RNPEPL1",
      "group": 1,
      "value": 20,
      "title": "RNPEPL1",
      "color": "#85D38D",
      "x": -1341.722092687432,
      "y": -261.82609378524063
    },
    {
      "id": 308,
      "label": "BCAN",
      "group": 1,
      "value": 20,
      "title": "BCAN",
      "color": "#85D38D",
      "x": -1461.8440257756288,
      "y": -353.99889489201666
    },
    {
      "id": 309,
      "label": "COL4A5",
      "group": 1,
      "value": 20,
      "title": "COL4A5",
      "color": "#85D38D",
      "x": -1554.0168268824061,
      "y": -474.1208279802148
    },
    {
      "id": 310,
      "label": "IDH1",
      "group": 1,
      "value": 20,
      "title": "IDH1",
      "color": "#85D38D",
      "x": -1611.9590719350917,
      "y": -614.0057818207536
    },
    {
      "id": 311,
      "label": "COL5A1",
      "group": 1,
      "value": 20,
      "title": "COL5A1",
      "color": "#85D38D",
      "x": -1631.7220926874318,
      "y": -764.1208279802145
    },
    {
      "id": 312,
      "label": "COL4A3",
      "group": 1,
      "value": 20,
      "title": "COL4A3",
      "color": "#85D38D",
      "x": -1611.9590719350913,
      "y": -914.2358741396773
    },
    {
      "id": 313,
      "label": "COL5A2",
      "group": 1,
      "value": 20,
      "title": "COL5A2",
      "color": "#85D38D",
      "x": -1554.0168268824066,
      "y": -1054.1208279802142
    },
    {
      "id": 314,
      "label": "COL18A1",
      "group": 1,
      "value": 20,
      "title": "COL18A1",
      "color": "#85D38D",
      "x": -1461.8440257756292,
      "y": -1174.2427610684126
    },
    {
      "id": 315,
      "label": "AOPEP",
      "group": 1,
      "value": 20,
      "title": "AOPEP",
      "color": "#85D38D",
      "x": -1341.722092687433,
      "y": -1266.4155621751888
    },
    {
      "id": 316,
      "label": "TNFRSF9",
      "group": 1,
      "value": 20,
      "title": "TNFRSF9",
      "color": "#85D38D",
      "x": -1201.837138846894,
      "y": -1324.3578072278744
    },
    {
      "id": 317,
      "label": "TUBB8B",
      "group": 1,
      "value": 20,
      "title": "TUBB8B",
      "color": "#85D38D",
      "x": -1051.7220926874313,
      "y": -1344.120827980215
    },
    {
      "id": 318,
      "label": "COL1A1",
      "group": 1,
      "value": 20,
      "title": "COL1A1",
      "color": "#85D38D",
      "x": -901.6070465279704,
      "y": -1324.3578072278747
    },
    {
      "id": 1,
      "label": "DLL3",
      "group": 1,
      "value": 20,
      "title": "DLL3",
      "color": "#D79B97",
      "x": 481.72209268743137,
      "y": -1236.3734711836996
    },
    {
      "id": 2,
      "label": "VEGFA",
      "group": 1,
      "value": 20,
      "title": "VEGFA",
      "color": "#D79B97",
      "x": 401.72209268743137,
      "y": -1156.3734711836996
    },
    {
      "id": 3,
      "label": "PTEN",
      "group": 1,
      "value": 20,
      "title": "PTEN",
      "color": "#D79B97",
      "x": 321.72209268743137,
      "y": -1236.3734711836996
    },
    {
      "id": 5,
      "label": "IGF2",
      "group": 1,
      "value": 20,
      "title": "IGF2",
      "color": "#D79B97",
      "x": 401.72209268743137,
      "y": -1316.3734711836996
    },
    {
      "id": 6,
      "label": "SST",
      "group": 1,
      "value": 20,
      "title": "SST",
      "color": "#D79B97",
      "x": 245.8375200062324,
      "y": -1326.3734711836996
    },
    {
      "id": 7,
      "label": "CDKN2A",
      "group": 1,
      "value": 20,
      "title": "CDKN2A",
      "color": "#D79B97",
      "x": 355.13466456897766,
      "y": -1410.2401199157318
    },
    {
      "id": 9,
      "label": "CTNNB1",
      "group": 1,
      "value": 20,
      "title": "CTNNB1",
      "color": "#D79B97",
      "x": 491.72209268743137,
      "y": -1392.2580438648986
    },
    {
      "id": 10,
      "label": "FLT1",
      "group": 1,
      "value": 20,
      "title": "FLT1",
      "color": "#D79B97",
      "x": 575.5887414194636,
      "y": -1282.9608993021534
    },
    {
      "id": 11,
      "label": "TF",
      "group": 1,
      "value": 20,
      "title": "TF",
      "color": "#D79B97",
      "x": 557.6066653686303,
      "y": -1146.3734711836996
    },
    {
      "id": 12,
      "label": "FGR",
      "group": 1,
      "value": 20,
      "title": "FGR",
      "color": "#D79B97",
      "x": 448.3095208058851,
      "y": -1062.5068224516674
    },
    {
      "id": 16,
      "label": "CDKN2A",
      "group": 1,
      "value": 20,
      "title": "CDKN2A",
      "color": "#D79B97",
      "x": 311.72209268743154,
      "y": -1080.4888985025007
    },
    {
      "id": 17,
      "label": "ABCA1",
      "group": 1,
      "value": 20,
      "title": "ABCA1",
      "color": "#D79B97",
      "x": 227.85544395539907,
      "y": -1189.7860430652458
    },
    {
      "id": 18,
      "label": "GPI",
      "group": 1,
      "value": 20,
      "title": "GPI",
      "color": "#D79B97",
      "x": 541.7220926874313,
      "y": -993.8863581240568
    },
    {
      "id": 19,
      "label": "PPARG",
      "group": 1,
      "value": 20,
      "title": "PPARG",
      "color": "#D79B97",
      "x": 401.7220926874315,
      "y": -956.3734711836996
    },
    {
      "id": 21,
      "label": "CSF2",
      "group": 1,
      "value": 20,
      "title": "CSF2",
      "color": "#D79B97",
      "x": 261.7220926874316,
      "y": -993.8863581240566
    },
    {
      "id": 25,
      "label": "WT1",
      "group": 1,
      "value": 20,
      "title": "WT1",
      "color": "#D79B97",
      "x": 159.2349796277885,
      "y": -1096.3734711836996
    },
    {
      "id": 26,
      "label": "EGFR",
      "group": 1,
      "value": 20,
      "title": "EGFR",
      "color": "#D79B97",
      "x": 121.72209268743137,
      "y": -1236.3734711836996
    },
    {
      "id": 27,
      "label": "AKT1",
      "group": 1,
      "value": 20,
      "title": "AKT1",
      "color": "#D79B97",
      "x": 159.23497962778842,
      "y": -1376.3734711836994
    },
    {
      "id": 32,
      "label": "FGFR1",
      "group": 1,
      "value": 20,
      "title": "FGFR1",
      "color": "#D79B97",
      "x": 261.7220926874314,
      "y": -1478.8605842433424
    },
    {
      "id": 41,
      "label": "CD44",
      "group": 1,
      "value": 20,
      "title": "CD44",
      "color": "#D79B97",
      "x": 401.72209268743126,
      "y": -1516.3734711836996
    },
    {
      "id": 42,
      "label": "NRCAM",
      "group": 1,
      "value": 20,
      "title": "NRCAM",
      "color": "#D79B97",
      "x": 541.7220926874311,
      "y": -1478.8605842433426
    },
    {
      "id": 43,
      "label": "HSP90AB1",
      "group": 1,
      "value": 20,
      "title": "HSP90AB1",
      "color": "#D79B97",
      "x": 644.2092057470742,
      "y": -1376.3734711836996
    },
    {
      "id": 44,
      "label": "EPHB2",
      "group": 1,
      "value": 20,
      "title": "EPHB2",
      "color": "#D79B97",
      "x": 681.7220926874313,
      "y": -1236.3734711836998
    },
    {
      "id": 45,
      "label": "MAPK1",
      "group": 1,
      "value": 20,
      "title": "MAPK1",
      "color": "#D79B97",
      "x": 644.2092057470743,
      "y": -1096.3734711836998
    },
    {
      "id": 46,
      "label": "MMP2",
      "group": 1,
      "value": 20,
      "title": "MMP2",
      "color": "#D79B97",
      "x": 401.7220926874312,
      "y": -1616.3734711836996
    },
    {
      "id": 47,
      "label": "ATM",
      "group": 1,
      "value": 20,
      "title": "ATM",
      "color": "#D79B97",
      "x": 547.1417969861654,
      "y": -1587.4476935379885
    },
    {
      "id": 48,
      "label": "SERPINC1",
      "group": 1,
      "value": 20,
      "title": "SERPINC1",
      "color": "#D79B97",
      "x": 670.4226695383195,
      "y": -1505.0740480345876
    },
    {
      "id": 49,
      "label": "FASLG",
      "group": 1,
      "value": 20,
      "title": "FASLG",
      "color": "#D79B97",
      "x": 752.7963150417202,
      "y": -1381.793175482434
    },
    {
      "id": 50,
      "label": "PTGS1",
      "group": 1,
      "value": 20,
      "title": "PTGS1",
      "color": "#D79B97",
      "x": 781.7220926874313,
      "y": -1236.3734711836998
    },
    {
      "id": 51,
      "label": "RB1",
      "group": 1,
      "value": 20,
      "title": "RB1",
      "color": "#D79B97",
      "x": 752.7963150417204,
      "y": -1090.9537668849655
    },
    {
      "id": 52,
      "label": "ABCB1",
      "group": 1,
      "value": 20,
      "title": "ABCB1",
      "color": "#D79B97",
      "x": 670.4226695383193,
      "y": -967.6728943328114
    },
    {
      "id": 53,
      "label": "CSF3",
      "group": 1,
      "value": 20,
      "title": "CSF3",
      "color": "#D79B97",
      "x": 547.1417969861658,
      "y": -885.2992488294108
    },
    {
      "id": 54,
      "label": "EPAS1",
      "group": 1,
      "value": 20,
      "title": "EPAS1",
      "color": "#D79B97",
      "x": 401.7220926874316,
      "y": -856.3734711836996
    },
    {
      "id": 56,
      "label": "STAT1",
      "group": 1,
      "value": 20,
      "title": "STAT1",
      "color": "#D79B97",
      "x": 256.3023883886973,
      "y": -885.2992488294105
    },
    {
      "id": 57,
      "label": "IGFBP3",
      "group": 1,
      "value": 20,
      "title": "IGFBP3",
      "color": "#D79B97",
      "x": 133.0215158365432,
      "y": -967.6728943328117
    },
    {
      "id": 59,
      "label": "FZR1",
      "group": 1,
      "value": 20,
      "title": "FZR1",
      "color": "#D79B97",
      "x": 50.64787033314252,
      "y": -1090.9537668849653
    },
    {
      "id": 63,
      "label": "SOX2",
      "group": 1,
      "value": 20,
      "title": "SOX2",
      "color": "#D79B97",
      "x": 21.72209268743137,
      "y": -1236.3734711836994
    },
    {
      "id": 68,
      "label": "IL1B",
      "group": 1,
      "value": 20,
      "title": "IL1B",
      "color": "#D79B97",
      "x": 50.64787033314212,
      "y": -1381.793175482433
    },
    {
      "id": 69,
      "label": "PIK3CA",
      "group": 1,
      "value": 20,
      "title": "PIK3CA",
      "color": "#D79B97",
      "x": 133.02151583654336,
      "y": -1505.0740480345878
    },
    {
      "id": 70,
      "label": "NGF",
      "group": 1,
      "value": 20,
      "title": "NGF",
      "color": "#D79B97",
      "x": 256.30238838869684,
      "y": -1587.4476935379885
    },
    {
      "id": 71,
      "label": "NF1",
      "group": 1,
      "value": 20,
      "title": "NF1",
      "color": "#D79B97",
      "x": 161.72209268743111,
      "y": -820.6812773671692
    },
    {
      "id": 73,
      "label": "BCR",
      "group": 1,
      "value": 20,
      "title": "BCR",
      "color": "#D79B97",
      "x": 45.0125764582819,
      "y": -915.1907801314478
    },
    {
      "id": 74,
      "label": "PTGS2",
      "group": 1,
      "value": 20,
      "title": "PTGS2",
      "color": "#D79B97",
      "x": -36.77972698101718,
      "y": -1041.1398825073159
    },
    {
      "id": 75,
      "label": "CD274",
      "group": 1,
      "value": 20,
      "title": "CD274",
      "color": "#D79B97",
      "x": -75.64841708933989,
      "y": -1186.1998088152263
    },
    {
      "id": 76,
      "label": "SMAD3",
      "group": 1,
      "value": 20,
      "title": "SMAD3",
      "color": "#D79B97",
      "x": -67.78875566479525,
      "y": -1336.1710827762245
    },
    {
      "id": 77,
      "label": "MB",
      "group": 1,
      "value": 20,
      "title": "MB",
      "color": "#D79B97",
      "x": -13.970101129099476,
      "y": -1476.3734711836992
    },
    {
      "id": 78,
      "label": "GH1",
      "group": 1,
      "value": 20,
      "title": "GH1",
      "color": "#D79B97",
      "x": 80.53940163517962,
      "y": -1593.082987412849
    },
    {
      "id": 79,
      "label": "IGF1",
      "group": 1,
      "value": 20,
      "title": "IGF1",
      "color": "#D79B97",
      "x": 206.4885040110467,
      "y": -1674.8752908521478
    },
    {
      "id": 80,
      "label": "GNPDA1",
      "group": 1,
      "value": 20,
      "title": "GNPDA1",
      "color": "#D79B97",
      "x": 351.54843031895797,
      "y": -1713.743980960471
    },
    {
      "id": 81,
      "label": "GLUL",
      "group": 1,
      "value": 20,
      "title": "GLUL",
      "color": "#D79B97",
      "x": 501.51970427995525,
      "y": -1705.8843195359264
    },
    {
      "id": 82,
      "label": "ACTB",
      "group": 1,
      "value": 20,
      "title": "ACTB",
      "color": "#D79B97",
      "x": 641.7220926874315,
      "y": -1652.0656650002302
    },
    {
      "id": 83,
      "label": "HIF1A",
      "group": 1,
      "value": 20,
      "title": "HIF1A",
      "color": "#D79B97",
      "x": 758.4316089165802,
      "y": -1557.556162235952
    },
    {
      "id": 84,
      "label": "NRG1",
      "group": 1,
      "value": 20,
      "title": "NRG1",
      "color": "#D79B97",
      "x": 840.22391235588,
      "y": -1431.6070598600836
    },
    {
      "id": 85,
      "label": "SERPINE1",
      "group": 1,
      "value": 20,
      "title": "SERPINE1",
      "color": "#D79B97",
      "x": 879.0926024642025,
      "y": -1286.5471335521738
    },
    {
      "id": 86,
      "label": "TFPI",
      "group": 1,
      "value": 20,
      "title": "TFPI",
      "color": "#D79B97",
      "x": 871.232941039658,
      "y": -1136.575859591175
    },
    {
      "id": 88,
      "label": "TGFB1",
      "group": 1,
      "value": 20,
      "title": "TGFB1",
      "color": "#D79B97",
      "x": 817.4142865039623,
      "y": -996.3734711837002
    },
    {
      "id": 89,
      "label": "GHR",
      "group": 1,
      "value": 20,
      "title": "GHR",
      "color": "#D79B97",
      "x": 722.9047837396831,
      "y": -879.6639549545503
    },
    {
      "id": 90,
      "label": "FGF2",
      "group": 1,
      "value": 20,
      "title": "FGF2",
      "color": "#D79B97",
      "x": 596.9556813638161,
      "y": -797.8716515152514
    },
    {
      "id": 94,
      "label": "ERBB2",
      "group": 1,
      "value": 20,
      "title": "ERBB2",
      "color": "#D79B97",
      "x": 451.89575505590483,
      "y": -759.0029614069283
    },
    {
      "id": 95,
      "label": "DDIT3",
      "group": 1,
      "value": 20,
      "title": "DDIT3",
      "color": "#D79B97",
      "x": 301.92448109490755,
      "y": -766.8626228314728
    },
    {
      "id": 96,
      "label": "CXCR4",
      "group": 1,
      "value": 20,
      "title": "CXCR4",
      "color": "#D79B97",
      "x": 904.0168268824058,
      "y": -1526.3734711836996
    },
    {
      "id": 97,
      "label": "RAF1",
      "group": 1,
      "value": 20,
      "title": "RAF1",
      "color": "#D79B97",
      "x": 961.9590719350913,
      "y": -1386.488517343161
    },
    {
      "id": 99,
      "label": "IL10",
      "group": 1,
      "value": 20,
      "title": "IL10",
      "color": "#D79B97",
      "x": 981.7220926874313,
      "y": -1236.3734711837
    },
    {
      "id": 100,
      "label": "MAGED2",
      "group": 1,
      "value": 20,
      "title": "MAGED2",
      "color": "#D79B97",
      "x": 961.9590719350908,
      "y": -1086.258425024237
    },
    {
      "id": 108,
      "label": "NOTCH2NLB",
      "group": 1,
      "value": 20,
      "title": "NOTCH2NLB",
      "color": "#D79B97",
      "x": 904.0168268824062,
      "y": -946.3734711837003
    },
    {
      "id": 109,
      "label": "DLL4",
      "group": 1,
      "value": 20,
      "title": "DLL4",
      "color": "#D79B97",
      "x": 811.8440257756289,
      "y": -826.251538095502
    },
    {
      "id": 110,
      "label": "DLL1",
      "group": 1,
      "value": 20,
      "title": "DLL1",
      "color": "#D79B97",
      "x": 691.7220926874323,
      "y": -734.0787369887257
    },
    {
      "id": 111,
      "label": "SNED1",
      "group": 1,
      "value": 20,
      "title": "SNED1",
      "color": "#D79B97",
      "x": 551.8371388468937,
      "y": -676.1364919360401
    },
    {
      "id": 112,
      "label": "JAG2",
      "group": 1,
      "value": 20,
      "title": "JAG2",
      "color": "#D79B97",
      "x": 401.7220926874308,
      "y": -656.3734711836996
    },
    {
      "id": 113,
      "label": "JAG1",
      "group": 1,
      "value": 20,
      "title": "JAG1",
      "color": "#D79B97",
      "x": 251.60704652796994,
      "y": -676.1364919360399
    },
    {
      "id": 114,
      "label": "NOTCH2NLA",
      "group": 1,
      "value": 20,
      "title": "NOTCH2NLA",
      "color": "#D79B97",
      "x": 111.7220926874312,
      "y": -734.0787369887253
    },
    {
      "id": 133,
      "label": "PVALEF",
      "group": 1,
      "value": 20,
      "title": "PVALEF",
      "color": "#D79B97",
      "x": -8.399840400765527,
      "y": -826.2515380955014
    },
    {
      "id": 134,
      "label": "PCDHGB5",
      "group": 1,
      "value": 20,
      "title": "PCDHGB5",
      "color": "#D79B97",
      "x": -100.57264150754293,
      "y": -946.3734711836995
    },
    {
      "id": 135,
      "label": "PCDH11X",
      "group": 1,
      "value": 20,
      "title": "PCDH11X",
      "color": "#D79B97",
      "x": -158.5148865602285,
      "y": -1086.2584250242382
    },
    {
      "id": 140,
      "label": "DLK1",
      "group": 1,
      "value": 20,
      "title": "DLK1",
      "color": "#D79B97",
      "x": -178.27790731256863,
      "y": -1236.3734711836992
    },
    {
      "id": 141,
      "label": "NOTCH1",
      "group": 1,
      "value": 20,
      "title": "NOTCH1",
      "color": "#D79B97",
      "x": -158.51488656022815,
      "y": -1386.4885173431621
    },
    {
      "id": 146,
      "label": "DLK2",
      "group": 1,
      "value": 20,
      "title": "DLK2",
      "color": "#D79B97",
      "x": -100.5726415075435,
      "y": -1526.373471183699
    },
    {
      "id": 147,
      "label": "NOTCH4",
      "group": 1,
      "value": 20,
      "title": "NOTCH4",
      "color": "#D79B97",
      "x": -8.399840400766209,
      "y": -1646.4954042718973
    },
    {
      "id": 0,
      "label": "CNR1",
      "group": 0,
      "value": 30,
      "title": "CNR1",
      "color": "#007DC4",
      "x": 0,
      "y": 0
    }
  ],
  "edges": [
    { "from": 92, "to": 62, "color": "#EDC962" },
    { "from": 92, "to": 94, "color": "#EDC962" },
    { "from": 92, "to": 277, "color": "#EDC962" },
    { "from": 92, "to": 102, "color": "#EDC962" },
    { "from": 92, "to": 93, "color": "#EDC962" },
    { "from": 92, "to": 314, "color": "#EDC962" },
    { "from": 92, "to": 104, "color": "#EDC962" },
    { "from": 92, "to": 256, "color": "#EDC962" },
    { "from": 92, "to": 91, "color": "#EDC962" },
    { "from": 92, "to": 56, "color": "#EDC962" },
    { "from": 92, "to": 36, "color": "#EDC962" },
    { "from": 92, "to": 176, "color": "#EDC962" },
    { "from": 92, "to": 55, "color": "#EDC962" },
    { "from": 92, "to": 265, "color": "#EDC962" },
    { "from": 92, "to": 248, "color": "#EDC962" },
    { "from": 92, "to": 260, "color": "#EDC962" },
    { "from": 92, "to": 107, "color": "#EDC962" },
    { "from": 92, "to": 199, "color": "#EDC962" },
    { "from": 92, "to": 183, "color": "#EDC962" },
    { "from": 92, "to": 175, "color": "#EDC962" },
    { "from": 92, "to": 29, "color": "#EDC962" },
    { "from": 92, "to": 45, "color": "#EDC962" },
    { "from": 92, "to": 261, "color": "#EDC962" },
    { "from": 92, "to": 54, "color": "#EDC962" },
    { "from": 92, "to": 43, "color": "#EDC962" },
    { "from": 92, "to": 262, "color": "#EDC962" },
    { "from": 92, "to": 82, "color": "#EDC962" },
    { "from": 92, "to": 26, "color": "#EDC962" },
    { "from": 92, "to": 174, "color": "#EDC962" },
    { "from": 92, "to": 263, "color": "#EDC962" },
    { "from": 92, "to": 275, "color": "#EDC962" },
    { "from": 92, "to": 69, "color": "#EDC962" },
    { "from": 92, "to": 20, "color": "#EDC962" },
    { "from": 92, "to": 83, "color": "#EDC962" },
    { "from": 92, "to": 11, "color": "#EDC962" },
    { "from": 92, "to": 304, "color": "#EDC962" },
    { "from": 92, "to": 72, "color": "#EDC962" },
    { "from": 92, "to": 100, "color": "#EDC962" },
    { "from": 92, "to": 253, "color": "#EDC962" },
    { "from": 92, "to": 9, "color": "#EDC962" },
    { "from": 92, "to": 76, "color": "#EDC962" },
    { "from": 92, "to": 58, "color": "#EDC962" },
    { "from": 92, "to": 61, "color": "#EDC962" },
    { "from": 92, "to": 27, "color": "#EDC962" },
    { "from": 92, "to": 3, "color": "#EDC962" },
    { "from": 92, "to": 291, "color": "#EDC962" },
    { "from": 92, "to": 101, "color": "#EDC962" },
    { "from": 57, "to": 318, "color": "#D79B97" },
    { "from": 57, "to": 82, "color": "#D79B97" },
    { "from": 57, "to": 79, "color": "#D79B97" },
    { "from": 57, "to": 5, "color": "#D79B97" },
    { "from": 57, "to": 41, "color": "#D79B97" },
    { "from": 57, "to": 58, "color": "#D79B97" },
    { "from": 57, "to": 11, "color": "#D79B97" },
    { "from": 57, "to": 93, "color": "#D79B97" },
    { "from": 57, "to": 26, "color": "#D79B97" },
    { "from": 57, "to": 39, "color": "#D79B97" },
    { "from": 57, "to": 19, "color": "#D79B97" },
    { "from": 57, "to": 29, "color": "#D79B97" },
    { "from": 57, "to": 275, "color": "#D79B97" },
    { "from": 3, "to": 287, "color": "#D79B97" },
    { "from": 3, "to": 38, "color": "#D79B97" },
    { "from": 3, "to": 102, "color": "#D79B97" },
    { "from": 3, "to": 72, "color": "#D79B97" },
    { "from": 3, "to": 45, "color": "#D79B97" },
    { "from": 3, "to": 141, "color": "#D79B97" },
    { "from": 3, "to": 176, "color": "#D79B97" },
    { "from": 3, "to": 91, "color": "#D79B97" },
    { "from": 3, "to": 59, "color": "#D79B97" },
    { "from": 3, "to": 62, "color": "#D79B97" },
    { "from": 3, "to": 34, "color": "#D79B97" },
    { "from": 3, "to": 29, "color": "#D79B97" },
    { "from": 3, "to": 27, "color": "#D79B97" },
    { "from": 3, "to": 111, "color": "#D79B97" },
    { "from": 3, "to": 33, "color": "#D79B97" },
    { "from": 3, "to": 9, "color": "#D79B97" },
    { "from": 3, "to": 26, "color": "#D79B97" },
    { "from": 3, "to": 224, "color": "#D79B97" },
    { "from": 3, "to": 252, "color": "#D79B97" },
    { "from": 3, "to": 42, "color": "#D79B97" },
    { "from": 3, "to": 261, "color": "#D79B97" },
    { "from": 3, "to": 291, "color": "#D79B97" },
    { "from": 3, "to": 43, "color": "#D79B97" },
    { "from": 3, "to": 100, "color": "#D79B97" },
    { "from": 3, "to": 250, "color": "#D79B97" },
    { "from": 3, "to": 310, "color": "#D79B97" },
    { "from": 3, "to": 82, "color": "#D79B97" },
    { "from": 3, "to": 20, "color": "#D79B97" },
    { "from": 3, "to": 71, "color": "#D79B97" },
    { "from": 3, "to": 263, "color": "#D79B97" },
    { "from": 3, "to": 101, "color": "#D79B97" },
    { "from": 3, "to": 15, "color": "#D79B97" },
    { "from": 3, "to": 93, "color": "#D79B97" },
    { "from": 3, "to": 262, "color": "#D79B97" },
    { "from": 3, "to": 98, "color": "#D79B97" },
    { "from": 94, "to": 82, "color": "#D79B97" },
    { "from": 94, "to": 72, "color": "#D79B97" },
    { "from": 94, "to": 62, "color": "#D79B97" },
    { "from": 94, "to": 102, "color": "#D79B97" },
    { "from": 94, "to": 43, "color": "#D79B97" },
    { "from": 94, "to": 101, "color": "#D79B97" },
    { "from": 94, "to": 88, "color": "#D79B97" },
    { "from": 94, "to": 290, "color": "#D79B97" },
    { "from": 94, "to": 34, "color": "#D79B97" },
    { "from": 94, "to": 15, "color": "#D79B97" },
    { "from": 94, "to": 28, "color": "#D79B97" },
    { "from": 94, "to": 44, "color": "#D79B97" },
    { "from": 94, "to": 275, "color": "#D79B97" },
    { "from": 94, "to": 98, "color": "#D79B97" },
    { "from": 94, "to": 27, "color": "#D79B97" },
    { "from": 94, "to": 305, "color": "#D79B97" },
    { "from": 94, "to": 32, "color": "#D79B97" },
    { "from": 94, "to": 287, "color": "#D79B97" },
    { "from": 94, "to": 93, "color": "#D79B97" },
    { "from": 94, "to": 33, "color": "#D79B97" },
    { "from": 94, "to": 4, "color": "#D79B97" },
    { "from": 94, "to": 41, "color": "#D79B97" },
    { "from": 94, "to": 26, "color": "#D79B97" },
    { "from": 94, "to": 12, "color": "#D79B97" },
    { "from": 94, "to": 56, "color": "#D79B97" },
    { "from": 94, "to": 9, "color": "#D79B97" },
    { "from": 94, "to": 91, "color": "#D79B97" },
    { "from": 94, "to": 69, "color": "#D79B97" },
    { "from": 94, "to": 37, "color": "#D79B97" },
    { "from": 94, "to": 59, "color": "#D79B97" },
    { "from": 94, "to": 29, "color": "#D79B97" },
    { "from": 94, "to": 39, "color": "#D79B97" },
    { "from": 94, "to": 84, "color": "#D79B97" },
    { "from": 94, "to": 74, "color": "#D79B97" },
    { "from": 94, "to": 40, "color": "#D79B97" },
    { "from": 94, "to": 97, "color": "#D79B97" },
    { "from": 94, "to": 261, "color": "#D79B97" },
    { "from": 94, "to": 31, "color": "#D79B97" },
    { "from": 71, "to": 262, "color": "#D79B97" },
    { "from": 71, "to": 27, "color": "#D79B97" },
    { "from": 71, "to": 75, "color": "#D79B97" },
    { "from": 71, "to": 26, "color": "#D79B97" },
    { "from": 71, "to": 39, "color": "#D79B97" },
    { "from": 71, "to": 97, "color": "#D79B97" },
    { "from": 71, "to": 29, "color": "#D79B97" },
    { "from": 71, "to": 40, "color": "#D79B97" },
    { "from": 287, "to": 305, "color": "#85D38D" },
    { "from": 287, "to": 7, "color": "#85D38D" },
    { "from": 287, "to": 101, "color": "#85D38D" },
    { "from": 287, "to": 290, "color": "#85D38D" },
    { "from": 287, "to": 41, "color": "#85D38D" },
    { "from": 287, "to": 27, "color": "#85D38D" },
    { "from": 287, "to": 98, "color": "#85D38D" },
    { "from": 287, "to": 64, "color": "#85D38D" },
    { "from": 287, "to": 33, "color": "#85D38D" },
    { "from": 287, "to": 275, "color": "#85D38D" },
    { "from": 287, "to": 29, "color": "#85D38D" },
    { "from": 287, "to": 34, "color": "#85D38D" },
    { "from": 287, "to": 69, "color": "#85D38D" },
    { "from": 287, "to": 88, "color": "#85D38D" },
    { "from": 287, "to": 314, "color": "#85D38D" },
    { "from": 287, "to": 105, "color": "#85D38D" },
    { "from": 287, "to": 4, "color": "#85D38D" },
    { "from": 287, "to": 286, "color": "#85D38D" },
    { "from": 287, "to": 59, "color": "#85D38D" },
    { "from": 287, "to": 261, "color": "#85D38D" },
    { "from": 287, "to": 26, "color": "#85D38D" },
    { "from": 287, "to": 97, "color": "#85D38D" },
    { "from": 287, "to": 56, "color": "#85D38D" },
    { "from": 287, "to": 20, "color": "#85D38D" },
    { "from": 287, "to": 28, "color": "#85D38D" },
    { "from": 62, "to": 102, "color": "#EDC962" },
    { "from": 62, "to": 293, "color": "#EDC962" },
    { "from": 62, "to": 72, "color": "#EDC962" },
    { "from": 62, "to": 41, "color": "#EDC962" },
    { "from": 62, "to": 39, "color": "#EDC962" },
    { "from": 62, "to": 29, "color": "#EDC962" },
    { "from": 62, "to": 31, "color": "#EDC962" },
    { "from": 62, "to": 58, "color": "#EDC962" },
    { "from": 62, "to": 291, "color": "#EDC962" },
    { "from": 62, "to": 273, "color": "#EDC962" },
    { "from": 51, "to": 56, "color": "#D79B97" },
    { "from": 51, "to": 243, "color": "#D79B97" },
    { "from": 51, "to": 66, "color": "#D79B97" },
    { "from": 51, "to": 98, "color": "#D79B97" },
    { "from": 51, "to": 97, "color": "#D79B97" },
    { "from": 51, "to": 29, "color": "#D79B97" },
    { "from": 51, "to": 261, "color": "#D79B97" },
    { "from": 51, "to": 19, "color": "#D79B97" },
    { "from": 51, "to": 59, "color": "#D79B97" },
    { "from": 51, "to": 290, "color": "#D79B97" },
    { "from": 51, "to": 141, "color": "#D79B97" },
    { "from": 51, "to": 33, "color": "#D79B97" },
    { "from": 51, "to": 83, "color": "#D79B97" },
    { "from": 51, "to": 263, "color": "#D79B97" },
    { "from": 51, "to": 28, "color": "#D79B97" },
    { "from": 51, "to": 237, "color": "#D79B97" },
    { "from": 51, "to": 73, "color": "#D79B97" },
    { "from": 51, "to": 60, "color": "#D79B97" },
    { "from": 93, "to": 76, "color": "#EDC962" },
    { "from": 93, "to": 101, "color": "#EDC962" },
    { "from": 93, "to": 175, "color": "#EDC962" },
    { "from": 93, "to": 171, "color": "#EDC962" },
    { "from": 93, "to": 298, "color": "#EDC962" },
    { "from": 93, "to": 290, "color": "#EDC962" },
    { "from": 93, "to": 38, "color": "#EDC962" },
    { "from": 93, "to": 9, "color": "#EDC962" },
    { "from": 93, "to": 261, "color": "#EDC962" },
    { "from": 93, "to": 43, "color": "#EDC962" },
    { "from": 93, "to": 98, "color": "#EDC962" },
    { "from": 93, "to": 89, "color": "#EDC962" },
    { "from": 93, "to": 26, "color": "#EDC962" },
    { "from": 93, "to": 79, "color": "#EDC962" },
    { "from": 93, "to": 20, "color": "#EDC962" },
    { "from": 93, "to": 27, "color": "#EDC962" },
    { "from": 93, "to": 15, "color": "#EDC962" },
    { "from": 93, "to": 263, "color": "#EDC962" },
    { "from": 93, "to": 28, "color": "#EDC962" },
    { "from": 93, "to": 39, "color": "#EDC962" },
    { "from": 93, "to": 252, "color": "#EDC962" },
    { "from": 43, "to": 82, "color": "#D79B97" },
    { "from": 43, "to": 102, "color": "#D79B97" },
    { "from": 43, "to": 97, "color": "#D79B97" },
    { "from": 43, "to": 9, "color": "#D79B97" },
    { "from": 43, "to": 83, "color": "#D79B97" },
    { "from": 43, "to": 15, "color": "#D79B97" },
    { "from": 43, "to": 4, "color": "#D79B97" },
    { "from": 43, "to": 141, "color": "#D79B97" },
    { "from": 43, "to": 98, "color": "#D79B97" },
    { "from": 43, "to": 100, "color": "#D79B97" },
    { "from": 43, "to": 55, "color": "#D79B97" },
    { "from": 43, "to": 63, "color": "#D79B97" },
    { "from": 43, "to": 66, "color": "#D79B97" },
    { "from": 43, "to": 20, "color": "#D79B97" },
    { "from": 43, "to": 27, "color": "#D79B97" },
    { "from": 43, "to": 39, "color": "#D79B97" },
    { "from": 43, "to": 19, "color": "#D79B97" },
    { "from": 43, "to": 28, "color": "#D79B97" },
    { "from": 43, "to": 101, "color": "#D79B97" },
    { "from": 43, "to": 32, "color": "#D79B97" },
    { "from": 43, "to": 291, "color": "#D79B97" },
    { "from": 43, "to": 261, "color": "#D79B97" },
    { "from": 43, "to": 31, "color": "#D79B97" },
    { "from": 43, "to": 34, "color": "#D79B97" },
    { "from": 43, "to": 58, "color": "#D79B97" },
    { "from": 43, "to": 29, "color": "#D79B97" },
    { "from": 43, "to": 318, "color": "#D79B97" },
    { "from": 43, "to": 275, "color": "#D79B97" },
    { "from": 43, "to": 12, "color": "#D79B97" },
    { "from": 43, "to": 56, "color": "#D79B97" },
    { "from": 43, "to": 252, "color": "#D79B97" },
    { "from": 43, "to": 305, "color": "#D79B97" },
    { "from": 43, "to": 273, "color": "#D79B97" },
    { "from": 43, "to": 26, "color": "#D79B97" },
    { "from": 43, "to": 304, "color": "#D79B97" },
    { "from": 279, "to": 176, "color": "#85D38D" },
    { "from": 279, "to": 66, "color": "#85D38D" },
    { "from": 279, "to": 27, "color": "#85D38D" },
    { "from": 279, "to": 82, "color": "#85D38D" },
    { "from": 279, "to": 29, "color": "#85D38D" },
    { "from": 279, "to": 261, "color": "#85D38D" },
    { "from": 279, "to": 28, "color": "#85D38D" },
    { "from": 279, "to": 26, "color": "#85D38D" },
    { "from": 279, "to": 304, "color": "#85D38D" },
    { "from": 279, "to": 273, "color": "#85D38D" },
    { "from": 279, "to": 58, "color": "#85D38D" },
    { "from": 279, "to": 306, "color": "#85D38D" },
    { "from": 279, "to": 60, "color": "#85D38D" },
    { "from": 102, "to": 176, "color": "#5FD6E7" },
    { "from": 102, "to": 82, "color": "#5FD6E7" },
    { "from": 102, "to": 56, "color": "#5FD6E7" },
    { "from": 102, "to": 100, "color": "#5FD6E7" },
    { "from": 102, "to": 279, "color": "#5FD6E7" },
    { "from": 102, "to": 318, "color": "#5FD6E7" },
    { "from": 102, "to": 310, "color": "#5FD6E7" },
    { "from": 102, "to": 18, "color": "#5FD6E7" },
    { "from": 102, "to": 45, "color": "#5FD6E7" },
    { "from": 102, "to": 272, "color": "#5FD6E7" },
    { "from": 102, "to": 261, "color": "#5FD6E7" },
    { "from": 102, "to": 252, "color": "#5FD6E7" },
    { "from": 102, "to": 159, "color": "#5FD6E7" },
    { "from": 102, "to": 101, "color": "#5FD6E7" },
    { "from": 31, "to": 76, "color": "#EDC962" },
    { "from": 31, "to": 102, "color": "#EDC962" },
    { "from": 31, "to": 98, "color": "#EDC962" },
    { "from": 31, "to": 27, "color": "#EDC962" },
    { "from": 31, "to": 40, "color": "#EDC962" },
    { "from": 31, "to": 66, "color": "#EDC962" },
    { "from": 31, "to": 9, "color": "#EDC962" },
    { "from": 31, "to": 26, "color": "#EDC962" },
    { "from": 31, "to": 293, "color": "#EDC962" },
    { "from": 31, "to": 15, "color": "#EDC962" },
    { "from": 31, "to": 310, "color": "#EDC962" },
    { "from": 31, "to": 141, "color": "#EDC962" },
    { "from": 31, "to": 241, "color": "#EDC962" },
    { "from": 31, "to": 56, "color": "#EDC962" },
    { "from": 31, "to": 218, "color": "#EDC962" },
    { "from": 31, "to": 252, "color": "#EDC962" },
    { "from": 31, "to": 228, "color": "#EDC962" },
    { "from": 31, "to": 238, "color": "#EDC962" },
    { "from": 31, "to": 299, "color": "#EDC962" },
    { "from": 31, "to": 29, "color": "#EDC962" },
    { "from": 31, "to": 39, "color": "#EDC962" },
    { "from": 31, "to": 118, "color": "#EDC962" },
    { "from": 31, "to": 315, "color": "#EDC962" },
    { "from": 31, "to": 88, "color": "#EDC962" },
    { "from": 31, "to": 60, "color": "#EDC962" },
    { "from": 31, "to": 188, "color": "#EDC962" },
    { "from": 31, "to": 32, "color": "#EDC962" },
    { "from": 31, "to": 101, "color": "#EDC962" },
    { "from": 252, "to": 83, "color": "#85D38D" },
    { "from": 252, "to": 279, "color": "#85D38D" },
    { "from": 252, "to": 72, "color": "#85D38D" },
    { "from": 252, "to": 310, "color": "#85D38D" },
    { "from": 252, "to": 62, "color": "#85D38D" },
    { "from": 252, "to": 314, "color": "#85D38D" },
    { "from": 252, "to": 39, "color": "#85D38D" },
    { "from": 252, "to": 56, "color": "#85D38D" },
    { "from": 252, "to": 38, "color": "#85D38D" },
    { "from": 252, "to": 107, "color": "#85D38D" },
    { "from": 252, "to": 132, "color": "#85D38D" },
    { "from": 252, "to": 298, "color": "#85D38D" },
    { "from": 252, "to": 291, "color": "#85D38D" },
    { "from": 252, "to": 18, "color": "#85D38D" },
    { "from": 252, "to": 176, "color": "#85D38D" },
    { "from": 252, "to": 9, "color": "#85D38D" },
    { "from": 252, "to": 30, "color": "#85D38D" },
    { "from": 252, "to": 82, "color": "#85D38D" },
    { "from": 252, "to": 261, "color": "#85D38D" },
    { "from": 252, "to": 26, "color": "#85D38D" },
    { "from": 252, "to": 120, "color": "#85D38D" },
    { "from": 252, "to": 76, "color": "#85D38D" },
    { "from": 252, "to": 283, "color": "#85D38D" },
    { "from": 252, "to": 63, "color": "#85D38D" },
    { "from": 252, "to": 61, "color": "#85D38D" },
    { "from": 252, "to": 177, "color": "#85D38D" },
    { "from": 252, "to": 101, "color": "#85D38D" },
    { "from": 252, "to": 29, "color": "#85D38D" },
    { "from": 252, "to": 241, "color": "#85D38D" },
    { "from": 83, "to": 7, "color": "#D79B97" },
    { "from": 83, "to": 283, "color": "#D79B97" },
    { "from": 83, "to": 96, "color": "#D79B97" },
    { "from": 83, "to": 98, "color": "#D79B97" },
    { "from": 83, "to": 20, "color": "#D79B97" },
    { "from": 83, "to": 2, "color": "#D79B97" },
    { "from": 83, "to": 29, "color": "#D79B97" },
    { "from": 83, "to": 33, "color": "#D79B97" },
    { "from": 83, "to": 27, "color": "#D79B97" },
    { "from": 83, "to": 58, "color": "#D79B97" },
    { "from": 83, "to": 103, "color": "#D79B97" },
    { "from": 83, "to": 272, "color": "#D79B97" },
    { "from": 83, "to": 275, "color": "#D79B97" },
    { "from": 83, "to": 76, "color": "#D79B97" },
    { "from": 83, "to": 261, "color": "#D79B97" },
    { "from": 83, "to": 34, "color": "#D79B97" },
    { "from": 83, "to": 9, "color": "#D79B97" },
    { "from": 83, "to": 73, "color": "#D79B97" },
    { "from": 83, "to": 54, "color": "#D79B97" },
    { "from": 83, "to": 59, "color": "#D79B97" },
    { "from": 83, "to": 47, "color": "#D79B97" },
    { "from": 83, "to": 141, "color": "#D79B97" },
    { "from": 83, "to": 45, "color": "#D79B97" },
    { "from": 83, "to": 88, "color": "#D79B97" },
    { "from": 76, "to": 263, "color": "#D79B97" },
    { "from": 76, "to": 9, "color": "#D79B97" },
    { "from": 76, "to": 237, "color": "#D79B97" },
    { "from": 76, "to": 45, "color": "#D79B97" },
    { "from": 76, "to": 11, "color": "#D79B97" },
    { "from": 76, "to": 14, "color": "#D79B97" },
    { "from": 76, "to": 82, "color": "#D79B97" },
    { "from": 76, "to": 147, "color": "#D79B97" },
    { "from": 76, "to": 141, "color": "#D79B97" },
    { "from": 76, "to": 54, "color": "#D79B97" },
    { "from": 76, "to": 218, "color": "#D79B97" },
    { "from": 76, "to": 27, "color": "#D79B97" },
    { "from": 76, "to": 88, "color": "#D79B97" },
    { "from": 76, "to": 29, "color": "#D79B97" },
    { "from": 76, "to": 261, "color": "#D79B97" },
    { "from": 76, "to": 98, "color": "#D79B97" },
    { "from": 76, "to": 262, "color": "#D79B97" },
    { "from": 76, "to": 69, "color": "#D79B97" },
    { "from": 76, "to": 101, "color": "#D79B97" },
    { "from": 76, "to": 255, "color": "#D79B97" },
    { "from": 76, "to": 259, "color": "#D79B97" },
    { "from": 76, "to": 19, "color": "#D79B97" },
    { "from": 13, "to": 305, "color": "#EDC962" },
    { "from": 13, "to": 72, "color": "#EDC962" },
    { "from": 13, "to": 176, "color": "#EDC962" },
    { "from": 13, "to": 14, "color": "#EDC962" },
    { "from": 13, "to": 232, "color": "#EDC962" },
    { "from": 13, "to": 266, "color": "#EDC962" },
    { "from": 13, "to": 82, "color": "#EDC962" },
    { "from": 272, "to": 39, "color": "#85D38D" },
    { "from": 272, "to": 45, "color": "#85D38D" },
    { "from": 272, "to": 97, "color": "#85D38D" },
    { "from": 272, "to": 261, "color": "#85D38D" },
    { "from": 272, "to": 293, "color": "#85D38D" },
    { "from": 272, "to": 98, "color": "#85D38D" },
    { "from": 272, "to": 52, "color": "#85D38D" },
    { "from": 272, "to": 29, "color": "#85D38D" },
    { "from": 272, "to": 60, "color": "#85D38D" },
    { "from": 272, "to": 101, "color": "#85D38D" },
    { "from": 261, "to": 73, "color": "#85D38D" },
    { "from": 261, "to": 7, "color": "#85D38D" },
    { "from": 261, "to": 101, "color": "#85D38D" },
    { "from": 261, "to": 310, "color": "#85D38D" },
    { "from": 261, "to": 55, "color": "#85D38D" },
    { "from": 261, "to": 6, "color": "#85D38D" },
    { "from": 261, "to": 128, "color": "#85D38D" },
    { "from": 261, "to": 262, "color": "#85D38D" },
    { "from": 261, "to": 104, "color": "#85D38D" },
    { "from": 261, "to": 222, "color": "#85D38D" },
    { "from": 261, "to": 269, "color": "#85D38D" },
    { "from": 261, "to": 298, "color": "#85D38D" },
    { "from": 261, "to": 237, "color": "#85D38D" },
    { "from": 261, "to": 74, "color": "#85D38D" },
    { "from": 261, "to": 295, "color": "#85D38D" },
    { "from": 261, "to": 27, "color": "#85D38D" },
    { "from": 261, "to": 100, "color": "#85D38D" },
    { "from": 261, "to": 275, "color": "#85D38D" },
    { "from": 261, "to": 25, "color": "#85D38D" },
    { "from": 261, "to": 14, "color": "#85D38D" },
    { "from": 261, "to": 15, "color": "#85D38D" },
    { "from": 261, "to": 277, "color": "#85D38D" },
    { "from": 261, "to": 45, "color": "#85D38D" },
    { "from": 261, "to": 26, "color": "#85D38D" },
    { "from": 261, "to": 66, "color": "#85D38D" },
    { "from": 261, "to": 29, "color": "#85D38D" },
    { "from": 261, "to": 47, "color": "#85D38D" },
    { "from": 261, "to": 274, "color": "#85D38D" },
    { "from": 261, "to": 60, "color": "#85D38D" },
    { "from": 261, "to": 64, "color": "#85D38D" },
    { "from": 261, "to": 147, "color": "#85D38D" },
    { "from": 261, "to": 98, "color": "#85D38D" },
    { "from": 261, "to": 38, "color": "#85D38D" },
    { "from": 261, "to": 82, "color": "#85D38D" },
    { "from": 261, "to": 107, "color": "#85D38D" },
    { "from": 261, "to": 175, "color": "#85D38D" },
    { "from": 261, "to": 62, "color": "#85D38D" },
    { "from": 261, "to": 273, "color": "#85D38D" },
    { "from": 261, "to": 20, "color": "#85D38D" },
    { "from": 261, "to": 19, "color": "#85D38D" },
    { "from": 261, "to": 41, "color": "#85D38D" },
    { "from": 261, "to": 23, "color": "#85D38D" },
    { "from": 261, "to": 88, "color": "#85D38D" },
    { "from": 261, "to": 291, "color": "#85D38D" },
    { "from": 261, "to": 40, "color": "#85D38D" },
    { "from": 261, "to": 69, "color": "#85D38D" },
    { "from": 261, "to": 89, "color": "#85D38D" },
    { "from": 261, "to": 59, "color": "#85D38D" },
    { "from": 261, "to": 290, "color": "#85D38D" },
    { "from": 261, "to": 259, "color": "#85D38D" },
    { "from": 19, "to": 101, "color": "#D79B97" },
    { "from": 19, "to": 56, "color": "#D79B97" },
    { "from": 19, "to": 9, "color": "#D79B97" },
    { "from": 19, "to": 263, "color": "#D79B97" },
    { "from": 19, "to": 45, "color": "#D79B97" },
    { "from": 19, "to": 26, "color": "#D79B97" },
    { "from": 19, "to": 295, "color": "#D79B97" },
    { "from": 19, "to": 82, "color": "#D79B97" },
    { "from": 82, "to": 61, "color": "#D79B97" },
    { "from": 82, "to": 62, "color": "#D79B97" },
    { "from": 82, "to": 101, "color": "#D79B97" },
    { "from": 82, "to": 26, "color": "#D79B97" },
    { "from": 82, "to": 27, "color": "#D79B97" },
    { "from": 82, "to": 32, "color": "#D79B97" },
    { "from": 82, "to": 284, "color": "#D79B97" },
    { "from": 82, "to": 263, "color": "#D79B97" },
    { "from": 82, "to": 30, "color": "#D79B97" },
    { "from": 82, "to": 306, "color": "#D79B97" },
    { "from": 82, "to": 39, "color": "#D79B97" },
    { "from": 82, "to": 9, "color": "#D79B97" },
    { "from": 82, "to": 237, "color": "#D79B97" },
    { "from": 82, "to": 259, "color": "#D79B97" },
    { "from": 82, "to": 304, "color": "#D79B97" },
    { "from": 82, "to": 176, "color": "#D79B97" },
    { "from": 82, "to": 58, "color": "#D79B97" },
    { "from": 82, "to": 29, "color": "#D79B97" },
    { "from": 82, "to": 12, "color": "#D79B97" },
    { "from": 64, "to": 27, "color": "#EDC962" },
    { "from": 64, "to": 102, "color": "#EDC962" },
    { "from": 64, "to": 9, "color": "#EDC962" },
    { "from": 64, "to": 59, "color": "#EDC962" },
    { "from": 64, "to": 33, "color": "#EDC962" },
    { "from": 64, "to": 20, "color": "#EDC962" },
    { "from": 64, "to": 29, "color": "#EDC962" },
    { "from": 64, "to": 98, "color": "#EDC962" },
    { "from": 64, "to": 97, "color": "#EDC962" },
    { "from": 64, "to": 101, "color": "#EDC962" },
    { "from": 64, "to": 15, "color": "#EDC962" },
    { "from": 56, "to": 26, "color": "#D79B97" },
    { "from": 56, "to": 20, "color": "#D79B97" },
    { "from": 56, "to": 9, "color": "#D79B97" },
    { "from": 56, "to": 29, "color": "#D79B97" },
    { "from": 56, "to": 60, "color": "#D79B97" },
    { "from": 56, "to": 88, "color": "#D79B97" },
    { "from": 56, "to": 69, "color": "#D79B97" },
    { "from": 56, "to": 28, "color": "#D79B97" },
    { "from": 56, "to": 34, "color": "#D79B97" },
    { "from": 34, "to": 106, "color": "#EDC962" },
    { "from": 34, "to": 101, "color": "#EDC962" },
    { "from": 34, "to": 232, "color": "#EDC962" },
    { "from": 34, "to": 15, "color": "#EDC962" },
    { "from": 34, "to": 26, "color": "#EDC962" },
    { "from": 34, "to": 290, "color": "#EDC962" },
    { "from": 34, "to": 97, "color": "#EDC962" },
    { "from": 34, "to": 28, "color": "#EDC962" },
    { "from": 34, "to": 32, "color": "#EDC962" },
    { "from": 34, "to": 23, "color": "#EDC962" },
    { "from": 26, "to": 102, "color": "#D79B97" },
    { "from": 26, "to": 72, "color": "#D79B97" },
    { "from": 26, "to": 101, "color": "#D79B97" },
    { "from": 26, "to": 37, "color": "#D79B97" },
    { "from": 26, "to": 30, "color": "#D79B97" },
    { "from": 26, "to": 238, "color": "#D79B97" },
    { "from": 26, "to": 32, "color": "#D79B97" },
    { "from": 26, "to": 204, "color": "#D79B97" },
    { "from": 26, "to": 255, "color": "#D79B97" },
    { "from": 26, "to": 140, "color": "#D79B97" },
    { "from": 26, "to": 63, "color": "#D79B97" },
    { "from": 26, "to": 66, "color": "#D79B97" },
    { "from": 26, "to": 88, "color": "#D79B97" },
    { "from": 26, "to": 106, "color": "#D79B97" },
    { "from": 26, "to": 275, "color": "#D79B97" },
    { "from": 26, "to": 29, "color": "#D79B97" },
    { "from": 26, "to": 265, "color": "#D79B97" },
    { "from": 26, "to": 41, "color": "#D79B97" },
    { "from": 26, "to": 290, "color": "#D79B97" },
    { "from": 26, "to": 9, "color": "#D79B97" },
    { "from": 26, "to": 61, "color": "#D79B97" },
    { "from": 26, "to": 15, "color": "#D79B97" },
    { "from": 26, "to": 58, "color": "#D79B97" },
    { "from": 26, "to": 38, "color": "#D79B97" },
    { "from": 26, "to": 97, "color": "#D79B97" },
    { "from": 26, "to": 36, "color": "#D79B97" },
    { "from": 26, "to": 175, "color": "#D79B97" },
    { "from": 26, "to": 14, "color": "#D79B97" },
    { "from": 26, "to": 222, "color": "#D79B97" },
    { "from": 26, "to": 12, "color": "#D79B97" },
    { "from": 26, "to": 249, "color": "#D79B97" },
    { "from": 26, "to": 84, "color": "#D79B97" },
    { "from": 26, "to": 291, "color": "#D79B97" },
    { "from": 26, "to": 173, "color": "#D79B97" },
    { "from": 26, "to": 178, "color": "#D79B97" },
    { "from": 26, "to": 20, "color": "#D79B97" },
    { "from": 26, "to": 87, "color": "#D79B97" },
    { "from": 26, "to": 298, "color": "#D79B97" },
    { "from": 26, "to": 69, "color": "#D79B97" },
    { "from": 26, "to": 39, "color": "#D79B97" },
    { "from": 26, "to": 27, "color": "#D79B97" },
    { "from": 26, "to": 81, "color": "#D79B97" },
    { "from": 26, "to": 17, "color": "#D79B97" },
    { "from": 26, "to": 318, "color": "#D79B97" },
    { "from": 26, "to": 273, "color": "#D79B97" },
    { "from": 26, "to": 28, "color": "#D79B97" },
    { "from": 26, "to": 165, "color": "#D79B97" },
    { "from": 26, "to": 171, "color": "#D79B97" },
    { "from": 26, "to": 167, "color": "#D79B97" },
    { "from": 26, "to": 213, "color": "#D79B97" },
    { "from": 26, "to": 103, "color": "#D79B97" },
    { "from": 26, "to": 40, "color": "#D79B97" },
    { "from": 26, "to": 315, "color": "#D79B97" },
    { "from": 26, "to": 179, "color": "#D79B97" },
    { "from": 26, "to": 100, "color": "#D79B97" },
    { "from": 255, "to": 176, "color": "#85D38D" },
    { "from": 255, "to": 38, "color": "#85D38D" },
    { "from": 255, "to": 296, "color": "#85D38D" },
    { "from": 263, "to": 102, "color": "#85D38D" },
    { "from": 263, "to": 101, "color": "#85D38D" },
    { "from": 263, "to": 68, "color": "#85D38D" },
    { "from": 263, "to": 25, "color": "#85D38D" },
    { "from": 263, "to": 9, "color": "#85D38D" },
    { "from": 263, "to": 262, "color": "#85D38D" },
    { "from": 263, "to": 237, "color": "#85D38D" },
    { "from": 263, "to": 274, "color": "#85D38D" },
    { "from": 263, "to": 27, "color": "#85D38D" },
    { "from": 263, "to": 29, "color": "#85D38D" },
    { "from": 263, "to": 4, "color": "#85D38D" },
    { "from": 263, "to": 38, "color": "#85D38D" },
    { "from": 263, "to": 279, "color": "#85D38D" },
    { "from": 263, "to": 43, "color": "#85D38D" },
    { "from": 263, "to": 176, "color": "#85D38D" },
    { "from": 263, "to": 30, "color": "#85D38D" },
    { "from": 263, "to": 228, "color": "#85D38D" },
    { "from": 263, "to": 55, "color": "#85D38D" },
    { "from": 263, "to": 41, "color": "#85D38D" },
    { "from": 263, "to": 171, "color": "#85D38D" },
    { "from": 263, "to": 62, "color": "#85D38D" },
    { "from": 263, "to": 18, "color": "#85D38D" },
    { "from": 263, "to": 56, "color": "#85D38D" },
    { "from": 263, "to": 298, "color": "#85D38D" },
    { "from": 263, "to": 45, "color": "#85D38D" },
    { "from": 263, "to": 310, "color": "#85D38D" },
    { "from": 263, "to": 72, "color": "#85D38D" },
    { "from": 263, "to": 100, "color": "#85D38D" },
    { "from": 263, "to": 37, "color": "#85D38D" },
    { "from": 263, "to": 291, "color": "#85D38D" },
    { "from": 263, "to": 71, "color": "#85D38D" },
    { "from": 271, "to": 293, "color": "#85D38D" },
    { "from": 271, "to": 286, "color": "#85D38D" },
    { "from": 98, "to": 7, "color": "#EDC962" },
    { "from": 98, "to": 27, "color": "#EDC962" },
    { "from": 98, "to": 38, "color": "#EDC962" },
    { "from": 98, "to": 41, "color": "#EDC962" },
    { "from": 98, "to": 33, "color": "#EDC962" },
    { "from": 98, "to": 29, "color": "#EDC962" },
    { "from": 98, "to": 12, "color": "#EDC962" },
    { "from": 98, "to": 59, "color": "#EDC962" },
    { "from": 98, "to": 63, "color": "#EDC962" },
    { "from": 98, "to": 28, "color": "#EDC962" },
    { "from": 98, "to": 39, "color": "#EDC962" },
    { "from": 98, "to": 45, "color": "#EDC962" },
    { "from": 98, "to": 97, "color": "#EDC962" },
    { "from": 98, "to": 15, "color": "#EDC962" },
    { "from": 98, "to": 40, "color": "#EDC962" },
    { "from": 275, "to": 7, "color": "#85D38D" },
    { "from": 275, "to": 102, "color": "#85D38D" },
    { "from": 275, "to": 18, "color": "#85D38D" },
    { "from": 275, "to": 175, "color": "#85D38D" },
    { "from": 275, "to": 66, "color": "#85D38D" },
    { "from": 275, "to": 221, "color": "#85D38D" },
    { "from": 275, "to": 88, "color": "#85D38D" },
    { "from": 275, "to": 27, "color": "#85D38D" },
    { "from": 275, "to": 9, "color": "#85D38D" },
    { "from": 275, "to": 59, "color": "#85D38D" },
    { "from": 275, "to": 237, "color": "#85D38D" },
    { "from": 275, "to": 171, "color": "#85D38D" },
    { "from": 275, "to": 29, "color": "#85D38D" },
    { "from": 275, "to": 58, "color": "#85D38D" },
    { "from": 275, "to": 101, "color": "#85D38D" },
    { "from": 275, "to": 39, "color": "#85D38D" },
    { "from": 275, "to": 33, "color": "#85D38D" },
    { "from": 275, "to": 281, "color": "#85D38D" },
    { "from": 7, "to": 33, "color": "#D79B97" },
    { "from": 7, "to": 237, "color": "#D79B97" },
    { "from": 7, "to": 38, "color": "#D79B97" },
    { "from": 7, "to": 29, "color": "#D79B97" },
    { "from": 7, "to": 176, "color": "#D79B97" },
    { "from": 7, "to": 43, "color": "#D79B97" },
    { "from": 7, "to": 82, "color": "#D79B97" },
    { "from": 7, "to": 58, "color": "#D79B97" },
    { "from": 7, "to": 93, "color": "#D79B97" },
    { "from": 7, "to": 3, "color": "#D79B97" },
    { "from": 7, "to": 130, "color": "#D79B97" },
    { "from": 7, "to": 27, "color": "#D79B97" },
    { "from": 7, "to": 41, "color": "#D79B97" },
    { "from": 7, "to": 64, "color": "#D79B97" },
    { "from": 7, "to": 40, "color": "#D79B97" },
    { "from": 7, "to": 66, "color": "#D79B97" },
    { "from": 7, "to": 88, "color": "#D79B97" },
    { "from": 72, "to": 295, "color": "#EDC962" },
    { "from": 72, "to": 27, "color": "#EDC962" },
    { "from": 72, "to": 39, "color": "#EDC962" },
    { "from": 72, "to": 97, "color": "#EDC962" },
    { "from": 72, "to": 33, "color": "#EDC962" },
    { "from": 72, "to": 29, "color": "#EDC962" },
    { "from": 72, "to": 310, "color": "#EDC962" },
    { "from": 72, "to": 30, "color": "#EDC962" },
    { "from": 72, "to": 71, "color": "#EDC962" },
    { "from": 72, "to": 43, "color": "#EDC962" },
    { "from": 72, "to": 9, "color": "#EDC962" },
    { "from": 72, "to": 69, "color": "#EDC962" },
    { "from": 237, "to": 141, "color": "#85D38D" },
    { "from": 237, "to": 29, "color": "#85D38D" },
    { "from": 237, "to": 99, "color": "#85D38D" },
    { "from": 237, "to": 97, "color": "#85D38D" },
    { "from": 4, "to": 101, "color": "#EDC962" },
    { "from": 4, "to": 32, "color": "#EDC962" },
    { "from": 4, "to": 90, "color": "#EDC962" },
    { "from": 4, "to": 282, "color": "#EDC962" },
    { "from": 4, "to": 29, "color": "#EDC962" },
    { "from": 4, "to": 61, "color": "#EDC962" },
    { "from": 38, "to": 171, "color": "#EDC962" },
    { "from": 38, "to": 80, "color": "#EDC962" },
    { "from": 38, "to": 304, "color": "#EDC962" },
    { "from": 38, "to": 18, "color": "#EDC962" },
    { "from": 38, "to": 47, "color": "#EDC962" },
    { "from": 38, "to": 20, "color": "#EDC962" },
    { "from": 38, "to": 29, "color": "#EDC962" },
    { "from": 38, "to": 88, "color": "#EDC962" },
    { "from": 38, "to": 142, "color": "#EDC962" },
    { "from": 38, "to": 9, "color": "#EDC962" },
    { "from": 38, "to": 33, "color": "#EDC962" },
    { "from": 38, "to": 39, "color": "#EDC962" },
    { "from": 38, "to": 59, "color": "#EDC962" },
    { "from": 38, "to": 81, "color": "#EDC962" },
    { "from": 260, "to": 7, "color": "#85D38D" },
    { "from": 29, "to": 102, "color": "#EDC962" },
    { "from": 29, "to": 262, "color": "#EDC962" },
    { "from": 29, "to": 17, "color": "#EDC962" },
    { "from": 29, "to": 101, "color": "#EDC962" },
    { "from": 29, "to": 67, "color": "#EDC962" },
    { "from": 29, "to": 314, "color": "#EDC962" },
    { "from": 29, "to": 171, "color": "#EDC962" },
    { "from": 29, "to": 85, "color": "#EDC962" },
    { "from": 29, "to": 41, "color": "#EDC962" },
    { "from": 29, "to": 249, "color": "#EDC962" },
    { "from": 29, "to": 86, "color": "#EDC962" },
    { "from": 29, "to": 104, "color": "#EDC962" },
    { "from": 29, "to": 176, "color": "#EDC962" },
    { "from": 29, "to": 74, "color": "#EDC962" },
    { "from": 29, "to": 28, "color": "#EDC962" },
    { "from": 29, "to": 18, "color": "#EDC962" },
    { "from": 29, "to": 11, "color": "#EDC962" },
    { "from": 29, "to": 58, "color": "#EDC962" },
    { "from": 29, "to": 15, "color": "#EDC962" },
    { "from": 29, "to": 291, "color": "#EDC962" },
    { "from": 29, "to": 91, "color": "#EDC962" },
    { "from": 29, "to": 27, "color": "#EDC962" },
    { "from": 29, "to": 100, "color": "#EDC962" },
    { "from": 29, "to": 277, "color": "#EDC962" },
    { "from": 29, "to": 298, "color": "#EDC962" },
    { "from": 29, "to": 274, "color": "#EDC962" },
    { "from": 29, "to": 66, "color": "#EDC962" },
    { "from": 29, "to": 55, "color": "#EDC962" },
    { "from": 29, "to": 30, "color": "#EDC962" },
    { "from": 29, "to": 265, "color": "#EDC962" },
    { "from": 29, "to": 128, "color": "#EDC962" },
    { "from": 29, "to": 45, "color": "#EDC962" },
    { "from": 29, "to": 155, "color": "#EDC962" },
    { "from": 29, "to": 97, "color": "#EDC962" },
    { "from": 29, "to": 73, "color": "#EDC962" },
    { "from": 29, "to": 9, "color": "#EDC962" },
    { "from": 29, "to": 295, "color": "#EDC962" },
    { "from": 29, "to": 47, "color": "#EDC962" },
    { "from": 29, "to": 69, "color": "#EDC962" },
    { "from": 29, "to": 39, "color": "#EDC962" },
    { "from": 29, "to": 296, "color": "#EDC962" },
    { "from": 29, "to": 229, "color": "#EDC962" },
    { "from": 29, "to": 61, "color": "#EDC962" },
    { "from": 29, "to": 243, "color": "#EDC962" },
    { "from": 29, "to": 68, "color": "#EDC962" },
    { "from": 29, "to": 267, "color": "#EDC962" },
    { "from": 29, "to": 241, "color": "#EDC962" },
    { "from": 29, "to": 59, "color": "#EDC962" },
    { "from": 29, "to": 36, "color": "#EDC962" },
    { "from": 29, "to": 52, "color": "#EDC962" },
    { "from": 29, "to": 217, "color": "#EDC962" },
    { "from": 9, "to": 101, "color": "#D79B97" },
    { "from": 9, "to": 102, "color": "#D79B97" },
    { "from": 9, "to": 293, "color": "#D79B97" },
    { "from": 9, "to": 39, "color": "#D79B97" },
    { "from": 9, "to": 74, "color": "#D79B97" },
    { "from": 9, "to": 32, "color": "#D79B97" },
    { "from": 9, "to": 296, "color": "#D79B97" },
    { "from": 9, "to": 63, "color": "#D79B97" },
    { "from": 9, "to": 295, "color": "#D79B97" },
    { "from": 9, "to": 141, "color": "#D79B97" },
    { "from": 9, "to": 294, "color": "#D79B97" },
    { "from": 9, "to": 10, "color": "#D79B97" },
    { "from": 9, "to": 49, "color": "#D79B97" },
    { "from": 9, "to": 27, "color": "#D79B97" },
    { "from": 9, "to": 40, "color": "#D79B97" },
    { "from": 9, "to": 60, "color": "#D79B97" },
    { "from": 9, "to": 284, "color": "#D79B97" },
    { "from": 9, "to": 69, "color": "#D79B97" },
    { "from": 9, "to": 15, "color": "#D79B97" },
    { "from": 9, "to": 44, "color": "#D79B97" },
    { "from": 9, "to": 286, "color": "#D79B97" },
    { "from": 9, "to": 54, "color": "#D79B97" },
    { "from": 9, "to": 59, "color": "#D79B97" },
    { "from": 9, "to": 28, "color": "#D79B97" },
    { "from": 58, "to": 102, "color": "#EDC962" },
    { "from": 58, "to": 101, "color": "#EDC962" },
    { "from": 58, "to": 38, "color": "#EDC962" },
    { "from": 58, "to": 277, "color": "#EDC962" },
    { "from": 58, "to": 144, "color": "#EDC962" },
    { "from": 58, "to": 11, "color": "#EDC962" },
    { "from": 58, "to": 2, "color": "#EDC962" },
    { "from": 58, "to": 282, "color": "#EDC962" },
    { "from": 58, "to": 41, "color": "#EDC962" },
    { "from": 58, "to": 318, "color": "#EDC962" },
    { "from": 58, "to": 49, "color": "#EDC962" },
    { "from": 58, "to": 46, "color": "#EDC962" },
    { "from": 58, "to": 22, "color": "#EDC962" },
    { "from": 58, "to": 61, "color": "#EDC962" },
    { "from": 298, "to": 102, "color": "#85D38D" },
    { "from": 298, "to": 291, "color": "#85D38D" },
    { "from": 298, "to": 228, "color": "#85D38D" },
    { "from": 298, "to": 315, "color": "#85D38D" },
    { "from": 298, "to": 88, "color": "#85D38D" },
    { "from": 298, "to": 97, "color": "#85D38D" },
    { "from": 298, "to": 101, "color": "#85D38D" },
    { "from": 298, "to": 100, "color": "#85D38D" },
    { "from": 298, "to": 63, "color": "#85D38D" },
    { "from": 262, "to": 102, "color": "#85D38D" },
    { "from": 262, "to": 68, "color": "#85D38D" },
    { "from": 262, "to": 20, "color": "#85D38D" },
    { "from": 262, "to": 25, "color": "#85D38D" },
    { "from": 262, "to": 237, "color": "#85D38D" },
    { "from": 262, "to": 38, "color": "#85D38D" },
    { "from": 262, "to": 62, "color": "#85D38D" },
    { "from": 262, "to": 315, "color": "#85D38D" },
    { "from": 262, "to": 274, "color": "#85D38D" },
    { "from": 262, "to": 100, "color": "#85D38D" },
    { "from": 28, "to": 102, "color": "#EDC962" },
    { "from": 28, "to": 101, "color": "#EDC962" },
    { "from": 28, "to": 290, "color": "#EDC962" },
    { "from": 28, "to": 38, "color": "#EDC962" },
    { "from": 28, "to": 42, "color": "#EDC962" },
    { "from": 28, "to": 52, "color": "#EDC962" },
    { "from": 28, "to": 277, "color": "#EDC962" },
    { "from": 28, "to": 165, "color": "#EDC962" },
    { "from": 28, "to": 70, "color": "#EDC962" },
    { "from": 28, "to": 241, "color": "#EDC962" },
    { "from": 28, "to": 69, "color": "#EDC962" },
    { "from": 28, "to": 128, "color": "#EDC962" },
    { "from": 28, "to": 291, "color": "#EDC962" },
    { "from": 28, "to": 73, "color": "#EDC962" },
    { "from": 28, "to": 265, "color": "#EDC962" },
    { "from": 28, "to": 100, "color": "#EDC962" },
    { "from": 28, "to": 18, "color": "#EDC962" },
    { "from": 28, "to": 268, "color": "#EDC962" },
    { "from": 28, "to": 171, "color": "#EDC962" },
    { "from": 28, "to": 45, "color": "#EDC962" },
    { "from": 28, "to": 188, "color": "#EDC962" },
    { "from": 28, "to": 295, "color": "#EDC962" },
    { "from": 28, "to": 30, "color": "#EDC962" },
    { "from": 28, "to": 310, "color": "#EDC962" },
    { "from": 28, "to": 249, "color": "#EDC962" },
    { "from": 28, "to": 27, "color": "#EDC962" },
    { "from": 180, "to": 102, "color": "#CBA5E9" },
    { "from": 180, "to": 101, "color": "#CBA5E9" },
    { "from": 259, "to": 102, "color": "#85D38D" },
    { "from": 259, "to": 257, "color": "#85D38D" },
    { "from": 259, "to": 43, "color": "#85D38D" },
    { "from": 259, "to": 298, "color": "#85D38D" },
    { "from": 259, "to": 291, "color": "#85D38D" },
    { "from": 259, "to": 279, "color": "#85D38D" },
    { "from": 259, "to": 141, "color": "#85D38D" },
    { "from": 259, "to": 274, "color": "#85D38D" },
    { "from": 259, "to": 101, "color": "#85D38D" },
    { "from": 259, "to": 243, "color": "#85D38D" },
    { "from": 39, "to": 44, "color": "#EDC962" },
    { "from": 39, "to": 113, "color": "#EDC962" },
    { "from": 39, "to": 112, "color": "#EDC962" },
    { "from": 39, "to": 15, "color": "#EDC962" },
    { "from": 39, "to": 55, "color": "#EDC962" },
    { "from": 39, "to": 18, "color": "#EDC962" },
    { "from": 39, "to": 293, "color": "#EDC962" },
    { "from": 39, "to": 20, "color": "#EDC962" },
    { "from": 39, "to": 41, "color": "#EDC962" },
    { "from": 39, "to": 232, "color": "#EDC962" },
    { "from": 39, "to": 266, "color": "#EDC962" },
    { "from": 39, "to": 301, "color": "#EDC962" },
    { "from": 39, "to": 52, "color": "#EDC962" },
    { "from": 39, "to": 249, "color": "#EDC962" },
    { "from": 39, "to": 45, "color": "#EDC962" },
    { "from": 39, "to": 30, "color": "#EDC962" },
    { "from": 39, "to": 97, "color": "#EDC962" },
    { "from": 39, "to": 284, "color": "#EDC962" },
    { "from": 39, "to": 69, "color": "#EDC962" },
    { "from": 39, "to": 141, "color": "#EDC962" },
    { "from": 39, "to": 139, "color": "#EDC962" },
    { "from": 39, "to": 238, "color": "#EDC962" },
    { "from": 39, "to": 233, "color": "#EDC962" },
    { "from": 39, "to": 36, "color": "#EDC962" },
    { "from": 39, "to": 291, "color": "#EDC962" },
    { "from": 39, "to": 40, "color": "#EDC962" },
    { "from": 88, "to": 102, "color": "#D79B97" },
    { "from": 88, "to": 35, "color": "#D79B97" },
    { "from": 88, "to": 22, "color": "#D79B97" },
    { "from": 88, "to": 87, "color": "#D79B97" },
    { "from": 88, "to": 101, "color": "#D79B97" },
    { "from": 88, "to": 46, "color": "#D79B97" },
    { "from": 88, "to": 63, "color": "#D79B97" },
    { "from": 69, "to": 128, "color": "#D79B97" },
    { "from": 69, "to": 30, "color": "#D79B97" },
    { "from": 69, "to": 290, "color": "#D79B97" },
    { "from": 69, "to": 27, "color": "#D79B97" },
    { "from": 69, "to": 33, "color": "#D79B97" },
    { "from": 69, "to": 40, "color": "#D79B97" },
    { "from": 69, "to": 141, "color": "#D79B97" },
    { "from": 294, "to": 101, "color": "#85D38D" },
    { "from": 294, "to": 63, "color": "#85D38D" },
    { "from": 294, "to": 296, "color": "#85D38D" },
    { "from": 294, "to": 295, "color": "#85D38D" },
    { "from": 59, "to": 7, "color": "#D79B97" },
    { "from": 59, "to": 100, "color": "#D79B97" },
    { "from": 59, "to": 62, "color": "#D79B97" },
    { "from": 59, "to": 52, "color": "#D79B97" },
    { "from": 59, "to": 140, "color": "#D79B97" },
    { "from": 59, "to": 305, "color": "#D79B97" },
    { "from": 59, "to": 265, "color": "#D79B97" },
    { "from": 59, "to": 206, "color": "#D79B97" },
    { "from": 59, "to": 43, "color": "#D79B97" },
    { "from": 59, "to": 279, "color": "#D79B97" },
    { "from": 59, "to": 175, "color": "#D79B97" },
    { "from": 59, "to": 15, "color": "#D79B97" },
    { "from": 59, "to": 63, "color": "#D79B97" },
    { "from": 59, "to": 33, "color": "#D79B97" },
    { "from": 59, "to": 27, "color": "#D79B97" },
    { "from": 59, "to": 61, "color": "#D79B97" },
    { "from": 97, "to": 7, "color": "#D79B97" },
    { "from": 97, "to": 291, "color": "#D79B97" },
    { "from": 97, "to": 102, "color": "#D79B97" },
    { "from": 97, "to": 85, "color": "#D79B97" },
    { "from": 97, "to": 104, "color": "#D79B97" },
    { "from": 97, "to": 260, "color": "#D79B97" },
    { "from": 97, "to": 261, "color": "#D79B97" },
    { "from": 97, "to": 9, "color": "#D79B97" },
    { "from": 97, "to": 18, "color": "#D79B97" },
    { "from": 97, "to": 176, "color": "#D79B97" },
    { "from": 97, "to": 58, "color": "#D79B97" },
    { "from": 97, "to": 313, "color": "#D79B97" },
    { "from": 97, "to": 30, "color": "#D79B97" },
    { "from": 97, "to": 279, "color": "#D79B97" },
    { "from": 97, "to": 38, "color": "#D79B97" },
    { "from": 97, "to": 91, "color": "#D79B97" },
    { "from": 97, "to": 314, "color": "#D79B97" },
    { "from": 97, "to": 56, "color": "#D79B97" },
    { "from": 97, "to": 175, "color": "#D79B97" },
    { "from": 97, "to": 116, "color": "#D79B97" },
    { "from": 97, "to": 265, "color": "#D79B97" },
    { "from": 97, "to": 100, "color": "#D79B97" },
    { "from": 97, "to": 86, "color": "#D79B97" },
    { "from": 97, "to": 277, "color": "#D79B97" },
    { "from": 97, "to": 40, "color": "#D79B97" },
    { "from": 97, "to": 45, "color": "#D79B97" },
    { "from": 97, "to": 232, "color": "#D79B97" },
    { "from": 97, "to": 66, "color": "#D79B97" },
    { "from": 97, "to": 33, "color": "#D79B97" },
    { "from": 97, "to": 41, "color": "#D79B97" },
    { "from": 97, "to": 15, "color": "#D79B97" },
    { "from": 97, "to": 27, "color": "#D79B97" },
    { "from": 97, "to": 171, "color": "#D79B97" },
    { "from": 97, "to": 67, "color": "#D79B97" },
    { "from": 97, "to": 128, "color": "#D79B97" },
    { "from": 97, "to": 14, "color": "#D79B97" },
    { "from": 97, "to": 57, "color": "#D79B97" },
    { "from": 97, "to": 101, "color": "#D79B97" },
    { "from": 293, "to": 7, "color": "#85D38D" },
    { "from": 293, "to": 36, "color": "#85D38D" },
    { "from": 293, "to": 141, "color": "#85D38D" },
    { "from": 293, "to": 189, "color": "#85D38D" },
    { "from": 293, "to": 270, "color": "#85D38D" },
    { "from": 293, "to": 296, "color": "#85D38D" },
    { "from": 293, "to": 158, "color": "#85D38D" },
    { "from": 293, "to": 40, "color": "#85D38D" },
    { "from": 27, "to": 175, "color": "#D79B97" },
    { "from": 27, "to": 102, "color": "#D79B97" },
    { "from": 27, "to": 20, "color": "#D79B97" },
    { "from": 27, "to": 63, "color": "#D79B97" },
    { "from": 27, "to": 141, "color": "#D79B97" },
    { "from": 27, "to": 32, "color": "#D79B97" },
    { "from": 27, "to": 41, "color": "#D79B97" },
    { "from": 27, "to": 33, "color": "#D79B97" },
    { "from": 27, "to": 60, "color": "#D79B97" },
    { "from": 27, "to": 101, "color": "#D79B97" },
    { "from": 27, "to": 87, "color": "#D79B97" },
    { "from": 32, "to": 93, "color": "#D79B97" },
    { "from": 32, "to": 15, "color": "#D79B97" },
    { "from": 32, "to": 41, "color": "#D79B97" },
    { "from": 32, "to": 101, "color": "#D79B97" },
    { "from": 32, "to": 232, "color": "#D79B97" },
    { "from": 32, "to": 175, "color": "#D79B97" },
    { "from": 32, "to": 298, "color": "#D79B97" },
    { "from": 32, "to": 90, "color": "#D79B97" },
    { "from": 32, "to": 249, "color": "#D79B97" },
    { "from": 32, "to": 308, "color": "#D79B97" },
    { "from": 32, "to": 199, "color": "#D79B97" },
    { "from": 32, "to": 140, "color": "#D79B97" },
    { "from": 32, "to": 103, "color": "#D79B97" },
    { "from": 32, "to": 282, "color": "#D79B97" },
    { "from": 32, "to": 213, "color": "#D79B97" },
    { "from": 12, "to": 30, "color": "#D79B97" },
    { "from": 12, "to": 49, "color": "#D79B97" },
    { "from": 12, "to": 15, "color": "#D79B97" },
    { "from": 66, "to": 102, "color": "#EDC962" },
    { "from": 66, "to": 45, "color": "#EDC962" },
    { "from": 66, "to": 74, "color": "#EDC962" },
    { "from": 66, "to": 63, "color": "#EDC962" },
    { "from": 66, "to": 101, "color": "#EDC962" },
    { "from": 66, "to": 78, "color": "#EDC962" },
    { "from": 20, "to": 96, "color": "#EDC962" },
    { "from": 20, "to": 248, "color": "#EDC962" },
    { "from": 20, "to": 45, "color": "#EDC962" },
    { "from": 20, "to": 75, "color": "#EDC962" },
    { "from": 20, "to": 40, "color": "#EDC962" },
    { "from": 63, "to": 310, "color": "#D79B97" },
    { "from": 63, "to": 18, "color": "#D79B97" },
    { "from": 63, "to": 195, "color": "#D79B97" },
    { "from": 63, "to": 48, "color": "#D79B97" },
    { "from": 63, "to": 85, "color": "#D79B97" },
    { "from": 63, "to": 55, "color": "#D79B97" },
    { "from": 63, "to": 248, "color": "#D79B97" },
    { "from": 63, "to": 11, "color": "#D79B97" },
    { "from": 63, "to": 237, "color": "#D79B97" },
    { "from": 63, "to": 30, "color": "#D79B97" },
    { "from": 63, "to": 107, "color": "#D79B97" },
    { "from": 63, "to": 100, "color": "#D79B97" },
    { "from": 63, "to": 291, "color": "#D79B97" },
    { "from": 63, "to": 296, "color": "#D79B97" },
    { "from": 63, "to": 273, "color": "#D79B97" },
    { "from": 63, "to": 113, "color": "#D79B97" },
    { "from": 63, "to": 61, "color": "#D79B97" },
    { "from": 243, "to": 240, "color": "#85D38D" },
    { "from": 243, "to": 274, "color": "#85D38D" },
    { "from": 243, "to": 141, "color": "#85D38D" },
    { "from": 243, "to": 257, "color": "#85D38D" },
    { "from": 243, "to": 270, "color": "#85D38D" },
    { "from": 278, "to": 82, "color": "#85D38D" },
    { "from": 278, "to": 7, "color": "#85D38D" },
    { "from": 278, "to": 43, "color": "#85D38D" },
    { "from": 278, "to": 298, "color": "#85D38D" },
    { "from": 278, "to": 279, "color": "#85D38D" },
    { "from": 274, "to": 241, "color": "#85D38D" },
    { "from": 274, "to": 141, "color": "#85D38D" },
    { "from": 274, "to": 238, "color": "#85D38D" },
    { "from": 274, "to": 257, "color": "#85D38D" },
    { "from": 189, "to": 36, "color": "#CBA5E9" },
    { "from": 189, "to": 310, "color": "#CBA5E9" },
    { "from": 99, "to": 114, "color": "#D79B97" },
    { "from": 114, "to": 174, "color": "#D79B97" },
    { "from": 114, "to": 49, "color": "#D79B97" },
    { "from": 95, "to": 123, "color": "#D79B97" },
    { "from": 95, "to": 54, "color": "#D79B97" },
    { "from": 295, "to": 247, "color": "#85D38D" },
    { "from": 295, "to": 201, "color": "#85D38D" },
    { "from": 295, "to": 242, "color": "#85D38D" },
    { "from": 295, "to": 49, "color": "#85D38D" },
    { "from": 295, "to": 240, "color": "#85D38D" },
    { "from": 44, "to": 101, "color": "#D79B97" },
    { "from": 44, "to": 238, "color": "#D79B97" },
    { "from": 15, "to": 101, "color": "#EDC962" },
    { "from": 15, "to": 232, "color": "#EDC962" },
    { "from": 15, "to": 171, "color": "#EDC962" },
    { "from": 15, "to": 18, "color": "#EDC962" },
    { "from": 15, "to": 290, "color": "#EDC962" },
    { "from": 15, "to": 33, "color": "#EDC962" },
    { "from": 15, "to": 41, "color": "#EDC962" },
    { "from": 15, "to": 23, "color": "#EDC962" },
    { "from": 15, "to": 60, "color": "#EDC962" },
    { "from": 15, "to": 40, "color": "#EDC962" },
    { "from": 23, "to": 101, "color": "#EDC962" },
    { "from": 23, "to": 2, "color": "#EDC962" },
    { "from": 23, "to": 30, "color": "#EDC962" },
    { "from": 23, "to": 45, "color": "#EDC962" },
    { "from": 23, "to": 305, "color": "#EDC962" },
    { "from": 23, "to": 10, "color": "#EDC962" },
    { "from": 23, "to": 314, "color": "#EDC962" },
    { "from": 23, "to": 282, "color": "#EDC962" },
    { "from": 305, "to": 101, "color": "#85D38D" },
    { "from": 305, "to": 238, "color": "#85D38D" },
    { "from": 305, "to": 100, "color": "#85D38D" },
    { "from": 10, "to": 101, "color": "#D79B97" },
    { "from": 10, "to": 55, "color": "#D79B97" },
    { "from": 10, "to": 2, "color": "#D79B97" },
    { "from": 10, "to": 282, "color": "#D79B97" },
    { "from": 232, "to": 18, "color": "#CBA5E9" },
    { "from": 232, "to": 279, "color": "#CBA5E9" },
    { "from": 232, "to": 30, "color": "#CBA5E9" },
    { "from": 232, "to": 43, "color": "#CBA5E9" },
    { "from": 232, "to": 38, "color": "#CBA5E9" },
    { "from": 232, "to": 102, "color": "#CBA5E9" },
    { "from": 232, "to": 291, "color": "#CBA5E9" },
    { "from": 232, "to": 238, "color": "#CBA5E9" },
    { "from": 232, "to": 40, "color": "#CBA5E9" },
    { "from": 232, "to": 288, "color": "#CBA5E9" },
    { "from": 232, "to": 149, "color": "#CBA5E9" },
    { "from": 232, "to": 202, "color": "#CBA5E9" },
    { "from": 75, "to": 62, "color": "#D79B97" },
    { "from": 75, "to": 142, "color": "#D79B97" },
    { "from": 75, "to": 265, "color": "#D79B97" },
    { "from": 75, "to": 128, "color": "#D79B97" },
    { "from": 75, "to": 47, "color": "#D79B97" },
    { "from": 75, "to": 123, "color": "#D79B97" },
    { "from": 191, "to": 263, "color": "#CBA5E9" },
    { "from": 283, "to": 286, "color": "#85D38D" },
    { "from": 283, "to": 54, "color": "#85D38D" },
    { "from": 45, "to": 7, "color": "#D79B97" },
    { "from": 45, "to": 299, "color": "#D79B97" },
    { "from": 45, "to": 141, "color": "#D79B97" },
    { "from": 45, "to": 47, "color": "#D79B97" },
    { "from": 106, "to": 36, "color": "#5FD6E7" },
    { "from": 106, "to": 1, "color": "#5FD6E7" },
    { "from": 106, "to": 116, "color": "#5FD6E7" },
    { "from": 171, "to": 175, "color": "#CBA5E9" },
    { "from": 171, "to": 318, "color": "#CBA5E9" },
    { "from": 87, "to": 96, "color": "#EDC962" },
    { "from": 220, "to": 227, "color": "#CBA5E9" },
    { "from": 220, "to": 226, "color": "#CBA5E9" },
    { "from": 220, "to": 159, "color": "#CBA5E9" },
    { "from": 220, "to": 225, "color": "#CBA5E9" },
    { "from": 220, "to": 127, "color": "#CBA5E9" },
    { "from": 140, "to": 107, "color": "#D79B97" },
    { "from": 140, "to": 238, "color": "#D79B97" },
    { "from": 60, "to": 257, "color": "#EDC962" },
    { "from": 40, "to": 233, "color": "#EDC962" },
    { "from": 40, "to": 113, "color": "#EDC962" },
    { "from": 40, "to": 266, "color": "#EDC962" },
    { "from": 40, "to": 218, "color": "#EDC962" },
    { "from": 40, "to": 91, "color": "#EDC962" },
    { "from": 40, "to": 41, "color": "#EDC962" },
    { "from": 40, "to": 149, "color": "#EDC962" },
    { "from": 40, "to": 249, "color": "#EDC962" },
    { "from": 40, "to": 248, "color": "#EDC962" },
    { "from": 40, "to": 141, "color": "#EDC962" },
    { "from": 40, "to": 291, "color": "#EDC962" },
    { "from": 40, "to": 188, "color": "#EDC962" },
    { "from": 40, "to": 36, "color": "#EDC962" },
    { "from": 314, "to": 46, "color": "#85D38D" },
    { "from": 314, "to": 22, "color": "#85D38D" },
    { "from": 314, "to": 318, "color": "#85D38D" },
    { "from": 314, "to": 302, "color": "#85D38D" },
    { "from": 314, "to": 67, "color": "#85D38D" },
    { "from": 117, "to": 182, "color": "#5FD6E7" },
    { "from": 117, "to": 188, "color": "#5FD6E7" },
    { "from": 117, "to": 227, "color": "#5FD6E7" },
    { "from": 117, "to": 208, "color": "#5FD6E7" },
    { "from": 117, "to": 192, "color": "#5FD6E7" },
    { "from": 209, "to": 167, "color": "#CBA5E9" },
    { "from": 209, "to": 165, "color": "#CBA5E9" },
    { "from": 209, "to": 290, "color": "#CBA5E9" },
    { "from": 213, "to": 200, "color": "#CBA5E9" },
    { "from": 213, "to": 188, "color": "#CBA5E9" },
    { "from": 228, "to": 291, "color": "#CBA5E9" },
    { "from": 228, "to": 107, "color": "#CBA5E9" },
    { "from": 192, "to": 194, "color": "#CBA5E9" },
    { "from": 192, "to": 188, "color": "#CBA5E9" },
    { "from": 192, "to": 182, "color": "#CBA5E9" },
    { "from": 192, "to": 212, "color": "#CBA5E9" },
    { "from": 192, "to": 210, "color": "#CBA5E9" },
    { "from": 192, "to": 179, "color": "#CBA5E9" },
    { "from": 192, "to": 224, "color": "#CBA5E9" },
    { "from": 192, "to": 218, "color": "#CBA5E9" },
    { "from": 192, "to": 227, "color": "#CBA5E9" },
    { "from": 257, "to": 101, "color": "#85D38D" },
    { "from": 257, "to": 141, "color": "#85D38D" },
    { "from": 199, "to": 218, "color": "#CBA5E9" },
    { "from": 199, "to": 224, "color": "#CBA5E9" },
    { "from": 199, "to": 168, "color": "#CBA5E9" },
    { "from": 199, "to": 165, "color": "#CBA5E9" },
    { "from": 141, "to": 112, "color": "#D79B97" },
    { "from": 141, "to": 241, "color": "#D79B97" },
    { "from": 141, "to": 265, "color": "#D79B97" },
    { "from": 141, "to": 113, "color": "#D79B97" },
    { "from": 141, "to": 110, "color": "#D79B97" },
    { "from": 141, "to": 238, "color": "#D79B97" },
    { "from": 141, "to": 273, "color": "#D79B97" },
    { "from": 141, "to": 47, "color": "#D79B97" },
    { "from": 141, "to": 270, "color": "#D79B97" },
    { "from": 141, "to": 146, "color": "#D79B97" },
    { "from": 146, "to": 241, "color": "#D79B97" },
    { "from": 74, "to": 50, "color": "#D79B97" },
    { "from": 310, "to": 122, "color": "#85D38D" },
    { "from": 310, "to": 11, "color": "#85D38D" },
    { "from": 241, "to": 113, "color": "#85D38D" },
    { "from": 241, "to": 110, "color": "#85D38D" },
    { "from": 241, "to": 112, "color": "#85D38D" },
    { "from": 241, "to": 238, "color": "#85D38D" },
    { "from": 113, "to": 238, "color": "#D79B97" },
    { "from": 113, "to": 301, "color": "#D79B97" },
    { "from": 113, "to": 299, "color": "#D79B97" },
    { "from": 22, "to": 311, "color": "#EDC962" },
    { "from": 22, "to": 302, "color": "#EDC962" },
    { "from": 22, "to": 37, "color": "#EDC962" },
    { "from": 49, "to": 69, "color": "#D79B97" },
    { "from": 5, "to": 11, "color": "#D79B97" },
    { "from": 5, "to": 93, "color": "#D79B97" },
    { "from": 5, "to": 171, "color": "#D79B97" },
    { "from": 47, "to": 96, "color": "#D79B97" },
    { "from": 47, "to": 3, "color": "#D79B97" },
    { "from": 25, "to": 29, "color": "#D79B97" },
    { "from": 188, "to": 136, "color": "#CBA5E9" },
    { "from": 188, "to": 8, "color": "#CBA5E9" },
    { "from": 188, "to": 208, "color": "#CBA5E9" },
    { "from": 188, "to": 202, "color": "#CBA5E9" },
    { "from": 315, "to": 291, "color": "#85D38D" },
    { "from": 284, "to": 186, "color": "#85D38D" },
    { "from": 61, "to": 2, "color": "#EDC962" },
    { "from": 91, "to": 249, "color": "#EDC962" },
    { "from": 91, "to": 90, "color": "#EDC962" },
    { "from": 90, "to": 41, "color": "#D79B97" },
    { "from": 90, "to": 282, "color": "#D79B97" },
    { "from": 41, "to": 33, "color": "#D79B97" },
    { "from": 110, "to": 238, "color": "#D79B97" },
    { "from": 202, "to": 236, "color": "#CBA5E9" },
    { "from": 202, "to": 200, "color": "#CBA5E9" },
    { "from": 202, "to": 187, "color": "#CBA5E9" },
    { "from": 238, "to": 112, "color": "#85D38D" },
    { "from": 116, "to": 225, "color": "#5FD6E7" },
    { "from": 116, "to": 165, "color": "#5FD6E7" },
    { "from": 116, "to": 227, "color": "#5FD6E7" },
    { "from": 96, "to": 144, "color": "#D79B97" },
    { "from": 96, "to": 142, "color": "#D79B97" },
    { "from": 265, "to": 100, "color": "#85D38D" },
    { "from": 265, "to": 279, "color": "#85D38D" },
    { "from": 265, "to": 248, "color": "#85D38D" },
    { "from": 46, "to": 308, "color": "#D79B97" },
    { "from": 46, "to": 311, "color": "#D79B97" },
    { "from": 46, "to": 302, "color": "#D79B97" },
    { "from": 46, "to": 22, "color": "#D79B97" },
    { "from": 46, "to": 318, "color": "#D79B97" },
    { "from": 78, "to": 89, "color": "#D79B97" },
    { "from": 78, "to": 142, "color": "#D79B97" },
    { "from": 120, "to": 2, "color": "#5FD6E7" },
    { "from": 282, "to": 64, "color": "#85D38D" },
    { "from": 33, "to": 82, "color": "#EDC962" },
    { "from": 296, "to": 292, "color": "#85D38D" },
    { "from": 296, "to": 306, "color": "#85D38D" },
    { "from": 2, "to": 305, "color": "#D79B97" },
    { "from": 318, "to": 67, "color": "#85D38D" },
    { "from": 14, "to": 84, "color": "#EDC962" },
    { "from": 14, "to": 176, "color": "#EDC962" },
    { "from": 84, "to": 291, "color": "#D79B97" },
    { "from": 48, "to": 107, "color": "#D79B97" },
    { "from": 100, "to": 107, "color": "#D79B97" },
    { "from": 35, "to": 24, "color": "#EDC962" },
    { "from": 167, "to": 203, "color": "#CBA5E9" },
    { "from": 30, "to": 18, "color": "#EDC962" },
    { "from": 311, "to": 67, "color": "#85D38D" },
    { "from": 79, "to": 11, "color": "#D79B97" },
    { "from": 67, "to": 2, "color": "#EDC962" },
    { "from": 182, "to": 210, "color": "#CBA5E9" },
    { "from": 182, "to": 165, "color": "#CBA5E9" },
    { "from": 291, "to": 11, "color": "#85D38D" },
    { "from": 291, "to": 317, "color": "#85D38D" },
    { "from": 291, "to": 119, "color": "#85D38D" },
    { "from": 291, "to": 273, "color": "#85D38D" },
    { "from": 291, "to": 267, "color": "#85D38D" },
    { "from": 123, "to": 114, "color": "#5FD6E7" },
    { "from": 157, "to": 136, "color": "#CBA5E9" },
    { "from": 157, "to": 134, "color": "#CBA5E9" },
    { "from": 157, "to": 208, "color": "#CBA5E9" },
    { "from": 103, "to": 1, "color": "#5FD6E7" },
    { "from": 127, "to": 225, "color": "#5FD6E7" },
    { "from": 127, "to": 138, "color": "#5FD6E7" },
    { "from": 127, "to": 223, "color": "#5FD6E7" },
    { "from": 127, "to": 206, "color": "#5FD6E7" },
    { "from": 127, "to": 205, "color": "#5FD6E7" },
    { "from": 127, "to": 227, "color": "#5FD6E7" },
    { "from": 269, "to": 259, "color": "#85D38D" },
    { "from": 308, "to": 112, "color": "#85D38D" },
    { "from": 203, "to": 165, "color": "#CBA5E9" },
    { "from": 234, "to": 8, "color": "#CBA5E9" },
    { "from": 0, "to": 230, "color": "#007DC4" },
    { "from": 223, "to": 225, "color": "#CBA5E9" },
    { "from": 266, "to": 227, "color": "#85D38D" },
    { "from": 248, "to": 71, "color": "#85D38D" },
    { "from": 248, "to": 291, "color": "#85D38D" },
    { "from": 208, "to": 200, "color": "#CBA5E9" },
    { "from": 208, "to": 207, "color": "#CBA5E9" },
    { "from": 208, "to": 136, "color": "#CBA5E9" },
    { "from": 208, "to": 172, "color": "#CBA5E9" },
    { "from": 208, "to": 134, "color": "#CBA5E9" },
    { "from": 208, "to": 194, "color": "#CBA5E9" },
    { "from": 208, "to": 212, "color": "#CBA5E9" },
    { "from": 208, "to": 224, "color": "#CBA5E9" },
    { "from": 208, "to": 165, "color": "#CBA5E9" },
    { "from": 208, "to": 227, "color": "#CBA5E9" },
    { "from": 165, "to": 193, "color": "#CBA5E9" },
    { "from": 165, "to": 153, "color": "#CBA5E9" },
    { "from": 165, "to": 126, "color": "#CBA5E9" },
    { "from": 165, "to": 172, "color": "#CBA5E9" },
    { "from": 165, "to": 134, "color": "#CBA5E9" },
    { "from": 165, "to": 224, "color": "#CBA5E9" },
    { "from": 165, "to": 107, "color": "#CBA5E9" },
    { "from": 165, "to": 112, "color": "#CBA5E9" },
    { "from": 1, "to": 107, "color": "#D79B97" },
    { "from": 1, "to": 102, "color": "#D79B97" },
    { "from": 1, "to": 104, "color": "#D79B97" },
    { "from": 1, "to": 105, "color": "#D79B97" },
    { "from": 1, "to": 101, "color": "#D79B97" },
    { "from": 119, "to": 107, "color": "#5FD6E7" },
    { "from": 134, "to": 136, "color": "#D79B97" },
    { "from": 134, "to": 227, "color": "#D79B97" },
    { "from": 225, "to": 138, "color": "#CBA5E9" },
    { "from": 225, "to": 205, "color": "#CBA5E9" },
    { "from": 225, "to": 159, "color": "#CBA5E9" },
    { "from": 225, "to": 32, "color": "#CBA5E9" },
    { "from": 225, "to": 224, "color": "#CBA5E9" },
    { "from": 197, "to": 207, "color": "#CBA5E9" },
    { "from": 227, "to": 214, "color": "#CBA5E9" },
    { "from": 227, "to": 153, "color": "#CBA5E9" },
    { "from": 227, "to": 224, "color": "#CBA5E9" },
    { "from": 227, "to": 200, "color": "#CBA5E9" },
    { "from": 227, "to": 207, "color": "#CBA5E9" },
    { "from": 227, "to": 194, "color": "#CBA5E9" },
    { "from": 227, "to": 212, "color": "#CBA5E9" },
    { "from": 118, "to": 107, "color": "#5FD6E7" },
    { "from": 118, "to": 32, "color": "#5FD6E7" },
    { "from": 118, "to": 112, "color": "#5FD6E7" },
    { "from": 118, "to": 291, "color": "#5FD6E7" },
    { "from": 118, "to": 298, "color": "#5FD6E7" },
    { "from": 118, "to": 168, "color": "#5FD6E7" },
    { "from": 206, "to": 224, "color": "#CBA5E9" },
    { "from": 138, "to": 224, "color": "#5FD6E7" },
    { "from": 164, "to": 112, "color": "#CBA5E9" },
    { "from": 164, "to": 88, "color": "#CBA5E9" },
    { "from": 297, "to": 151, "color": "#85D38D" },
    { "from": 130, "to": 43, "color": "#5FD6E7" },
    { "from": 135, "to": 204, "color": "#D79B97" },
    { "from": 52, "to": 41, "color": "#D79B97" }
  ]
}

function PPINetwotkGraph({ jobId }) {
  const dispatch = useDispatch();
  const getPPINetworkGraphResponse = useAppSelector((state) => state.getPPINetworkGraphResponse);
  const [graphData, setGraphData] = useState({});
  const [allNodes, setAllNodes] = useState([]);
  const [allEdges, setAllEdges] = useState([]);
  const [graphParsing, setGraphParsing] = useState(false);
  const [highlightActive, setHighlightActive] = useState(false);
  // let highlightActive = false;
  const networkRef = useRef(null); // Create a reference for the Graph component

  useEffect(() => {
    const request = {
      // job_id: '47f9d2b3-8bc2-4608-b3cf-069e581f091c',
      job_id: jobId,
    };
    dispatch(getPPINetworkGraphAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getPPINetworkGraphResponse.flag) {
      setGraphParsing(true);
      let zero;
      const nodes = [];
      let cnt = 0;
      for (const i of getPPINetworkGraphResponse.data.data.nodes) {
      // for( const i of dummyData.nodes) {
        const title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 28px; padding: 4px; border-radius: 8px">' + '<p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Associated Protein: </p>' + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${i.title}` + '</p>' + '</div>';
        // const title = '';
        const font = {
          size: 25,
          bold: true,
        };
        var obj = { ...i, title, font };
        if (i.id === 0) {
          zero = obj;
          obj = { ...obj, value: 80 };
          // continue;
        } else {
          obj = { ...obj, value: 20 };
        }
        nodes.push(obj);
      }
      const edges = [];
      for (const i of getPPINetworkGraphResponse.data.data.edges) {
        // for (const i of dummyData.edges) {
        const obj = { ...i, color: undefined };
        edges.push(obj);
      }
      setGraphData((prev) => {
        return { nodes: _.cloneDeep(nodes), edges: _.cloneDeep(edges) };
      });
      setAllNodes(nodes);
      setAllEdges(edges);
      setGraphParsing(false);
    }
  }, [JSON.stringify(getPPINetworkGraphResponse)]);

  // const options = {
  //   physics: {
  //     enabled: true,
  //     solver: 'barnesHut', // 'forceAtlas2Based' or 'repulsion' are other options
  //     barnesHut: {
  //       gravitationalConstant: -20000, // More negative for more repulsion
  //       centralGravity: 0.1, // Lower values mean less attraction to the center
  //       springLength: 550, // Desired distance between connected nodes
  //       springConstant: 0.05, // Rigidity of the edges
  //       damping: 0.09, // Reduces the velocity of moving nodes
  //       avoidOverlap: 1, // Prevents overlap
  //     },
  //     stabilization: {
  //       enabled: true,
  //       iterations: 100, // Number of iterations to stabilize
  //       updateInterval: 25,
  //       onlyDynamicEdges: false,
  //       fit: true,
  //     },
  //     timestep: 0.5,
  //     adaptiveTimestep: true,
  //   },
  //   layout: {
  //     improvedLayout: false,
  //   },
  //   interaction: {
  //     zoomView: false,
  //     selectable: true,
  //   },
  //   edges: {
  //     // color: 'red',
  //     width: 2,
  //     color: { inherit: "from" },
  //     smooth: {
  //       type: "continuous",
  //     },
  //     arrows: {
  //       to: {
  //         enabled: false,
  //       },
  //       middle: {
  //         enabled: false,
  //       },
  //       from: {
  //         enabled: false,
  //       },
  //     },
  //   },
  //   nodes: {
  //     shape: 'dot',
  //     scaling: {
  //       // label: {
  //       //   enabled: false,
  //       //   min: 25,
  //       // },
  //       customScalingFunction(min, max, total, value) {
  //         return ((value * 10) / total) * 2.25;
  //       },
  //       min: 20,
  //       max: 150,
  //     },
  //   },
  //   height: '90%',
  //   width: '100%',
  // };

  var options = {
    physics: {
      enabled: false,
      solver: 'barnesHut',
      barnesHut: {
        gravitationalConstant: -2000, // More negative for more repulsion
        centralGravity: 0.05, // Lower values mean less attraction to the center
        springLength: 750, // Desired distance between connected nodes
        springConstant: 0.005, // Rigidity of the edges
        damping: 0.09, // Reduces the velocity of moving nodes
        avoidOverlap: 1, // Prevents overlap
      },
      stabilization: {
        enabled: true,
        iterations: 150, // Number of iterations to stabilize
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    // nodes: {
    //   shape: 'dot',
    //   scaling: {
    //     min: 10,
    //     max: 30,
    //     label: {
    //       min: 8,
    //       max: 30,
    //       drawThreshold: 12,
    //       maxVisible: 20,
    //     },
    //   },
    //   font: {
    //     size: 12,
    //     face: 'Tahoma',
    //   },
    // },
    nodes: {
      shape: 'dot',
      scaling: {
        label: {
          enabled: false,
          min: 35,
          // drawThreshold: 12,
          // maxVisible: 20,
        },
        customScalingFunction(min, max, total, value) {
          console.log(value,total)
          return ((value * 10) / total) * 2.25;
        },
        min: 20,
        max: 150,
      },
    },
    edges: {
      width: 0.15,
      color: { inherit: 'from' },
      arrows: {
        to: {
          enabled: false,
        },
        middle: {
          enabled: false,
        },
        from: {
          enabled: false,
        },
      },
      smooth: {
        type: 'continuous',
      },
    },
    layout: {
      improvedLayout: false,
    },
    interaction: {
      tooltipDelay: 200,
      // hideEdgesOnDrag: true,
      // hideEdgesOnZoom: true,
    },
  };

  // function neighbourhoodHighlight(params) {
  //   // if something is selected:
  //   const network = networkRef.current.Network;
  //   if (params.nodes.length > 0) {
  //     highlightActive = true;
  //     var i, j;
  //     var selectedNode = params.nodes[0];
  //     var degrees = 2;

  //     // mark all nodes as hard to read.
  //     for (var nodeId in allNodes) {
  //       allNodes[nodeId].color = 'rgba(200,200,200,0.5)';
  //       if (allNodes[nodeId].hiddenLabel === undefined) {
  //         allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
  //         allNodes[nodeId].label = undefined;
  //       }
  //     }
  //     var connectedNodes = network.getConnectedNodes(selectedNode);
  //     var allConnectedNodes = [];

  //     // get the second degree nodes
  //     for (i = 1; i < degrees; i++) {
  //       for (j = 0; j < connectedNodes.length; j++) {
  //         allConnectedNodes = allConnectedNodes.concat(network.getConnectedNodes(connectedNodes[j]));
  //       }
  //     }

  //     // all second degree nodes get a different color and their label back
  //     console.log('allConnectedNodes', allConnectedNodes);

  //     for (i = 0; i < allConnectedNodes.length; i++) {
  //       if (allNodes[allConnectedNodes[i]] !== undefined) {
  //         allNodes[allConnectedNodes[i]].color = 'rgba(150,150,150,0.75)';
  //         if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
  //           allNodes[allConnectedNodes[i]].label = allNodes[allConnectedNodes[i]].hiddenLabel;
  //           allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
  //         }
  //       }
  //     }

  //     // all first degree nodes get their own color and their label back
  //     for (i = 0; i < connectedNodes.length; i++) {
  //       allNodes[connectedNodes[i]].color = undefined;
  //       if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
  //         allNodes[connectedNodes[i]].label = allNodes[connectedNodes[i]].hiddenLabel;
  //         allNodes[connectedNodes[i]].hiddenLabel = undefined;
  //       }
  //     }

  //     // the main node gets its own color and its label back.
  //     allNodes[selectedNode].color = undefined;
  //     if (allNodes[selectedNode].hiddenLabel !== undefined) {
  //       allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
  //       allNodes[selectedNode].hiddenLabel = undefined;
  //     }
  //   } else if (highlightActive === true) {
  //     // reset all nodes
  //     for (var nodeId in allNodes) {
  //       allNodes[nodeId].color = undefined;
  //       if (allNodes[nodeId].hiddenLabel !== undefined) {
  //         allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
  //         allNodes[nodeId].hiddenLabel = undefined;
  //       }
  //     }
  //     highlightActive = false;
  //   }

  //   // transform the object into an array
  //   var updateArray = [];
  //   for (nodeId in allNodes) {
  //     if (allNodes.hasOwnProperty(nodeId)) {
  //       updateArray.push(allNodes[nodeId]);
  //     }
  //   }
  //   // nodesDataset.update(updateArray);
  //   setGraphData((prev) => ({
  //     ...prev,
  //     nodes: updateArray,
  //   }));
  // }

  function resetNodeColorsAndLabels() {
    for (const nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        allNodes[nodeId].color = undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
    }
  }

  function neighbourhoodHighlight(params) {
    const network = networkRef.current.Network;

    if (params.nodes.length > 0) {
      // highlightActive = true;
      console.log('params',params)
      var i, j;
      var selectedNode = params.nodes[0];
      console.log('selectedNode', selectedNode);

      var degrees = 1;

      // mark all nodes as hard to read.
      for (var nodeId in allNodes) {

        if (allNodes[nodeId].hiddenColor === undefined) {
          allNodes[nodeId].hiddenColor = allNodes[nodeId].color;
        }
        allNodes[nodeId].color = 'rgba(200,200,200,0.1)';
        if (allNodes[nodeId].hiddenLabel === undefined) {
          allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
          allNodes[nodeId].label = undefined;
        }
      }
      var connectedNodes = network.getConnectedNodes(selectedNode);

      var allConnectedNodes = [];

      // get the second degree nodes
      // for (i = 1; i < degrees; i++) {
      //   for (j = 0; j < connectedNodes.length; j++) {
      //     allConnectedNodes = allConnectedNodes.concat(
      //       network.getConnectedNodes(connectedNodes[j])
      //     );
      //   }
      // }

      // all second degree nodes get a different color and their label back
      // for (i = 0; i < allConnectedNodes.length; i++) {
      //   allNodes[allConnectedNodes[i]].color = "rgba(150,150,150,0.75)";
      //   if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
      //     allNodes[allConnectedNodes[i]].label =
      //       allNodes[allConnectedNodes[i]].hiddenLabel;
      //     allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
      //   }
      // }

      // all first degree nodes get their own color and their label back
      for (i = 0; i < connectedNodes.length; i++) {
        if (allNodes[connectedNodes[i]]) {
          allNodes[connectedNodes[i]].color = allNodes[connectedNodes[i]].hiddenColor ?? undefined;
          if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
            allNodes[connectedNodes[i]].label = allNodes[connectedNodes[i]].hiddenLabel;
            allNodes[connectedNodes[i]].hiddenLabel = undefined;
          }
        }
      }

      // the main node gets its own color and its label back.
      allNodes[selectedNode].color = allNodes[selectedNode].hiddenColor ?? undefined;
      if (allNodes[selectedNode].hiddenLabel !== undefined) {
        allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
        allNodes[selectedNode].hiddenLabel = undefined;
      }
      setHighlightActive(true);
    } else if (highlightActive === true) {
      // reset all nodes

      for (var nodeId in allNodes) {
        allNodes[nodeId].color = allNodes[nodeId].hiddenColor ?? undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
      setHighlightActive(false);
      // highlightActive = false;
    }

    // transform the object into an array
    var updateArray = [];
    for (nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        updateArray.push(allNodes[nodeId]);
      }
    }
    // nodesDataset.update(updateArray);
    // network.redraw();
    setGraphData((prev) => ({
      ...prev,
      nodes: _.cloneDeep(updateArray),
      // nodes: [],
    }));
  }

  useEffect(() => {
    if (networkRef.current) {
      const network = networkRef.current.Network;

      // network.on('selectNode', (params) => {
      //   const nodeId = params.nodes[0];
      //   const connectedEdges = network.getConnectedEdges(nodeId);

      //   connectedEdges.forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 15 }); // Increase width of the edge
      //   });
      // });
      // network.on('selectEdge', neighbourhoodHighlight);
      // network.on('click', neighbourhoodHighlight);
      // network.on('deselectNode', (params) => {
      //   const { edges } = network.body;

      //   Object.keys(edges).forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 2 }); // Reset width of the edge
      //   });
      // });
      // You can now access the network object and call its methods
    }
  }, [graphData]);

  return (
    <Loader loading={getPPINetworkGraphResponse.loading || Object.keys(graphData).length === 0 || graphParsing}>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph" style={{ width: '100%', height: '800px' }}>
        <div className="card-content-ctp-title">PPI Network Graph and 1st - 2nd Degree Associations</div>
        {graphData ? (
          <>
            <div className="pathway-enrichment-graph-header">
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#CBA5E9', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Molecular functions Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#EDC962', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Disease Similarity Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#5FD6E7', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                PPIs Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#85D38D', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Literature Mining Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#D79B97', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px' }}>
                Common Protein
              </div>
            </div>
            <Graph ref={networkRef} graph={graphData} options={options} events={{ select: neighbourhoodHighlight }} />
          </>
        ) : (
          <Loader loading={graphParsing} />
        )}
      </div>
    </Loader>
  );
}

PPINetwotkGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default PPINetwotkGraph;
