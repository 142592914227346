/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { Upload, Input } from 'antd';
import SvgLoader from '../../../../common/components/SvgLoader';
import Mixpanel from '../../../../utils/mixpanel';

function InputBoxOncoTagging({
  inputHandler,
  isDisabled,
  serviceName,
  setFormData,
}) {
  const { TextArea } = Input;
  const [inputValue, setInputValue] = useState();
  const [dataset, setDataset] = useState([1, 0]);
  const inputStyles = [
    'Textual Input',
    'Upload a Document',
  ];
  const [isFileSelected, setIsFileSelected] = useState(false);

  const onInputFileChange = (fileList) => {
    if (fileList?.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files?.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      setIsFileSelected(true);
      setFormData(formData);
    }
    // change below after api integration
  };

  const handleDatasetChange = (arr) => {
    setDataset(arr);
    let value = {};
    if (arr[0] === 1) {
      setIsFileSelected(false);
      setFormData(null);
      value = { btn: inputStyles[0] };
    } else {
      value = { btn: inputStyles[1] };
    }
    inputHandler(value);
    Mixpanel.track('Ontology Tagging input style selected', {
      action: 'Ontology Tagging Input style selected',
      input_style: value.btn,
      service: 'Clinical & Commercial Analysis',
    });
  };

  const downloadTemplateFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = `${process.env.apiUrl_utilities}/templateDownload`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      const outputFilename = 'drug_discovery_utilities_input_template.xlsx';

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,
    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setFormData(null);
    },
  };

  useEffect(() => {
    const splits = inputValue?.split('\n');
    // if (splits?.length <= 5) {
    const inputs = {
      inputText: splits?.join(','),
    };
    inputHandler(inputs);
    // }
  }, [inputValue]);

  // useEffect(() => {
  //   inputStyles.forEach((item, index) => {
  //     if (item === inputStyle) {
  //       setDataset((prev) => {
  //         const newDataset = prev;
  //         newDataset[index] = 1;
  //         return newDataset;
  //       });
  //       inputHandler({ dataset: inputStyles[index] });
  //     }
  //   });
  // }, []);

  const handleInputChange = (e) => {
    if (e !== undefined) {
      // if ((e.match(/\n/g) || []).length < 5) {
      setInputValue(e);
      // }
    }
  };

  return (
    <div className="provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Select Input Style</div>
        <div className="input-fields">
          <div className="select-dataset">
            <div className="select-dataset-footer">
              <div className="select-dataset-footer-btn">
                <button
                  type="button"
                  className={`select-button ${dataset[0] === 1 ? 'active' : ''}`}
                  onClick={() => handleDatasetChange([1, 0])}
                >
                  Textual Input
                </button>
              </div>

              <div className="select-dataset-footer-btn">
                <button
                  type="button"
                  className={`select-button ${dataset[1] === 1 ? 'active' : serviceName === 'Ontology ID Association' ? 'disabled' : ''}`} 
                  disabled={serviceName === 'Ontology ID Association'}
                  onClick={() => handleDatasetChange([0, 1])}
                >
                  Upload a Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="provide-input-sec">
          <div className="pro-input-title">
            <div className="input-container-title">Provide Input</div>
            {/* <div className="input-fields-value try-example-section">
              <text
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => {
                  // setIndication('Lung Cancer');
                  // if (!isDisabled) {
                  //   setInputValue('CC1=C(C(C(=C(N1)C)C(=O)OCCN2CCN(CC2)C(C3=CC=CC=C3)C4=CC=CC=C4)C5=CC(=CC=C5)[N+](=O)[O-])C(=O)OC');
                  // }
                  // setDefaultIndication('Lung Cancer');
                }}
              >
                Try Example
              </text>
            </div> */}
          </div>
          <div className="input-fields">
            <div className="input-fields-value">
              {(dataset[0] === 1 || dataset[0] === -1) && (
                <TextArea
                  rows={8}
                  disabled={isDisabled}
                  value={inputValue}
                  placeholder={'Enter Gene names, Proteins, Drugs or Diseases here\nLine 1: Gene 1, Protein 1, Drug 1 or Diseases 1\nLine 2: Gene 2, Protein 2, Drug 2 or Diseases 2\nLine 3: Gene 3, Protein 3, Drug 3 or Diseases 3'}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              )}
              {dataset[1] === 1 && (
                <div
                  className={`input-fields-upload${isFileSelected ? ' upload-file-item' : ''}`}
                >
                  <Upload {...props}>
                    {!isFileSelected && (
                    <p className="ant-upload-drag-icon">
                      <SvgLoader
                        width={216}
                        height={72}
                        svgName="upload-file"
                      />
                    </p>
                    )}
                    {!isFileSelected && (
                    <p className="ant-upload-text">
                      <span className="ant-upload-text-grey">
                        Drag & drop indications file or
                      </span>
                      <span className="ant-upload-text-blue">Browse</span>
                    </p>
                    )}
                    {!isFileSelected && (
                    <p className="ant-upload-hint">
                      (Acceptable file formats are .xlsx or .csv)
                    </p>
                    )}
                    {!isFileSelected && (
                    <p className="ant-upload-text">
                      <span className="ant-upload-text-grey">
                        For reference
                      </span>
                      <span
                        className="ant-upload-text-blue"
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadTemplateFile();
                        }}
                        aria-hidden
                      >
                        Download a template
                      </span>
                    </p>
                    )}
                  </Upload>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBoxOncoTagging.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  inputStyle: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
  setInputStyle: PropTypes.func.isRequired,
};

export default InputBoxOncoTagging;
