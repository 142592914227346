import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import InputDropdown from '../../components/InputDropdownV2';
import Mixpanel from '../../../../utils/mixpanel';
import AdvanceSearchView from '../AdvanceSearchView';
import { getInputSynonymsAction, getInputSynonymsResetAction } from '../../pages/IEPInputPage/logic';

function InputBoxTopIndications({
  inputHandler, isDisabled, serviceName,
}) {
  const dispatch = useDispatch();
  const [synonymsList, setSynonymsList] = useState([]);
  const [proteinList, setProteinList] = useState([]);

  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);

  useEffect(() => {
    if (!isAdvanceSearch) inputHandler({ proteins: synonymsList });
  }, [synonymsList]);

  return (
    <div className="provide-input-container dc-provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="dropdown-container">
            <InputDropdown index={0} proteinList={proteinList} isDisabled={isDisabled} setProteinList={setProteinList} setSynonymsList={setSynonymsList} />
            <div className="try-example-row">
              <div className="input-fields-value try-example-section">
                <text className="try-example-section-title">Try Example: </text>
                <text
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => {
                    Mixpanel.track('IEP try example clicked', {
                      action: 'IEP Try example at Input page clicked',
                      selected_protein: 'DLL3',
                      service: 'Prioritize Top Indications',
                    });
                    // updateProteinItem({ gene_name: 'DLL3', uid: 'PROT_ID_9606_a39ee0dbc72871f2d3c39685fb730753b47045f1' });
                    setProteinList((prev) => [...prev, 'DLL3']);
                    const request = {
                      protein_name: 'DLL3',
                      uid: 'PROT_ID_9606_a39ee0dbc72871f2d3c39685fb730753b47045f1',
                    };
                    dispatch(getInputSynonymsAction(queryString.stringify(request)));
                  }}
                >
                  DLL3
                </text>
              </div>
              <div className="advance-search">
                <div
                  className="advance-search-txt"
                  onClick={() => {
                    setIsAdvanceSearch(true);
                    inputHandler({ proteins: [] });
                    dispatch(getInputSynonymsResetAction());
                    Mixpanel.track('IEP advance search clicked', {
                      action: 'IEP Advance search at Input page clicked',
                      service: 'Prioritize Top Indications',
                    });
                  }}
                  aria-hidden
                  style={{ cursor: 'pointer', color: 'blue' }}
                >
                  Advance Search
                </div>
              </div>
              <AdvanceSearchView isOpen={isAdvanceSearch} setIsAdvanceSearch={setIsAdvanceSearch} serviceName={serviceName} isAdvanceSearch={isAdvanceSearch} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBoxTopIndications.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  serviceName: PropTypes.string.isRequired,
};

export default InputBoxTopIndications;
