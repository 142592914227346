/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import * as d3 from 'd3';
import {
  GeoJSON,
  // TileLayer,
  MapContainer,
  Map as WorldMap,
  Tooltip,
  CircleMarker,
  TileLayer,
  Popup,
} from 'react-leaflet';
import { geoCtAction, geoCtDetailsAction, geoCtStatesAction, geoKolAction, geoKolDetailsAction, geoKolStatesAction } from './logic';
import { getCenterOfGeoJson } from './MapUtils';
import worldData from './worlddata.json';
import './leaflet.scss';
import useAppSelector from '../../../../../utils/redux-hook';
import Loader from '../../../../../common/components/Loader';
import { ColorLegend } from './ColorLegend';
import getCountryData from './GetJsonData';
import { Button } from 'antd';

function GlobalMap({ indicationName, type, geoJsonId, setGeoJsonId }) {
  const dispatch = useDispatch();
  const geoCtData = useAppSelector((state) => state.geoCtResponse);
  const geoCtStatesData = useAppSelector((state) => state.geoCtStatesResponse);
  const geoCtDetailsData = useAppSelector((state) => state.geoCtDetailsResponse);
  const geoKolData = useAppSelector((state) => state.geoKolResponse);
  const geoKolStatesData = useAppSelector((state) => state.geoKolStatesResponse);
  const geoKolDetailsData = useAppSelector((state) => state.geoKolDetailsResponse);
  const [countriesCtData, setCountriesCtData] = useState([]);
  const [countriesKolData, setCountriesKolData] = useState([]);

  const [valueRange, setValueRange] = useState({ min: 2, max: undefined });
  const popupData = useRef([]);
  const markerRef = useRef();
  const COUNTRY_VIEW_ID = 'worlddata';

  const [mapData, setMapData] = useState(getCountryData(COUNTRY_VIEW_ID));

  const bounds = [
    [-90, -180],
    [90, 180],
  ];

  const mapOptions = {
    worldCopyJump: false, // Prevent map wrapping
    maxBounds: bounds, // Set the map bounds
    maxBoundsViscosity: 1.0, // Enforce the bounds strictly
    zoomSnap: 0.1,
  };
  const mapCenter = getCenterOfGeoJson(worldData);
  const mapRef = useRef(null);
  const geoJsonRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && geoJsonRef.current) {
      mapRef.current?.leafletElement?.fitBounds(geoJsonRef.current?.leafletElement?.getBounds());
    }
  });

  useEffect(() => {
    // make indication dynamic
    if (type === 'sites') {
      if (geoJsonId === 'worlddata') {
        dispatch(
          geoCtAction(
            queryString.stringify({
              indication_name: indicationName,
            }),
          ),
        );
      } else {
        dispatch(
          geoCtStatesAction(
            queryString.stringify({
              indication_name: indicationName,
              country_name: geoJsonId,
            }),
          ),
        );
      }
    }
    if (type === 'kols') {
      if (geoJsonId === 'worlddata') {
        dispatch(
          geoKolAction(
            queryString.stringify({
              indication_name: indicationName,
            }),
          ),
        );
      } else {
        dispatch(
          geoKolStatesAction(
            queryString.stringify({
              indication_name: indicationName,
              country_name: geoJsonId,
            }),
          ),
        );
      }
    }
  }, [type, indicationName]);

  useEffect(() => {
    if (geoCtData.flag) {
      popupData.current = [];
      if (geoCtData?.data?.countries) {
        setCountriesCtData(geoCtData?.data?.countries);
      }
      const countryData = worldData.features;
      countryData.map((e) => {
        if (Object.keys(geoCtData?.data?.countries).includes(e?.properties?.NAME_1)) {
          if (geoCtData?.data?.countries[e?.properties?.NAME_1]?.sites_count) {
            popupData.current.push({ ...geoCtData?.data?.countries[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoCtData?.data?.max_count) {
        setValueRange({ max: geoCtData?.data?.max_count, min: geoCtData?.data?.min_count });
      }
    }
  }, [geoCtData]);

  useEffect(() => {
    if (geoCtStatesData.flag) {
      popupData.current = [];
      if (geoCtStatesData?.data?.states) {
        setCountriesCtData(geoCtStatesData?.data?.states);
      }
      const countryData = mapData.features;
      countryData.map((e) => {
        if (Object.keys(geoCtStatesData?.data?.states).includes(e?.properties?.NAME_1)) {
          if (geoCtStatesData?.data?.states[e?.properties?.NAME_1]?.sites_count) {
            popupData.current.push({ ...geoCtStatesData?.data?.states[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoCtStatesData?.data?.max_count) {
        setValueRange({ max: geoCtStatesData?.data?.max_count, min: geoCtStatesData?.data?.min_count });
      }
    }
  }, [geoCtStatesData]);

  useEffect(() => {
    if (geoKolData.flag) {
      popupData.current = [];
      if (geoKolData?.data?.countries) {
        setCountriesKolData(geoKolData?.data?.countries);
      }
      const countryData = worldData.features;
      countryData.map((e) => {
        if (Object.keys(geoKolData?.data?.countries).includes(e?.properties?.NAME_1)) {
          if (geoKolData?.data?.countries[e?.properties?.NAME_1]?.sites_count) {
            popupData.current.push({ ...geoKolData?.data?.countries[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoKolData?.data?.max_count) {
        setValueRange({ max: geoKolData?.data?.max_count, min: geoKolData?.data?.min_count });
      }
    }
  }, [geoKolData]);

  useEffect(() => {
    if (geoKolStatesData.flag) {
      popupData.current = [];
      if (geoKolStatesData?.data?.states) {
        setCountriesKolData(geoKolStatesData?.data?.states);
      }
      const countryData = mapData.features;
      countryData.map((e) => {
        if (Object.keys(geoKolStatesData?.data?.states).includes(e?.properties?.NAME_1)) {
          if (geoKolStatesData?.data?.states[e?.properties?.NAME_1]?.kol_count) {
            popupData.current.push({ ...geoKolStatesData?.data?.states[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoKolStatesData?.data?.max_count) {
        setValueRange({ max: geoKolStatesData?.data?.max_count, min: geoKolStatesData?.data?.min_count });
      }
    }
  }, [geoKolStatesData]);

  function getColor(d) {
    // const obj = rangeObj.find((itm) => ((itm.lowerBound <= d) && (d <= itm.lowerBound)))
    if (d < 1) return '#CDDCFB';
    if (d < 100) return '#93B2F0';
    if (d > 100) return '#3B76EF';
    return '#CDDCFB';
  }
  // const processName = (e) => e.toLowerCase().replaceAll(' ', '').replaceAll('-', '');

  function geoJSONStyle(e) {
    if (type === 'sites') {
      if (Object.keys(countriesCtData).includes(e?.properties?.NAME_1)) {
        return {
          // color: '#1f2021',
          weight: 1,
          fillOpacity: 0.7,
          fillColor: getColor(countriesCtData[e?.properties?.NAME_1]?.sites_count),
        };
      }
    }
    if (type === 'kols') {
      if (Object.keys(countriesKolData).includes(e?.properties?.NAME_1)) {
        return {
          // color: '#1f2021',
          weight: 1,
          fillOpacity: 0.7,
          fillColor: getColor(countriesKolData[e?.properties?.NAME_1]?.sites_count),
        };
      }
    }
    return {
      // color: '#CDDCFB',
      weight: 1,
      // fillOpacity: 0,
      fillOpacity: 0.7,
      fillColor: '#3B76EF',
    };
  }

  const noDataFound = () => {
    return (
      <div className="no-data-found" style={{ color: '#000' }}>
        No data found
      </div>
    );
  };

  const popUpData = (itm) => {
    console.log(geoJsonId, 'geoJsonId');
    if (geoJsonId === 'worlddata') {
      return (
        <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
          <div className="popUp-first" tabIndex={0} role="button" onKeyDown={null}>
            <div className="popup-container">
              <div className="popup-country-name">{itm.country}</div>
              <div className="popup-count-section">
                <div className="popup-count-number">{type === 'sites' ? itm.sites_count : itm.kol_count}</div>
                <div className="popup-count-text">{type === 'sites' ? 'Sites' : 'KOLs'}</div>
              </div>
              <div className="popup-count-section">
                <div className="popup-count-number">{itm?.trial_count}</div>
                <div className="popup-count-text">Trials</div>
              </div>
              <div
                className="popup-explore"
                onClick={(e) => {
                  e.stopPropagation();
                  onDrillDown(itm);
                }}
              >
                Explore
              </div>
            </div>
          </div>
        </Popup>
      );
    }
    return (
      (type === 'sites') ?
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
        <div className="popUp-first" tabIndex={0} role="button" onKeyDown={null}>
          <Loader loading={geoCtDetailsData.loading} height={300}>
            <div className="popup-details-container">
              {geoCtDetailsData?.data?.sites
                ? geoCtDetailsData?.data?.sites?.map((item, index) => (
                    <div className="popup-details-section">
                      <div className="popup-details-number">{index + 1}.</div>
                      <div className="popup-details-section-data">
                        <div className="popup-details-top">
                          <div className="popup-details-name">{item?.site_data?.insitute?.name}</div>
                        </div>
                        <div className="popup-details-bottom">
                          <span>Total trials</span>
                          <div className="popup-details-count">{item?.trial_count}</div>
                        </div>
                      </div>
                    </div>
                  ))
                : noDataFound()}
            </div>
          </Loader>
        </div>
      </Popup>
      : 
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
        <div className="popUp-first" tabIndex={0} role="button" onKeyDown={null}>
          <Loader loading={geoKolDetailsData.loading} height={300}>
            <div className="popup-details-container">
              {geoKolDetailsData?.data
                ? geoKolDetailsData?.data?.map((item, index) => (
                    <div className="popup-details-section">
                      <div className="popup-details-number">{index + 1}.</div>
                      <div className="popup-details-section-data">
                        <div className="popup-details-top">
                          <div className="popup-details-name">{item?.kol_name}</div>
                        </div>
                        <div className="popup-details-bottom">
                          <span>Total trials</span>
                          <div className="popup-details-count">{item?.total_count}</div>
                        </div>
                      </div>
                    </div>
                  ))
                : noDataFound()}
            </div>
          </Loader>
        </div>
      </Popup>
    );
  };

  const colorScale = d3.scaleLinear().domain([valueRange?.min, valueRange?.max]).range(['#CDDCFB', '#3B76EF']);

  function onEachFeature(e, layer) {
    // const layerUtils = layersUtils(geoJsonRef, mapRef);
    layer.on({
      // mouseover: layerUtils.highlightOnClick,
      // mouseout: layerUtils.resetHighlight,
      click: onDrillDown,
    });
  }

  const processName = (e) => {
    return e.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
  };

  const handlebackClicked = () => {
    popupData.current = [];
    if (type === 'sites') {
      dispatch(
        geoCtAction(
          queryString.stringify({
            indication_name: indicationName,
          }),
        ),
      );
    }
    if (type === 'kols') {
      dispatch(
        geoKolAction(
          queryString.stringify({
            indication_name: indicationName,
          }),
        ),
      );
    }
    setGeoJsonId('worlddata');
    setMapData(getCountryData('worlddata'));
  };

  const onDrillDown = (e) => {
    popupData.current = [];
    if (getCountryData(processName(e.country))) {
      if (type === 'sites') {
        dispatch(
          geoCtStatesAction(
            queryString.stringify({
              indication_name: indicationName,
              country_name: e.country,
            }),
          ),
        );
      } else if (type === 'kols') {
        dispatch(
          geoKolStatesAction(
            queryString.stringify({
              indication_name: indicationName,
              country_name: e.country,
            }),
          ),
        );
      }
      setMapData(getCountryData(processName(e.country)));
      setGeoJsonId(e.country);
    }
  };
  return (
    <Loader loading={geoCtData.loading || geoKolData.loading || geoCtStatesData.loading || geoKolStatesData.loading}>
      <div className="map-container">
      {geoJsonId !== 'worlddata' && (
            // <div className="map-back-screen-button">
            //   <Button
            //     className="back-btn"
            //     id="map-back-btn"
            //     type="primary"
            //     onClick={(e) => {
            //       e.preventDefault();
            //       handlebackClicked();
            //     }}
            //   >
            //     Back
            //   </Button>
            // </div>
            <div className="back-btn map-back-screen-button">
            <Button className="back-btn-icon" 
                onClick={(e) => {
                  e.preventDefault();
                  handlebackClicked();
                }}>
              Back to Country View
            </Button>
          </div>
          )}
        <WorldMap className="map" center={mapCenter} zoom={2} minZoom={geoJsonId === 'worlddata' ? 2 : 3} ref={mapRef} zoomControl={false} {...mapOptions}>
          {/* <TileLayer
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            // attribution= downloadPreviewModalOpen ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            minZoom={2}
            maxZoom={19}
          /> */}
          <GeoJSON
            data={mapData}
            key={geoJsonId}
            style={(e) => geoJSONStyle(e)}
            // onEachFeature={geoJsonId  === 'worlddata' ? onEachFeature : null}
            ref={geoJsonRef}
            id={geoJsonId}
          />
          
          {popupData.current.length
            ? popupData?.current.map(
                (item) =>
                  (item?.sites_count || item?.kol_count) && (
                    <CircleMarker
                      center={item.center}
                      markerRef={markerRef}
                      fillOpacity={0.5}
                      className="circle-marker-map"
                      radius={7}
                      key={type === 'sites' ? item?.sites_count : item?.kol_count}
                      onClick={(e) => {
                        console.log('e', e);
                        if (geoJsonId === 'worlddata') return;
                        if (type === 'sites') {
                          dispatch(
                            geoCtDetailsAction(
                              queryString.stringify({
                                indication_name: indicationName,
                                country_name: geoJsonId,
                                state_name: item.country,
                              }),
                            ),
                          );
                        }
                        if (type === 'kols') {
                          dispatch(
                            geoKolDetailsAction(
                              queryString.stringify({
                                indication_name: indicationName,
                                country_name: geoJsonId,
                                state_name: item.country,
                              }),
                            ),
                          );
                        }
                      }}
                    >
                      <Tooltip direction="top" permanent interactive className="run-analysis-map-tooltip">
                        <b>{type === 'sites' ? item?.sites_count : item?.kol_count}</b>
                      </Tooltip>
                      {(type === 'sites' ? item?.sites_count : item?.kol_count) && popUpData(item)}
                    </CircleMarker>
                  ),
              )
            : null}
          <div className="color-legend-container">
            <div className="n-number-section">
              <div className="n-number-section-icon" />
              <div className="n-number-section-text">
                {' '}
                - Number of <div className="n-number-section-text-com"> {`Clinical Trial ${type}`}</div>
              </div>
            </div>
            <ColorLegend width={400} height={100} colorScale={colorScale} />
          </div>
        </WorldMap>
      </div>
      )
    </Loader>
  );
}

GlobalMap.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default GlobalMap;
