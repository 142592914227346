import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getInputSuggestAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';
import { trackOutSideEvents } from '../../../../utils';
import { getInputSynonymsAction } from '../../pages/IEPInputPage/logic';

function InputDropdown({
  proteinList, isDisabled, setProteinList, setSynonymsList,
}) {
  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const getInputSuggestResponse = useAppSelector((reduxState) => reduxState.getInputSuggestResponse || {});
  const getInputSynonymsResponse = useAppSelector((state) => state.getInputSynonymsResponse);

  useEffect(() => {
    if (searchBoxRef.current) {
      trackOutSideEvents(searchBoxRef, () => {
        setSearchText('');
        setToggle(false);
        setDisplayDropdown(false);
      });
    }
  }, [searchBoxRef]);

  useEffect(() => {
    if (getInputSuggestResponse.flag) {
      setAutosuggestOptions(getInputSuggestResponse.data);
    }
  }, [getInputSuggestResponse]);

  useEffect(() => {
    if (getInputSynonymsResponse.flag && !getInputSynonymsResponse.loading) {
      setSynonymsList((prev) => [...prev, { protein_name: getInputSynonymsResponse.data?.protein_name, synonyms: getInputSynonymsResponse.data.terms[0]?.uniqueSynonymsTerms, synonyms_net: getInputSynonymsResponse.data.terms[0]?.uniqueSynonymsTerms }]);
    }
  }, [getInputSynonymsResponse]);

  useEffect(() => {
    if (toggle === true) {
      inputRef.current.focus();
    }
  }, [toggle]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0 && !isDisabled) {
      setAutosuggestOptions([]);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      const request = {
        input_text: e.target.value,
      };
      dispatch(getInputSuggestAction(queryString.stringify(request)));
    } else {
      setSearchText('');
      setDisplayDropdown(false);
    }
  };

  const renderInputTags = () => (
    <div>
      {proteinList?.map((item) => (
        <Tag
          closeIcon={<CloseOutlined />}
          onClose={(e) => {
            /* eslint-disable */
            e.preventDefault();
            setProteinList((prev) => {
              const arr = [];
              for (const i of prev) {
                if (i === item) continue;
                arr.push(i);
              }
              return arr;
            });
            setSynonymsList((prev) => {
              const arr = [];
              for (const i of prev) {
                if (i.protein_name === item) continue;
                arr.push(i);
              }
              return arr;
            });
            /* eslint-enable */
          }}
        >
          {item}
        </Tag>
      ))}
    </div>
  );

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < Math.min(10, autosuggestOptions.length); i++) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          key={crypto.randomUUID()}
          onClick={() => {
            Mixpanel.track('IEP input provided', {
              action: 'provide input click',
              protein_name : itm?.gene_name,
            });
            setSearchText('');
            setProteinList((prev) => [...prev,itm?.gene_name]);
            if (itm !== '') {
              const request = {
                protein_name: itm?.gene_name,
                uid: itm.uid,
              };
              dispatch(getInputSynonymsAction(queryString.stringify(request)));
            }
            setToggle(false)
            setDisplayDropdown(false);
          }}
        >
          {itm?.gene_name}
        </div>,
      );
    }
    /* eslint-enable */
    return options;
  };

  return (
    <div ref={searchBoxRef} className="searchbar-dashboard-content-search joyride-searchBar">
      {/* <Search placeholder="Enter Protein name, Preferred name or UniProt ID" value={searchText} size="large" maxLength="256" onChange={onChangeInput} enterButton={null} allowClear /> */}
      <div className="multi-input">
        <div className="multi-input-tag">{renderInputTags()}</div>
        <div className="multi-input-btn">
          { (toggle === false && proteinList?.length) ? (
            <Button color="primary" shape="round" variant="outlined" icon={<PlusOutlined />} onClick={() => { setToggle(true); }}>
              Add
            </Button>
          ) : <div />}
        </div>
        <div className="multi-input-search">
          <Input placeholder={(proteinList?.length === 0) ? 'Enter Protein name, Preferred name or UniProt ID' : ''} ref={inputRef} value={searchText} disabled={!toggle && proteinList?.length} onChange={onChangeInput} />
        </div>
      </div>
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <div className="search-dropdown-result">
            <div className="search-dropdown-result-list">
              <Loader loading={getInputSuggestResponse.loading}>{renderAutosuggestOptions()}</Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputDropdown.propTypes = {
  proteinList: PropTypes.arrayOf.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setProteinList: PropTypes.func.isRequired,
  setSynonymsList: PropTypes.func.isRequired,
};

export default InputDropdown;
