/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

function EndpointsGraph({dData}) {
  const [maxRadius, setMaxRadius] = useState(150);
  const [data, setData] = useState([]);

  const baseWidth = 1.6 * window.innerWidth / 3 ;
  const minRadius = 20;
  const maxTried = 800;
  const maxFontSize = 25;

  useEffect(() => {
    let parsedData = [];
    for(let i = 0; i < dData.length; i++) {
      if(dData[i].value !== undefined) {
        dData[i].value = parseFloat(Math.max(0.01,Math.floor(dData[i].value * 100) / 100)).toFixed(2);
        parsedData.push(dData[i]);
      } else {
        dData[i].value = 0;
        parsedData.push(dData[i]);
      }
    }
    setData(parsedData);
  }, []);


  const values = data.map((element) => element.value);

  values.sort((a, b) => b - a);

  const colorScale = d3.scaleLinear()
    .domain(d3.extent(values))
    .interpolate(d3.interpolateHcl)
    .range(['#5C8CEF', '#5C8CEF']);

  const getRadius = d3.scaleLinear()
    .domain([0, d3.max(data?.map((d) => d.value))])
    .range([minRadius, maxRadius]);

  const intersects = (first, second) => {
    const dx = first.x - second.x;
    const dy = first.y - second.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const sumOfRadii = first.radius + second.radius;
    return distance < sumOfRadii;
  };

  const renderClassName = (value) => {
    const valArr = value.split(' ');
    let temp = '';
    valArr.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const makeCircles = (dataset, width, height, ctr) => {
    if (!dataset) {
      return null;
    }

    const dots = [];

    const checkInsideRect = (dot) => {
      const checkWidth = dot.radius < dot.x && dot.x < width - dot.radius;
      const checkHight = dot.radius < dot.y && dot.y < height - dot.radius;
      return checkWidth && checkHight;
    };

    const createDots = (d) => {
      const x = Math.floor(Math.random() * width);
      const y = Math.floor(Math.random() * height);

      const radius = getRadius(d.value);
      const dot = {
        radius, x, y, ...d,
      };

      let flag = false;
      dots.forEach((item) => {
        if (intersects(dot, item)) {
          flag = true;
        }
      });

      if (!flag && checkInsideRect(dot)) {
        return dot;
      }
      return null;
    };

    let i = 0;
    let tried = 0;
    let checkOutOfBound = false;
    while (i < dataset.length) {
      const dot = createDots(dataset[i]);
      if (dot) {
        dots.push(dot);
        i += 1;
      }
      tried += 1;
      if (tried > maxTried) {
        checkOutOfBound = true;
        setMaxRadius((currentMax) => currentMax - 10);
        i += 20;
      }
    }

    if (checkOutOfBound) {
      return false;
    }

    const circlePlot = ctr.append('g');

    circlePlot.selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => colorScale(d.value))
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y);

    const labelCounts = ctr.append('g');

    circlePlot.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label')
      .attr('fill', 'white')
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + d.radius / 3 + 5)
      .style('font-size', (d) => {
        const len = d.key.substring(0, d.radius / 3).length;
        let size = d.radius / 3;
        size *= 10 / len;
        size -= 2;
        if (Math.round(size) > maxFontSize) {
          return `${maxFontSize}px`;
        }
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const text = d.key.substring(0, d.radius / 3);
        return text;
      });

    labelCounts.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label')
      .attr('fill', 'white')
      .attr('class', 'dot-counts')
      .attr('id', (d) => `endpoint-${renderClassName(d.key)}`)
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 2)
      .style('font-size', (d) => {
        const size = d.radius / 3;
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const count = String(d.value).substring(0, 2);
        return String(d.value * 100).substring(0, 2) + '%';
      });

    return false;
  };


  const draw = (dataset) => {
    const dimensions = {
      width: baseWidth,
      height: 380,
      margin: {
        left: 10,
        top: 10,
        bottom: 10,
        right: 10,
      },
    };


    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const svg = d3.select('#endpoints').append('svg')
      .attr('id', 'endpoints-graph')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = svg.append('g')
      .attr('transform', `translate(${0}, ${0})`)
      .attr('width', dimensions.ctrWidth)
      .attr('height', dimensions.ctrHeight);

      const translateX = 0;
      const width = dimensions.ctrWidth;
      const height = dimensions.ctrHeight;


      const phaseCtr = ctr.append('g')
        .attr('transform', `translate(${translateX}, 0)`);

    makeCircles(dataset, width, height, phaseCtr);

  };


  useEffect(() => {
    d3.select('#endpoints-graph').remove();
    draw(data);
  }, [maxRadius, data]);


  return (
    <div className="Widget large" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

      <div id="endpoints" />

    </div>
  );
}

EndpointsGraph.propTypes = {
  dData: PropTypes.array.isRequired,
};

export default EndpointsGraph;
