// /* eslint-disable */
import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';
import { terminalsPath } from '../../../../app/components/Card/data';
import InputBoxOncoTagging from '../../container/InputBoxOncoTagging';
import SelectService from '../../container/SelectService';
import {
  getOncoTaggingOutputAction, getUtilitiesPathwayEnrichmentAction, getUtilitiesPPINetworkAction, pathwayEnrichmentUploadFileAction, ppiNetworkUploadFileAction,
} from './logic';

function OncoTaggingInputPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const service = searchParams.get('service');
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  // const dispatch = useDispatch();

  const [formData, setFormData] = useState();
  const [inputs, setInputs] = useState({
    card: '',
    inputText: '',
  });
  const handleClick = () => {
    Mixpanel.track('Ontology Tagging Input Submitted', {
      action: 'Ontology Tagging Input submitted',
      service: inputs.card,
      inputText: inputs.inputText,
      module: 'Utilities',
    });
    if (inputs.card === 'Ontology ID Association') {
      const request = {
        input_text: inputs.inputText,
        page_size: 10,
        page_number: 1,
      };
      dispatch(getOncoTaggingOutputAction(queryString.stringify(request)));
    } else if (inputs.card === 'Pathway Enrichment Graph') {
      if (inputs.btn === 'Upload a Document') {
        dispatch(pathwayEnrichmentUploadFileAction({ params: '', body: formData }));
      } else {
        dispatch(getUtilitiesPathwayEnrichmentAction({ params: '', body: { proteins: inputs.inputText.split(',') } }));
      }
    } else if (inputs.card === 'PPI Network Graph') {
      if (inputs.btn === 'Upload a Document') {
        dispatch(ppiNetworkUploadFileAction({ params: '', body: formData }));
      } else {
        dispatch(getUtilitiesPPINetworkAction({ params: '', body: { input_text: inputs.inputText } }));
      }
    }
    navigate(`${terminalsPath.metad3_utilities_output}?service=${inputs.card}&inputText=${inputs.inputText}&isFileInput=${inputs.inputText === undefined}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let check = false;
    if (!formData) {
      check = inputs.inputText === '' || inputs.inputText === undefined;
    } else {
      check = !(formData !== null && formData && 'get' in formData);
    }
    check = check || !inputs.card;
    setDisableButton(check);
  }, [inputs, formData]);

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="utilities-logo" width={500} modulePath={terminalsPath.metad3_utilities} />
      <Loader loading={false}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Utilities for adhoc analyses on predicted outputs</div>
            <div className="main-description">Commonly used analytical tools and bioinformatics services for further analysis</div>
          </div>
          <div>
            <SelectService inputHandler={getInputs} service={service} resetInputs={() => setInputs({})} />
            <InputBoxOncoTagging inputHandler={getInputs} serviceName={inputs.card} setFormData={setFormData} isDisabled={inputs.card === ''} />
            <div className="ct-home-page-footer">
              <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
                Generate Prot ID
              </button>
            </div>
          </div>
        </div>
        {/* <SaveProjectModal name={inputs.protein_name} service={inputs.card} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} formData={formData} /> */}
      </Loader>
    </div>
  );
}

export default OncoTaggingInputPage;
