import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GEO_CT = 'GEO_CT';
const GEO_CT_SUCCESS = 'GEO_CT_SUCCESS';
const GEO_CT_FAILURE = 'GEO_CT_FAILURE';
const GEO_CT_RESET = 'GEO_CT_RESET';

export const geoCtAction = createAction(GEO_CT);
export const geoCtResetAction = createAction(GEO_CT_RESET);

const geoCtSuccess = createAction(GEO_CT_SUCCESS);
const geoCtFailure = createAction(GEO_CT_FAILURE);

export const geoCtEpic = (actions$) => actions$.pipe(
  ofType(GEO_CT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTSites?${action.payload}`, 'get')).pipe(
    map((res) => geoCtSuccess(res)),
    catchError((err) => of(geoCtFailure(err))),
  )),
);

export const geoCtReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoCtAction, (state) => {
        state.loading = true;
      })
      .addCase(geoCtSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoCtFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoCtResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GEO_CT_STATES = 'GEO_CT_STATES';
const GEO_CT_STATES_SUCCESS = 'GEO_CT_STATES_SUCCESS';
const GEO_CT_STATES_FAILURE = 'GEO_CT_STATES_FAILURE';
const GEO_CT_STATES_RESET = 'GEO_CT_STATES_RESET';

export const geoCtStatesAction = createAction(GEO_CT_STATES);
export const geoCtStatesResetAction = createAction(GEO_CT_STATES_RESET);

const geoCtStatesSuccess = createAction(GEO_CT_STATES_SUCCESS);
const geoCtStatesFailure = createAction(GEO_CT_STATES_FAILURE);

export const geoCtStatesEpic = (actions$) => actions$.pipe(
  ofType(GEO_CT_STATES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTSites?${action.payload}`, 'get')).pipe(
    map((res) => geoCtStatesSuccess(res)),
    catchError((err) => of(geoCtStatesFailure(err))),
  )),
);

export const geoCtStatesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoCtStatesAction, (state) => {
        state.loading = true;
      })
      .addCase(geoCtStatesSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoCtStatesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoCtStatesResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GEO_CT_DETAILS = 'GEO_CT_DETAILS';
const GEO_CT_DETAILS_SUCCESS = 'GEO_CT_DETAILS_SUCCESS';
const GEO_CT_DETAILS_FAILURE = 'GEO_CT_DETAILS_FAILURE';
const GEO_CT_DETAILS_RESET = 'GEO_CT_DETAILS_RESET';

export const geoCtDetailsAction = createAction(GEO_CT_DETAILS);
export const geoCtDetailsResetAction = createAction(GEO_CT_DETAILS_RESET);

const geoCtDetailsSuccess = createAction(GEO_CT_DETAILS_SUCCESS);
const geoCtDetailsFailure = createAction(GEO_CT_DETAILS_FAILURE);

export const geoCtDetailsEpic = (actions$) => actions$.pipe(
  ofType(GEO_CT_DETAILS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTSites?${action.payload}`, 'get')).pipe(
    map((res) => geoCtDetailsSuccess(res)),
    catchError((err) => of(geoCtDetailsFailure(err))),
  )),
);

export const geoCtDetailsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoCtDetailsAction, (state) => {
        state.loading = true;
      })
      .addCase(geoCtDetailsSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoCtDetailsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoCtDetailsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GEO_KOL = 'GEO_KOL';
const GEO_KOL_SUCCESS = 'GEO_KOL_SUCCESS';
const GEO_KOL_FAILURE = 'GEO_KOL_FAILURE';
const GEO_KOL_RESET = 'GEO_KOL_RESET';

export const geoKolAction = createAction(GEO_KOL);
export const geoKolResetAction = createAction(GEO_KOL_RESET);

const geoKolSuccess = createAction(GEO_KOL_SUCCESS);
const geoKolFailure = createAction(GEO_KOL_FAILURE);

export const geoKolEpic = (actions$) => actions$.pipe(
  ofType(GEO_KOL),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTKols?${action.payload}`, 'get')).pipe(
    map((res) => geoKolSuccess(res)),
    catchError((err) => of(geoKolFailure(err))),
  )),
);

export const geoKolReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoKolAction, (state) => {
        state.loading = true;
      })
      .addCase(geoKolSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoKolFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoKolResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GEO_KOL_STATES = 'GEO_KOL_STATES';
const GEO_KOL_STATES_SUCCESS = 'GEO_KOL_STATES_SUCCESS';
const GEO_KOL_STATES_FAILURE = 'GEO_KOL_STATES_FAILURE';
const GEO_KOL_STATES_RESET = 'GEO_KOL_STATES_RESET';

export const geoKolStatesAction = createAction(GEO_KOL_STATES);
export const geoKolStatesResetAction = createAction(GEO_KOL_STATES_RESET);

const geoKolStatesSuccess = createAction(GEO_KOL_STATES_SUCCESS);
const geoKolStatesFailure = createAction(GEO_KOL_STATES_FAILURE);

export const geoKolStatesEpic = (actions$) => actions$.pipe(
  ofType(GEO_KOL_STATES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTKols?${action.payload}`, 'get')).pipe(
    map((res) => geoKolStatesSuccess(res)),
    catchError((err) => of(geoKolStatesFailure(err))),
  )),
);

export const geoKolStatesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoKolStatesAction, (state) => {
        state.loading = true;
      })
      .addCase(geoKolStatesSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoKolStatesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoKolStatesResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GEO_KOL_DETAILS = 'GEO_KOL_DETAILS';
const GEO_KOL_DETAILS_SUCCESS = 'GEO_KOL_DETAILS_SUCCESS';
const GEO_KOL_DETAILS_FAILURE = 'GEO_KOL_DETAILS_FAILURE';
const GEO_KOL_DETAILS_RESET = 'GEO_KOL_DETAILS_RESET';

export const geoKolDetailsAction = createAction(GEO_KOL_DETAILS);
export const geoKolDetailsResetAction = createAction(GEO_KOL_DETAILS_RESET);

const geoKolDetailsSuccess = createAction(GEO_KOL_DETAILS_SUCCESS);
const geoKolDetailsFailure = createAction(GEO_KOL_DETAILS_FAILURE);

export const geoKolDetailsEpic = (actions$) => actions$.pipe(
  ofType(GEO_KOL_DETAILS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getGeoCTKols?${action.payload}`, 'get')).pipe(
    map((res) => geoKolDetailsSuccess(res)),
    catchError((err) => of(geoKolDetailsFailure(err))),
  )),
);

export const geoKolDetailsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(geoKolDetailsAction, (state) => {
        state.loading = true;
      })
      .addCase(geoKolDetailsSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(geoKolDetailsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(geoKolDetailsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
